import { useState } from 'react';
import { cn } from "../../lib/utils";
import avatar1 from "../../styles/avatar1.png";
import avatar2 from "../../styles/avatar2.png";
import avatar3 from "../../styles/avatar3.png";
import avatar4 from "../../styles/avatar4.png";
import avatar5 from "../../styles/avatar5.png";
export default function AvatarCircles({ numPeople, className }) {
    const [avatars] = useState([
        avatar1,
        avatar3,
        avatar4,
        avatar5,
    ]);

    return (
        <div className={cn("z-10 flex -space-x-5 rtl:space-x-reverse", className)}>
            {avatars.map((avatar, index) => (
                <img
                    key={index}
                    className="h-10 w-10 rounded-full border-2 border-white dark:border-gray-800"
                    src={avatar}
                    alt=""
                />
            ))}
            <a href="https://x.com/search?q=monezon%20-atMonezon%20-MonezonAlerts&src=spelling_expansion_revert_click" className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-black text-center text-xs font-medium text-white hover:bg-gray-600 dark:border-gray-800 dark:bg-white dark:text-black">
                +{numPeople}
            </a>
        </div>
    );
}
