import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../components/DataContext';
import home from '../styles/home.png';
import cabinet from '../styles/cabinet.png';
import logoImage from '../styles/delivery-package.png';
function DeliveryTypeSelection() {
  const navigate = useNavigate();
  const { data, dispatch } = useData();

  const handleButtonClick = (type) => {
    localStorage.setItem('deliveryType', type);
    navigate('/cart');
  };

  return (
    <div className="center-container" style={{ height: "70vh" }}>
      <img src={logoImage} alt="Logo" className="logo-image" />

      <div className="form">
        <div className='delivery-buttons'>
          <button onClick={() => handleButtonClick('homeDelivery')}><img src={home}></img>
          <p style={{ color: "white", marginTop: "10px"}}>Home</p>
          </button>
          <button onClick={() => handleButtonClick('relayPoint')}><img src={cabinet}></img>
          <p style={{ color: "white", marginTop: "10px" }}>Locker</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeliveryTypeSelection;
