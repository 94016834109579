import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ManageOrders from "../../components/manageOrders.js";
import ReactModal from "react-modal";
import Joyride from "react-joyride";
import LivraisonIcon from "../../styles/Livraison.svg";
import { CheckIcon } from "@chakra-ui/icons";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Button,
  Text,
  VStack,
  useToast,
  BadgeProps,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Box,
  Center
} from "@chakra-ui/react";
import { Badge } from "@chakra-ui/react";
import AdminManageOrders from "./AdminManageOrders.js";
import AddPost from "../blog/addPost.js";
import AdminSearchBar from "../../components/AdminSearchBar.js";


const AdminDashboard = () => {
  const navigate = useNavigate();
  const [processingOrders, setProcessingOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [referral, setReferral] = useState([]);
  const [activeTab, setActiveTab] = useState("availableOrders");
  const [selectedReferralCode, setSelectedReferralCode] = useState("");
  const [selectedReferralBalance, setSelectedReferralBalance] = useState(0);
  const {
    isOpen: isConfirmPayoutOpen,
    onOpen: onConfirmPayoutOpen,
    onClose: onConfirmPayoutClose,
  } = useDisclosure();

  const [wsUrl, setwsUrl] = useState(process.env.REACT_APP_WS_URL);
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  useEffect(() => {
    axios
      .get("/admin/check", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 201) {
          loadProcessingOrders();
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/");
      });
  }, []);

  async function loadProcessingOrders() {
    try {
      const { data } = await axios.get("/admin/orders/processing", {
        withCredentials: true,
      });
      setProcessingOrders(data["message"]);
    } catch (error) {
      if (error.response && error.response.data.code === 200) {
        navigate("/");
      } else {
        console.error("Error fetching orders:", error);
      }
    }
  }

  useEffect(() => {
    getAllRewardsMatching();
    // Créer un EventSource pour écouter les événements SSE
    const eventSource = new EventSource(wsUrl + "/events", {
      withCredentials: true,
    });

    eventSource.onmessage = (event) => {
      try {
        if (event.data.startsWith("{") || event.data.startsWith("[")) {
          event = JSON.parse(event.data);
          console.log(event.action);
          switch (event.action) {
            case "addOrder":
              setPendingOrders((prevPendingOrders) => [
                ...prevPendingOrders,
                event.data,
              ]);
              break;
            case "delOrder":
              console.log(event.action);
              console.log(event.data.id);
              setPendingOrders((prevPendingOrders) =>
                prevPendingOrders.filter((order) => order.id !== event.data.id)
              );
              break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    eventSource.onerror = (error) => {
      // setPendingOrders([]);
    };

    return () => {
      eventSource.close();
      setPendingOrders([]);
    };
  }, []);

  async function getAllRewardsMatching() {
    try {
      const { data } = await axios.get("/admin/referral/balance", {
        withCredentials: true,
      });
      setReferral(data["message"]);
    } catch (error) {
      if (error.response && error.response.data.code === 200) {
      } else {
        console.error("Error fetching orders:", error);
      }
    }
  }

  async function confirmPayout() {
    try {
      const { data } = await axios.post(
        "/admin/referral/confirmPayout",
        {
          referralCode: selectedReferralCode,
          xmrBalance: selectedReferralBalance,
        },
        {
          withCredentials: true,
        }
      );
      toast(data["message"]);
      onConfirmPayoutClose();
      getAllRewardsMatching();
    } catch (error) {
      if (error.response && error.response.data.code === 200) {
      } else {
        console.error("Error confirming payout:", error);
      }
    }
  }

  return (
    <div className="panel">
      <Tabs
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        onChange={setActiveTab}
      >
        <TabList>
          <Tab>Pending Orders</Tab>
          <Tab>Processing Orders</Tab>
          <Tab>Users</Tab>
          <Tab>Affiliate Rewards</Tab>
          <Tab>Add Post</Tab>
        </TabList>

        <TabPanels w={"100%"} style={{ width: "100%" }}>
          <TabPanel>
            <VStack spacing={4}>
              {pendingOrders.length === 0 ? (
                <div className="emptyOrders">
                  <p>No order at this moment...</p>
                </div>
              ) : (
                pendingOrders.map((order) => (
                  <div className="orderWaiting" key={order.id}>
                    <Badge
                      colorScheme="orange"
                      top={"-10px"}
                      position={"absolute"}
                      left={"40%"}
                    >
                      {order.choosedDiscount
                        ? `-${order.choosedDiscount}%`
                        : ""}
                    </Badge>
                    <p className="orderWaitingText">
                      <span className="orderWaitingPrice">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>
                            Pay: $
                            {order.feePercentage ? order.amount : order.amount}
                          </p>
                          #{String(order.id).substring(0, 5)}
                        </div>{" "}
                      </span>
                      <span className="orderWaitingPrize">
                        Earn: {parseFloat(order.totalXMR).toFixed(5)} XMR ($
                        {order?.feePercentage
                          ? (parseFloat(order.price) + 5).toFixed(2)
                          : parseFloat(order.price).toFixed(2)}
                        )
                      </span>
                    </p>
                  </div>
                ))
              )}
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack spacing={4}>
              {processingOrders.length > 0 ? (
                <div>
                  <p>Total Amount of Processing Orders: {processingOrders.filter(order => order.executor !== 'Monezon').reduce((totalXMR, order) => totalXMR + (order.totalXMR || 0), 0).toFixed(5)} XMR</p>
                </div>
              ) : (
                <div>
                  <p>No processing orders at this moment...</p>
                </div>
              )}
              <AdminManageOrders orders={processingOrders} />
            </VStack>
          </TabPanel>

          <TabPanel>
            <AdminSearchBar></AdminSearchBar>
          </TabPanel>

          <TabPanel p={4} background={"white"}>
            <h1>Showing balance bigger than 0</h1>
            {referral.map((referral) => (
              <Center>
                <Box
                  key={referral.referralCode}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  mb={4}
                >
                  {referral.requestedPayout && (
                    <Badge mt={2} colorScheme="orange" variant="solid">
                      Payout Requested
                    </Badge>
                  )}
                  <Text>Referral Code: {referral.referralCode}</Text>
                  <Text>Uses: {referral.uses}</Text>
                  <Text>Balance: {referral.xmrBalance} XMR</Text>
                  {referral.requestedPayout && (
                    <Button
                      mt={2}
                      colorScheme="orange"
                      onClick={() => {
                        setSelectedReferralCode(referral.referralCode);
                        setSelectedReferralBalance(referral.xmrBalance);
                        onConfirmPayoutOpen();
                      }}
                    >
                      Set as Paid
                    </Button>
                  )}
                </Box>
              </Center>
            ))}

            <Modal isOpen={isConfirmPayoutOpen} onClose={onConfirmPayoutClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Confirm Payout</ModalHeader>
                <ModalBody>
                  Are you sure you want to confirm this payout?
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="orange"
                    mr={3}
                    onClick={() => {
                      confirmPayout();
                      onConfirmPayoutClose();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button onClick={onConfirmPayoutClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </TabPanel>
          <TabPanel>
            <AddPost />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;
