// Button.js
import React from 'react';

const Button = ({ children, ...props }) => {
  return (
    <button
      className="px-4 py-2 border border-gray-300 rounded text-black hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
