import WordRotate from "./magicui/word-rotate"

export default function WordRotateDemo() {
    return (
        <WordRotate
            className="smE:text-4xl text-2xl font-bold text-orange-500 dark:text-white"
            words={[
                "BTC",
                "LTC",
                "DODGE",
                "USDT",
                "BNB",
                "MATIC",
                "XMR",
                "SOL",
                "1INCH",
                "AVAX",
                "BAT",
                "ATOM",
                "ETH",
            ]}
        />
    )
}