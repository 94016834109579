import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Button, Container, Grid, Heading, Text, VStack, useColorModeValue, Image, Center, Badge, Input, InputGroup, InputRightElement, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverHeader, PopoverArrow, PopoverCloseButton, Stack,
  HStack
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Spinner } from "@chakra-ui/react";

export default function ViewPost() {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);


  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await axios.get('/posts');
        setPosts(response.data.message);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch posts:', error);
      }
    };
    fetchPosts();
  }, []);

  const backgroundColor = useColorModeValue('white', 'gray.800');

  const filteredPosts = posts.filter(post =>
    (!selectedTopic || post.topic === selectedTopic || (selectedTopic === 'Buyers' || selectedTopic === 'Executors') && post.topic === 'Both') &&
    (post.title.toLowerCase().includes(searchTerm.toLowerCase()) || post.summary.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
  };

  const stripHtmlTags = (html) => {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || "";
  };

  const extractCoverImage = (content) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    const firstImage = tempElement.querySelector('img');
    return firstImage ? firstImage.src : null;
  };

  const renderBadges = (topic) => {
    if (topic === "Both") {
      return ["Buyers", "Executors"].map(t => (
        <Badge key={t} borderRadius="full" px="2" colorScheme={
          t === "Buyers" ? "blue" : "orange"
        } mt={3} mr={1}>
          {t}
        </Badge>
      ));
    } else {
      return (
        <Badge borderRadius="full" px="2" colorScheme={
          topic === "News" ? "green" :
            topic === "Buyers" ? "blue" :
              topic === "Executors" ? "orange" : "gray"
        } mt={3}>
          {topic}
        </Badge>
      );
    }
  };

  return (
    <Container maxW="container.xl" py={5} backgroundColor={"white"} p={10} mt={40}>
      <Box mb={4}>
        <InputGroup>
          <Input placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          <InputRightElement>
            <Button variant="ghost" onClick={() => setSearchTerm('')}>
              <CloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
      <Stack direction="row" spacing={4} mb={4}>
        <Popover>
          <PopoverTrigger>
            <Button>
              Filter by Topic
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Choose a Topic</PopoverHeader>
            <PopoverBody>
              <HStack>
                <Button onClick={() => handleTopicSelect(null)}>All</Button>
                <Button onClick={() => handleTopicSelect('News')}>News</Button>
                <Button onClick={() => handleTopicSelect('Buyers')}>Buyers</Button>
                <Button onClick={() => handleTopicSelect('Executors')}>Executors</Button>
                <Button onClick={() => handleTopicSelect('LocalMonero')}>LocalMonero</Button>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>

      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)',  }} gap={6} >
      <Spinner color="red.500" display={loading ? "initial" : "none"} style={{ position: "absolute", top: "100%", left: "50%", transform: "translate(-50%, -50%)" }} />

        {filteredPosts.toReversed().map((post, index) => (
          <Box
            key={index}
            bg={backgroundColor}
            boxShadow="lg"
            borderRadius="lg"
            overflow="hidden"
            w={"100%"}
            _hover={{ boxShadow: '2xl' }}
          >
            <Link to={`/article/${post._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box p={5} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} h={"100%"} w={"100%"} alignItems={"baseline"} >
                <Image src={extractCoverImage(post.content) || 'https://via.placeholder.com/500'} alt={`Cover Image for ${post.title}`} borderRadius={"5px"} width={"200px"} height={"200px"} objectFit={"cover"} />
                {renderBadges(post.topic)}
                <Heading fontSize="xl" mt={4} mb={2}>{post.title}</Heading>
                <Text mb={4} noOfLines={3}>{post.summary}</Text>
                <Text mb={4} noOfLines={5}>{stripHtmlTags(post.content).substring(0, 200)}</Text>
                <Button colorScheme="orange" variant="solid">
                  Read More
                </Button>
                <Text color={"gray"} fontSize="sm" mt={2}>{new Date(post.createdAt).toLocaleDateString('en-US')}</Text>
              </Box>
            </Link>
          </Box>
        ))}
      </Grid>
    </Container>
  );
}