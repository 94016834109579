import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useData } from '../components/DataContext';

import { ec as EC } from 'elliptic';
import hash from 'hash.js';
const ec = new EC('secp256k1');



function ChatExecutorEntry() {
    const { data, dispatch } = useData();
    const navigate = useNavigate();
    const { id } = useParams();

    const [error, setError] = useState("");

    const [password, setPassword] = useState(null);

    useEffect(() => {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      }, []);

    async function accessChat() {

        if (password !== null) {
            axios.post("/chat/getRoom", { orderId: id.toString(), password: password }, { withCredentials: true })
                .then(async (response) => {
                    dispatch({ type: 'SET_USERTYPE', payload: "Executor" });

                    const [publicKey, privateKey] = await generateKeysFromPassword(password);
                    dispatch({ type: 'SET_KEYS', payload: { private: privateKey, public: publicKey } });
                    dispatch({ type: 'SET_PUBLICKEY', payload: response.data.message.publicKey });
                    dispatch({ type: 'SET_TRACKINGCODE', payload: response.data.message.trackingCode });
                    dispatch({ type: "SET_ACCEPTED_TIMESTAMP", payload: response.data.message.acceptedTimestamp });
                    dispatch({
                        type: "SET_DELIVERY_VALIDATED",
                        payload: response.data.message.deliveryValidated,
                      });
                    navigate(`/chat/${response.data.message.roomId}`);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    }

    function generateKeysFromPassword(password) {
        // Create a hash from the user's password
        const hashBuffer = hash.sha256().update(password).digest();

        // Generate keys from the hash
        const keys = ec.keyFromPrivate(hashBuffer);

        // Get the public and private keys in hexadecimal format
        const publicKey = keys.getPublic('hex');
        const privateKey = keys.getPrivate('hex');
        return [publicKey, privateKey];
    }


    return (
        <div className='mt-52' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <form className='tracking-words form' onSubmit={(e) => { e.preventDefault(); accessChat() }} >
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <p>Enter your password to enter chat #{id}</p>

                </div>
                <div className='flex flex-wrap justify-center border border-orange-300 rounded-md'>
                    <input type="password" className='pl-2 rounded-lg' id="" onChange={(e) => { setPassword(e.target.value) }} />
                </div>
                <button className='mt-2 bg-orange-500 text-white p-2 rounded-md hover:bg-orange-600 w-2/4 mt-6' >Access</button>

            </form>
            <div style={{ marginTop: '20px' }}>
                <p>{error}</p>

            </div>
        </div>
    );
}

export default ChatExecutorEntry;
