import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import {
  Center,
  Flex,
  Button,
  Heading,
  Input,
  HStack,
  Badge,
} from "@chakra-ui/react";

export default function AddPost() {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const quillRef = useRef(null);
  const [selectedTopic, setSelectedTopic] = useState("");

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/admin/check", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 201) {

        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/");
      });
  }, []);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    const quillInstance = quillRef.current.getEditor();

    function imageHandler() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await axios.post("/upload/image", formData, {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data && response.data.image) {
            const base64Image = `data:image/jpeg;base64,${response.data.image}`; // Assurez-vous que le type MIME est correct
            const range = quillInstance.getSelection(true);
            quillInstance.insertEmbed(range.index, "image", base64Image);
            quillInstance.setSelection(range.index + 1);
          } else {
            console.error("No image data received");
          }
        } catch (error) {
          console.error("Failed to upload image:", error);
        }
      };
    }

    // Add the custom handler for the image button
    quillInstance.getModule("toolbar").addHandler("image", imageHandler);
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "font",
    "align",
  ];

  async function handleSubmit() {
    if (!selectedTopic) {
      alert("Please select a topic");
      return;
    }

    try {
      let response = await axios.post(
        "/add/posts",
        {
          title: title,
          content: content,
          topic: selectedTopic,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        alert("Post added successfully");
        setTitle("");
        setContent("");
        setSelectedTopic("");
      } else {
        alert("Failed to add post");
      }
    } catch (error) {
      console.error("Error submitting post:", error);
      alert("Error submitting post");
    }
  }

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
  };

  return (
    <div className="addPost" style={{background: "white"}}>
      <Center>
        <Heading as="h2" size="xl" noOfLines={1} mb={20}>
          Let's publish 👨‍💻
        </Heading>
      </Center>
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Heading as="h2" size="sm" noOfLines={1} mb={4}>
          Choose the topic
        </Heading>
        <HStack spacing={8}>
          {["News", "Executors", "Buyers", "Both", "LocalMonero"].map((topic) => (
            <Badge
              key={topic}
              colorScheme={selectedTopic === topic ? "orange" : "gray"}
              fontSize="1em"
              onClick={() => handleTopicSelect(topic)}
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
            >
              {topic}
            </Badge>
          ))}
        </HStack>
        <Input
          placeholder="Enter post title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          size="md"
          my={4}
          width="90%"
          maxWidth="1000px"
        />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={content}
          onChange={setContent}
          style={{ width: "90%", height: "400px", maxWidth: "1000px" }}
          modules={modules}
          formats={formats}
          // normal font size should be 20px
        />
        <Button
          onClick={handleSubmit}
          colorScheme="orange"
          size="md"
          mt={20}
          mb={20}
        >
          Submit
        </Button>
      </Flex>
    </div>
  );
}
