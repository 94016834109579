import { useState } from 'react';
import OrbitingCircles from "./magicui/orbiting-circles";
import Flag from 'react-world-flags'

export default function OrbitingCirclesDemo() {
    const [icons] = useState({
        notion: require('../styles/cart.png'),
        openai: require('../styles/cart.png'),
        googleDrive: require('../styles/cart.png'),
        whatsapp: require('../styles/cart.png'),
    });

    return (
        <div className="relative flex h-[500px] w-full max-w-[32rem] items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
            <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center text-4xl font-semibold leading-none text-transparent">
                Up to 15% Discount
            </span>
        
            {/* Inner Circles */}
            <OrbitingCircles
                className="h-[30px] w-[30px] border-none bg-transparent"
                duration={20}
                delay={20}
                radius={80}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[30px] w-[30px] border-none bg-transparent"
                duration={20}
                delay={4}
                radius={80}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[30px] w-[30px] border-none bg-transparent"
                duration={20}
                delay={12}
                radius={80}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[30px] w-[30px] border-none bg-transparent"
                duration={20}
                delay={20}
                radius={80}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[30px] w-[30px] border-none bg-transparent"
                duration={20}
                delay={10}
                radius={80}
            >
                🏷️
            </OrbitingCircles>

            {/* Outer Circles (reverse) */}
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
                delay={20}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
                delay={10}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
                delay={7}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
                delay={1}
            >
                🏷️
            </OrbitingCircles>
            <OrbitingCircles
                className="h-[50px] w-[50px] border-none bg-transparent"
                reverse
                radius={190}
                duration={20}
                delay={8}
            >
                🏷️
            </OrbitingCircles>
        </div>
    );
}