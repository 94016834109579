import React, { createContext, useContext, useReducer } from "react";

const DataStateContext = createContext();

export function DataProvider({ children }) {
  const [data, dispatch] = useReducer(dataReducer, initialState);

  return (
    <DataStateContext.Provider value={{ data, dispatch }}>
      {children}
    </DataStateContext.Provider>
  );
}

export const useData = () => {
  return useContext(DataStateContext);
};

const initialState = {
  selectedDeliveryType: null,
  cart: null,
  keys: null,
  publicKey: null,
  userType: "Buyer",
  trackingCode: "none",
  passphrase: [],
  checkoutUrl: "",
  deliveryValidated: null,
  acceptedTimestamp: null,
  ratings: 0,
  orderCount: 0,
  executorUsername: "",
  choosedDiscount: 0,
  xmrAddress: "",
  trustLevel: "",
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case "SET_DELIVERY_TYPE":
      return { ...state, selectedDeliveryType: action.payload };
    case "SET_CART":
      return { ...state, cart: action.payload };
    case "SET_KEYS":
      return { ...state, keys: action.payload };
    case "SET_PUBLICKEY":
      return { ...state, publicKey: action.payload };
    case "SET_USERTYPE":
      return { ...state, userType: action.payload };
    case "SET_TRACKINGCODE":
      return { ...state, trackingCode: action.payload };
    case "SET_PASSPHRASE":
      return { ...state, passphrase: action.payload };
    case "SET_CHECKOUTURL":
      return { ...state, checkoutUrl: action.payload };
    case "SET_DELIVERY_VALIDATED":
      return { ...state, deliveryValidated: action.payload };
    case "SET_ACCEPTED_TIMESTAMP":
      return { ...state, acceptedTimestamp: action.payload };
    case "SET_RATINGS":
      return { ...state, ratings: action.payload };
    case "SET_ORDER_COUNT":
      return { ...state, orderCount: action.payload };
    case "SET_EXECUTOR_USERNAME":
      return { ...state, executorUsername: action.payload };
    case "SET_CHOOSED_DISCOUNT":
      return { ...state, choosedDiscount: action.payload };
    case "SET_EXECUTOR_XMR_ADDRESS":
      return { ...state, xmrAddress: action.payload };
    case "SET_EXECUTOR_TRUSTLEVEL":
      return { ...state, trustLevel: action.payload };
    default:
      return state;
  }
};
