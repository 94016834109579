import React, { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import referral from "../styles/referral.png";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Divider,
  Center,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { ViewIcon, CopyIcon, LinkIcon, InfoIcon } from "@chakra-ui/icons";

import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import Joyride from "react-joyride";
import { motion } from "framer-motion";
import { emojiBlast, emojiBlasts } from "emoji-blast";

// Once a user has requested payout, add a boolean to the referral table
// If user has already requested payout, disable request payout button
//

const Affiliation = () => {
  const [referralCode, setReferralCode] = useState("");
  const [referralUse, setReferralUse] = useState(0);
  const [amountSaved, setAmountSaved] = useState(0);
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [xmrBalance, setXmrBalance] = useState(0);
  const [xmrAddress, setXmrAddress] = useState("");
  const [cryptoRate, setCryptoRate] = useState(0.0);

  const [totalPayout, setTotalPayout] = useState(0);
  const [hasAlreadyRequestedPayout, setHasAlreadyRequestedPayout] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [run, setRun] = useState(referralCode);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isGenerateOpen,
    onOpen: onGenerateOpen,
    onClose: onGenerateClose,
  } = useDisclosure();

  const MotionModalContent = motion(ModalContent);
  const MotionButton = motion(Button);
  const confirmationRef = useRef(null);

  const modalVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    hover: { scale: 1.1 },
    tap: { scale: 0.95 },
  };

  const steps = [
    { title: "Share", description: "Your referral link", completed: false },
    { title: "Reach", description: "A total value of $20" },
    { title: "Request", description: "A payout" },
  ];
  const { activeStep, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toast = useToast();

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  async function generatePassword() {
    const response = await axios
      .get("/referral/generate")
      .then((response) => {
        console.log(response.data);
        setReferralCode(response.data.referralCode);
        setReferralUse(response.data.uses);
        setCryptoRate(parseFloat(response.data.cryptoRate));
        setPassword(response.data.password);
        onGenerateOpen();
        const isReferralCompleted = localStorage.getItem("referralCompleted");
        if (!isReferralCompleted) {
          setRun(true);
          localStorage.setItem("referralCompleted", true);
        }
      })
      .catch((error) => {
        toast({
          title: "Cooldown of 1 minute",
          autoClose: 1000,
          duration: 1000,
          status: "error",
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  }

  useEffect(() => {
    if (referralUse % 3 === 0) {
      var amountSavedCalcul = (referralUse / 3) * 3;
    } else {
      const closestMultipleOfThree = Math.floor(referralUse / 3) * 3;
      console.log(closestMultipleOfThree);
      amountSavedCalcul = (closestMultipleOfThree / 3) * 3;
    }
    setAmountSaved(amountSavedCalcul);
  }, [referralUse]);

  async function accessreferral() {
    await axios
      .post(
        "/referral/access",
        {
          password: password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setReferralCode(response.data.referralCode);
        setReferralUse(response.data.uses);
        setXmrBalance(response.data.xmrBalance);
        setCryptoRate(parseFloat(response.data.cryptoRate));
        setTotalPayout(
          response.data.totalPayout !== null &&
            response.data.totalPayout !== undefined
            ? response.data.totalPayout
            : 0
        );
        setHasAlreadyRequestedPayout(response.data.requestedPayout);

        const isReferralCompleted = localStorage.getItem("referralCompleted");
        if (!isReferralCompleted) {
          setRun(true);
          localStorage.setItem("referralCompleted", true);
        }

        if (response.data.xmrBalance === 0) {
          localStorage.removeItem("hasRequestedPayout");
        }

        const hasClickedShare = localStorage.getItem("hasClickedShare");

        const hasRequestedPayout = localStorage.getItem("hasRequestedPayout");

        if (hasClickedShare) {
          setActiveStep(1);
        }
        if (response.data.xmrBalance * response.data.cryptoRate >= 20) {
          setActiveStep(2);
        }
        if (hasRequestedPayout) {
          setActiveStep(3);
        }
      })
      .catch((error) => {
        toast({
          title: error.response.data.error,
          autoClose: 1000,
          duration: 1000,
          status: "error",
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  }

  const handlePayoutRequest = async () => {
    if (xmrAddress.length < 20) {
      toast({
        title: "Error",
        description: "XMR address not valid.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    onConfirmOpen(); // Open the confirmation modal
    setIsLoading(false);
    requestPayout();
    emojiBlast({
      emojis: [
        "🎉",
        "🎊",
        "🥳",
        "🎈",
        "🍾",
        "🎂",
        "🎁",
        "🎶",
        "💃",
        "🕺",
        "🍹",
        "🎊",
        "🎉",
      ],
      emojiCount: () => Math.random() * 10 + 50,
      uniqueness: 8,
    });
    onClose(); // Optionally close the first modal
    setHasAlreadyRequestedPayout(true);
    localStorage.setItem("hasRequestedPayout", true);
  };

  async function requestPayout() {
    if (xmrBalance * cryptoRate >= 20) {
      await axios
        .post("/referral/requestPayout", {
          xmrAddress: xmrAddress,
          referralCode: referralCode,
        })
        .then((response) => {
          
          toast({
            title: "Request for payout successfully sent!",
            description:
              "Once confirmed, your XMR balance will be transferred.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: error.response.data.error,
            autoClose: 1000,
            duration: 1000,
            status: "error",
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    } else {
      toast({
        title: "Insufficient balance",
        description: "You need at least $20 in XMR to request a payout.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }



  return (
    <div
      className=" mt-52 w-3/6 flex flex-col gap-5 justify-center items-center"
      style={{ display: "flex", flexDirection: "column", gap: "50px", width: "100%" }}
    >
      <div className={`${referralCode === "" ? "form" : "hidden"}`}>
        <Joyride
          steps={steps}
          run={run}
          continuous={true}
          scrollToFirstStep={true}
          showSkipButton={true}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#f87b45",
              textColor: "#004a14",
              zIndex: 1000,
            },
          }}
        />
        <form
          className={`referral referral-access w-4/6 ${
            referralCode == "" ? "" : "hidden"
          } `}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p style={{ color: "gray" }}>
            New to the referral program? Generate an account!
          </p>
          <h3>Enter your password</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type={passwordVisible ? "text" : "password"}
              maxLength={20}
              name="password"
              id=""
              value={password}
              autoFocus={true}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent the default action
                  e.stopPropagation(); // Stop the event from propagating
                  accessreferral();
                }
              }}
            />
            <button
              style={{
                width: "25px",
                height: "25px",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <ViewIcon /> : <ViewIcon />}
            </button>{" "}
          </div>

          <button
            type="submit"
            onClick={() => {
              accessreferral();
            }}
          >
            Access
          </button>
          <button
            onClick={() => {
              generatePassword();
            }}
            disabled={referralCode.length > 0}
            className={`${referralCode.length > 0 ? "gray" : ""}`}
          >
            Generate an account{" "}
          </button>
        </form>
      </div>

      <div
        className={referralCode === "" ? "hidden" : ""}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
        }}
      >
        {" "}
        <Modal isOpen={isGenerateOpen} onClose={onGenerateClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Referral Created</ModalHeader>
            <ModalBody>
              <p>Ensure to save your password to access this panel later!</p>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="orange"
                onClick={() => {
                  // copy password to clipboard
                  navigator.clipboard
                    .writeText(password)
                    .then(() => {
                      toast({
                        title: "Password copied to clipboard",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    })
                    .catch((err) => {});
                  onGenerateClose();
                }}
              >
                Copy Password
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enter XMR Address</ModalHeader>
            <ModalBody>
              <Input
                type="text"
                value={xmrAddress}
                onChange={(e) => setXmrAddress(e.target.value)}
                placeholder="Enter your XMR address"
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="orange" onClick={handlePayoutRequest}>
                Request payout
              </Button>
              <Box width={4} />
              <Button
                onClick={() => {
                  onClose();
                  setIsLoading(false);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
          <ModalOverlay />
          <MotionModalContent
            p={5}
            bgGradient="linear(to-r, teal.300, green.400)"
            borderRadius="xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ModalHeader color="white">Confirmation</ModalHeader>
            <ModalBody>
              <Box color="white" textAlign="center" fontSize="lg">
                <h2 ref={confirmationRef}>
                  Your payout request has been sent! 🎉
                </h2>
                <h3>
                  Once the payout is confirmed, your XMR balance will be reset.
                </h3>
              </Box>
            </ModalBody>
            <ModalFooter>
              <MotionButton
                colorScheme="orange"
                onClick={() => {
                  onConfirmClose();
                }}
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                Okay
              </MotionButton>
            </ModalFooter>
          </MotionModalContent>
        </Modal>
        <div
          style={{ display: "flex", flexDirection: "row", gap: "50px" }}
          className={referralCode === "" ? "hidden" : ""}
        >
          <Stat className="form-referral-stats">
            <StatLabel>Total Earned</StatLabel>
            <StatNumber>${(xmrBalance * cryptoRate).toFixed(2)}</StatNumber>
          </Stat>
          <Stat className="form-referral-stats">
            <StatLabel>Total Payout</StatLabel>
            <StatNumber>${totalPayout.toFixed(2)}</StatNumber>
          </Stat>
          <Stat className="form-referral-stats">
            <StatLabel>Referral Link Uses</StatLabel>
            <StatNumber>{referralUse}</StatNumber>
          </Stat>
          <Stat className="form-referral-stats">
            <StatLabel>XMR Rate</StatLabel>
            <StatNumber>${cryptoRate?.toFixed(2)}</StatNumber>
          </Stat>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "50px" }}>
          <div className="form-referral">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              className={referralCode === "" ? "hidden" : "share-referral"}
            >
              <h1 style={{ fontSize: "20px" }}>Share your referral link</h1>

              <div style={{ display: "flex", gap: "10px" }}>
                <span className="step1">{referralCode}</span>
                <button
                  className="step2"
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://monezon.com/?r=" + referralCode
                    );
                    toast({
                      title: "Successfully copied referral link!",
                      description: "",
                      status: "success",
                      duration: 1000,
                      isClosable: true,
                    });
                    goToNext();
                    localStorage.setItem("hasClickedShare", true);
                  }}
                >
                  <LinkIcon></LinkIcon>
                </button>
                <Tooltip
                  label="Click the button to copy your referral link"
                  fontSize="xs"
                  hasArrow
                >
                  <InfoIcon color="#6c7a89" />
                </Tooltip>
              </div>
              <Divider width="50%" color="#636e7a"></Divider>
              <Text textAlign={"center"}>
                Earn <b>$3</b> on every uses of your <b>referral</b>
              </Text>
            </div>
          </div>
          <div className="form-referral">
            <div className="xmr-balance">
              {xmrBalance * cryptoRate >= 20 && !hasAlreadyRequestedPayout ? (
                <Badge colorScheme="green">ELIGIBLE FOR PAYOUT</Badge>
              ) : (
                <>
                  <Badge colorScheme="red">NOT ELIGIBLE FOR PAYOUT</Badge>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "20px",
                    width: "100%",
                  }}
                >
                  Your balance
                </h1>
                <h1
                  style={{
                    fontSize: "20px",
                    background: "#f87b45",
                    color: "white",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                >
                  {xmrBalance.toFixed(6)} XMR
                </h1>
                <Divider width="50%" color="#636e7a"></Divider>

                {xmrBalance * cryptoRate >= 20 && !hasAlreadyRequestedPayout ? (
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      onOpen();
                      setIsLoading(true);
                    }}
                    isLoading={isLoading}
                  >
                    Request payout
                  </Button>
                ) : (
                  <Fragment>
                    {!hasAlreadyRequestedPayout ? (
                      <Button
                        colorScheme="gray"
                        disabled
                        style={{ display: "flex", textWrap: "break-word" }}
                        pointerEvents={"none"}
                      >
                        Request payout
                      </Button>
                    ) : (
                      <Button
                        colorScheme="gray"
                        disabled
                        style={{ display: "flex", textWrap: "break-word" }}
                        pointerEvents={"none"}
                      >
                        Payout already requested
                      </Button>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="form-referral" style={{ gap: "20px" }}>
            <h1 style={{ fontSize: "20px" }}>How to be eligible</h1>
            <Stepper
              index={activeStep}
              colorScheme="orange"
              orientation="vertical"
              height="400px"
              gap="0"
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
