import React, { useEffect, useState, Fragment } from "react";
import { useData } from "../components/DataContext";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Bounce, ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {
  Checkbox,
  Center,
  Flex,
  Text,
  Heading,
  Button,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepNumber,
  useSteps,
  StepSeparator,
  StepIcon,
  StepTitle,
  StepDescription,
  Box,
  Hide,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Divider,
  Select,
  Textarea,
  Input,
  Link,
  useDisclosure,
  useToast,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Switch,
} from "@chakra-ui/react";

const steps = [
  { title: "First", description: "Wishlist" },
  { title: "Second", description: "Informations" },
  { title: "Third", description: "Passphrase" },
];

function PassPhrase() {
  const { activeStep } = useSteps({
    initialStep: 2,
    index: 2,
    count: steps.length,
  });

  // navigate
  const navigate = useNavigate();

  const { data, dispatch } = useData();

  const [passphrase, setPassphrase] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  async function processOrder() {
    try {
      localStorage.removeItem("referralCode");
      window.location.href = data.checkoutUrl;
    } catch (error) {
      console.error("Error creating invoice:", error.response.data.message);
    }
  }

  const handleSavedClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPassphrase(data.passphrase);
  }, []);

  const savePassphraseAsTxt = () => {
    const textToSave = passphrase.join(" ");
    const element = document.createElement("a");
    const file = new Blob([textToSave], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "passphrase.txt";
    document.body.appendChild(element);
    element.click();
  };

  const copyPassphraseToClipboard = () => {
    const textToCopy = passphrase.join(" ");
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard!", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto", // You can set a specific width if you want
      padding: "20px",
      gap: "10px",
      display: "flex",
      flexDirection: "column",
      // Add more styling if needed
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  };

  return (
    <div
    className="mt-40"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="form"
        style={{ maxWidth: "1200px", width: "90vw", height: "400px" }}
      >
        <Hide below="md">
          <Stepper
            index={activeStep}
            w={"70%"}
            colorScheme="orange"
            size={"md"}
            mb={10}
          >
            {steps.map((step, index) => (
              <Fragment key={index}>
                <Step>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </Step>
                <StepSeparator />
              </Fragment>
            ))}
          </Stepper>
        </Hide>
        <div className="passphrase-text">
          <h2>
            <span style={{ color: "red" }}>Save</span> this passphrase to track
            your order
          </h2>
          <h2>
            Ensure to have this passphrase saved, you’ll{" "}
            <span style={{ color: "red" }}>not</span> be able to get it back
          </h2>
        </div>
        <div className="passphrase">
          {passphrase.map((word, index) => (
            <p key={index}>{word} </p>
          ))}
        </div>
        <div className="passphrase-buttons">
          <button onClick={savePassphraseAsTxt}>Save .txt</button>
          <button onClick={copyPassphraseToClipboard}>Copy</button>
        </div>
        <div className="passphrase-buttons">
          <button className="saved" onClick={handleSavedClick}>
            I saved it
          </button>
        </div>
      </div>
      {
        <ReactModal
          isOpen={showPopup}
          onRequestClose={handlePopupClose}
          contentLabel="Confirm Passphrase Save"
          style={customStyles}
        >
          <h3>Make sure you saved your passphrase</h3>
          <p>Is it saved?</p>
          <div className="popup-buttons">
            <button
              onClick={() => {
                processOrder();
              }}
            >
              Yes
            </button>
            <button onClick={handlePopupClose}>No</button>
          </div>
        </ReactModal>
      }
    </div>
  );
}

export default PassPhrase;
