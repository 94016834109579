import React, { useState, useEffect, useRef } from "react";
import { useToast } from "@chakra-ui/react";
import { Badge } from "@chakra-ui/react";

const PublicChat = () => {
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [username, setUsername] = useState("");
  const wsUrl = process.env.REACT_APP_PUBLIC_CHAT_WS_URL;
  const messagesEndRef = useRef(null);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [token, setToken] = useState(localStorage.getItem("chatToken"));
  const toast = useToast();

  useEffect(() => {
    setToken(localStorage.getItem("chatToken"));
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      setTimeout(() => {
        if (token) {
          ws.send(JSON.stringify({ type: "token", token }));
        }
        scrollToBottom();
      }, 500);
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "token") {
        localStorage.setItem("chatToken", message.token);
        setToken(message.token);
        scrollToBottom();
      } else if (message.type === "message") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            username: message.username,
            message: message.message,
            timestamp: message.timestamp || Date.now(), // Ensure timestamp is present
          },
        ]);
      } else if (message.type === "online") {
        setOnlineUsers(message.count);
      } else if (message.type === "error") {
        toast({
          title: message.message,
          status: "error",
          duration: 2000,
          position: "top",
          isClosable: true,
        });
      }
    };

    ws.onclose = () => {
      console.log("Disconnected from the WebSocket");
      setMessages([]);
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [wsUrl, username]);

  useEffect(() => {
    if (!isScrolledUp) {
      scrollToBottom();
    }
  }, [messages, isScrolledUp]);

  useEffect(() => {
    if (isChatVisible) {
      scrollToBottom();
    }
  }, [isChatVisible]);

  const sendMessage = () => {
    if (
      inputMessage.trim() !== "" &&
      inputMessage.trim().length > 1 &&
      inputMessage.trim().length < 200
    ) {
      socket.send(JSON.stringify({ type: "message", message: inputMessage }));
      setInputMessage("");
    } else {
      toast({
        title: "Message must be between 2 and 200 characters",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleUsernameSubmit = () => {
    if (username.trim() === "") {
      toast({
        title: "Username cannot be empty",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      return;
    }
    socket.send(JSON.stringify({ type: "username", username }));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight < scrollHeight) {
      setIsScrolledUp(true);
    } else {
      setIsScrolledUp(false);
    }
  };

  return (
    <div className="fixed bottom-0 right-10 w-[300px] bg-white border border-orange-300 rounded-t-lg shadow-lg z-50">
      <div
        className="flex justify-between items-center mb-4 p-1 bg-orange-200 cursor-pointer"
        onClick={() => setIsChatVisible(!isChatVisible)}
      >
        <h1 className="text-lg font-bold text-orange-500 flex justify-between items-center w-full">
          Public Chat
          <span className="flex items-center mr-5">
            <span className="relative inline-block mr-2">{onlineUsers}</span>
            {onlineUsers > 0 && (
              <span className="relative inline-block flex items-center">
                <span className="h-2 w-2 bg-green-500 rounded-full mr-1"></span>
              </span>
            )}
          </span>
        </h1>

        <span className="text-sm text-orange-500">
          {isChatVisible ? "▼" : "▲"}
        </span>
      </div>
      {isChatVisible && (
        <>
          {!token && (
            <div className="pb-4 pl-4 pr-4 flex flex-col gap-2">
              <p className="text-sm text-gray-500">
                You'll only be able to use this username from this browser.
              </p>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Choose your username"
                className="border border-gray-300 rounded-lg px-3 py-2 h-8 w-full"
              />
              <button
                onClick={handleUsernameSubmit}
                className="bg-orange-500 text-white px-3 py-2 rounded-lg w-full"
              >
                Set Username
              </button>
            </div>
          )}
          {token && (
            <div
              className="overflow-y-auto h-64 pr-4 pl-4"
              onScroll={handleScroll}
            >
              {messages.map((message, index) => (
                <div key={index} className="mb-2">
                  <div className="flex gap-2 items-center">
                    {message.username?.toLowerCase() === "monezon" ? (
                      <Badge
                        colorScheme="orange"
                        className="text-md font-semibold"
                      >
                        {message.username}
                      </Badge>
                    ) : (
                      <p className="text-md font-semibold">
                        {message.username}
                      </p>
                    )}
                    <p className="text-sm text-gray-500">
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </p>
                  </div>
                  <p className="text-sm">{message.message}</p>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
          {token && (
            <div className="flex p-4">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
                placeholder="Type a message..."
                className="flex-1 mr-2 border border-gray-300 rounded-lg px-3 py-2"
              />
              <button
                onClick={sendMessage}
                className="bg-orange-500 text-white px-2 py-2 rounded-lg"
              >
                Send
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PublicChat;
