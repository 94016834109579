import { BrowserRouter, Routes, Route } from "react-router-dom";
import Delivery from "./pages/deliveryTypeSelection";
import Nav from "./components/nav";
import LogNav from "./components/logNav";
import PassPhrase from "./pages/passphrase";
import Index from "./pages";
import Track from "./pages/track";
import Panel from "./pages/panel";
import CartBuild from "./pages/cartBuild";
import DetailsBuild from "./pages/detailsBuild";
import Login from "./pages/login";
import Register from "./pages/register";
import Chat from "./pages/chat";
import Background from "./components/Background";
import Confirmation from "./pages/confirmation";
import ChatExecutorEntry from "./pages/chatExecutorEntry";
import "./styles/styles.css";
import Faq from "./pages/faq";
import Profile from "./pages/profile";
import ChatEntry from "./pages/chatEntry";
import ThankYou from "./pages/thanks";
import VerifyAccount from "./pages/verify";
import Affiliation from "./pages/affiliation";
import WishListWarning from "./pages/wishListWarning";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminDashboard from "./pages/admin/dashboard";
import ViewPost from "./pages/blog/viewPost";
import AddPost from "./pages/blog/addPost";
import Article from "./pages/blog/article";
import Stats from "./pages/stats";
import ProfileNav from "./components/profileNav";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Nav></Nav>
                <Index></Index>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/faq"
            element={
              <>
                <Nav></Nav>
                <Faq></Faq>
              </>
            }
          ></Route>
          <Route
            path="/delivery"
            element={
              <>
                <Nav></Nav>
                <Delivery></Delivery>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/start-ordering"
            element={
              <>
                <Nav></Nav>
                <WishListWarning></WishListWarning>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/cart"
            element={
              <>
                <Nav></Nav>
                <CartBuild></CartBuild>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/details"
            element={
              <>
                <Nav></Nav>
                <DetailsBuild></DetailsBuild>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/passphrase"
            element={
              <>
                <Nav></Nav>
                <PassPhrase></PassPhrase>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/track"
            element={
              <>
                <Nav></Nav>
                <Track></Track>
              </>
            }
          ></Route>
          <Route
            path="/panel"
            element={
              <>
                <LogNav></LogNav>
                <Panel></Panel>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/login"
            element={
              <>
                <Login></Login>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/register"
            element={
              <>
                <Register></Register>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/chat/entry"
            element={
              <>
                <Nav></Nav>
                <ChatEntry></ChatEntry>
                <Background></Background>
              </>
            }
          />
          <Route
            path="/panel/chat/:id"
            element={
              <>
                <Nav></Nav>
                <ChatExecutorEntry></ChatExecutorEntry>
                <Background></Background>
              </>
            }
          />
          <Route
            path="/verify-your-account"
            element={
              <>
                <Nav></Nav>
                <VerifyAccount></VerifyAccount>
                <Background></Background>
              </>
            }
          />
          <Route
            path="/stats"
            element={
              <>
                <Nav></Nav>
                <Stats></Stats>
              </>
            }
          ></Route>

          <Route
            path="/confirmation"
            element={
              <>
                <Nav></Nav>
                <Confirmation></Confirmation>
                <Background></Background>
              </>
            }
          />

          <Route
            path="/chat/:roomid"
            element={
              <>
                <Nav></Nav>
                <Chat></Chat>
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <ProfileNav></ProfileNav>
                <Profile></Profile>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/thanks"
            element={
              <>
                <ThankYou></ThankYou>
                <Background></Background>
              </>
            }
          ></Route>

          <Route
            path="/referral"
            element={
              <>
                <Nav></Nav>
                <Affiliation></Affiliation>
                <Background></Background>
              </>
            }
          ></Route>

          <Route
            path="/admin/login"
            element={
              <>
                <AdminLogin></AdminLogin>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/admin/dashboard"
            element={
              <>
                <AdminDashboard></AdminDashboard>
                <Background></Background>
              </>
            }
          ></Route>
          <Route
            path="/blog"
            element={
              <>
                <Nav></Nav>
                <ViewPost></ViewPost>
              </>
            }
          ></Route>
          <Route
            path="/post/add"
            element={
              <>
                <Nav></Nav>
                <AddPost></AddPost>
              </>
            }
          ></Route>
          <Route
            path="/article/:postId"
            element={
              <>
                <Nav></Nav>
                <Article></Article>
              </>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
