import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useData } from "../components/DataContext";
import { ec as EC } from "elliptic";
import hash from "hash.js";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ec = new EC("secp256k1");

function ChatEntry() {
  const { data, dispatch } = useData();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState(Array(8).fill(""));

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const handleChange = (index, value) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    setInputs(updatedInputs);
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const data = e.clipboardData.getData("text");
    const words = data.split(" ");
    let newInputs = [...inputs];
    for (let i = index; i < newInputs.length && i - index < words.length; i++) {
      newInputs[i] = words[i - index];
    }
    setInputs(newInputs);
  };

  function generateKeysFromPassword(password) {
    const hashBuffer = hash.sha256().update(password).digest();
    const keys = ec.keyFromPrivate(hashBuffer);
    const publicKey = keys.getPublic("hex");
    const privateKey = keys.getPrivate("hex");
    return [publicKey, privateKey];
  }

  async function sendPassPhrase() {
    const filledInputs = inputs.map(input => input.trim().toLowerCase()).filter(input => input !== "");
    if (filledInputs.length === 8) {
      var passphrase = filledInputs.join(",");
      passphrase = passphrase.replace(/\r\n/g, "").replace(/\s/g, "");
      var passphraseArray = passphrase.split(",");
  
      const [publicKey, privateKey] = generateKeysFromPassword(
        passphraseArray.toString()
      );
  
      try {
        const response = await axios.post("/chat/getRoomP", {
          passphrase: passphraseArray,
        });
        if (response.data.success) {
          const roomId = response.data.message.roomId;
          dispatch({ type: 'SET_USERTYPE', payload: "Buyer" });
  
          dispatch({
            type: "SET_KEYS",
            payload: { private: privateKey, public: publicKey },
          });
          dispatch({
            type: "SET_PUBLICKEY",
            payload: response.data.message.publicKey,
          });
          dispatch({
            type: "SET_TRACKINGCODE",
            payload: response.data.message.trackingCode,
          });
          dispatch({
            type: "SET_DELIVERY_VALIDATED",
            payload: response.data.message.deliveryValidated,
          });
          dispatch({
            type: "SET_ACCEPTED_TIMESTAMP",
            payload: response.data.message.acceptedTimestamp,
          });
          dispatch({
            type: "SET_RATINGS",
            payload: response.data.message.ratings,
          });
          dispatch({
            type: "SET_ORDER_COUNT",
            payload: response.data.message.orderCount,
          });
          dispatch({
            type: "SET_EXECUTOR_USERNAME",
            payload: response.data.message.username,
          });
          dispatch({
            type: "SET_EXECUTOR_XMR_ADDRESS",
            payload: response.data.message.xmrAddress,
          });
          dispatch({
            type: "SET_EXECUTOR_TRUSTLEVEL",
            payload: response.data.message.trustLevel,
          });
  
          navigate(`/chat/${roomId}`);
        }
      } catch (error) {
        setInputs(Array(8).fill(""));
        toast.error(
          "No executor for this order at the moment. Please try again later.",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }
        );
        console.error("Error sending passphrase:", error);
      }
    }
  }
  

  return (
    <div
    className="mt-40"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <form
        className="tracking-words border border-orange-300 rounded-md bg-orange-50 p-4 w-5/6 flex flex-col items-center justify-center"
        onSubmit={(e) => {
          e.preventDefault();
          sendPassPhrase();
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <p>Enter tracking words</p>
        </div>
        <div
          className="input-box"
          style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
        >
          {inputs.map((input, index) => (
            <div style={{ flex: "0 0 25%", marginBottom: "10px" }}>
              <span>{index + 1}. </span>
              <input
                type="text"
                value={input}
                onChange={(e) => handleChange(index, e.target.value)}
                onPaste={(e) => handlePaste(e, index)}
                style={{
                  width: "70%",
                  padding: "5px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center gap-4 mt-4">
        <button
            type="submit"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              color: "white",
              backgroundColor: "#F87B45",
              cursor: "pointer",
            }}

            onClick={() => {
              navigator.clipboard.readText().then(text => {
                const words = text.split(" ").slice(0, 8);
                setInputs(words);
              });
            }}
          >
            Paste
          </button>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              color: "white",
              backgroundColor: "#F87B45",
              cursor: "pointer",
            }}
          >
            Enter
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatEntry;