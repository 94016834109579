import { cn } from "../lib/utils";
import Marquee from "./magicui/marquee";

const reviews = [
    {
        name: "ᴜɴᴛʀᴀᴄᴇᴀʙʟᴇ",
        username: "@DontTraceMeBruh",
        body: "Buy anything from Amazon worldwide and pay with cash over the internet through Monezon. A #Monero to Amazon bridge. Because Monero keeps your financial business, your business.Its the most secure way to transact online.",
        img: "https://pbs.twimg.com/profile_images/1715247253609222144/Q1ghnEV4_400x400.jpg",
    },
    {
        name: "Victor",
        username: "@VictorMoneroXMR",
        body: "Wow 😍 #monero #monezon",
        img: "https://pbs.twimg.com/profile_images/1774022597493047297/NVD0Pqlt_400x400.jpg",
    },
    {
        name: "XMRadar🗽",
        username: "@XMRadar",
        body: "Buy ANY product from #amazon using #Monero with @atMonezon.",
        img: "https://pbs.twimg.com/profile_images/1793666097851355136/UOnyUG11_400x400.png",
    },
    {
        name: "Pavol Lupták",
        username: "@wilderko",
        body: "Shop on Amazon Anonymously: Enjoy secure, private shopping on Amazon with your monero. https://monezon.com",
        img: "https://pbs.twimg.com/profile_images/1293173311212486659/LnLz3tcC_400x400.jpg",
    },
    {
        name: "FREDERIK DIAMOND",
        username: "@frederikdiamond",
        body: "A lot of progress is being made on Monezon. I highly recommend it! It's a great way to convert fiat currency to Monero by buying products on Amazon for people. It's also a great option to spend your Monero on the countless products available on Amazon. #Monero #Monezon",
        img: "https://pbs.twimg.com/profile_images/1466655107387379715/mQ_gUEyM_400x400.jpg",
    },
    {
        name: "MyMonero",
        username: "@MyMonero",
        body: "I'm at a loss for words. This is amazing. I love it.",
        body: "Did you know that there's an easy gateway to secure, private shopping on Amazon using #Monero?! We love this concept 👏 Take a look 👀 ➡️ https://monezon.com",
        img: "https://pbs.twimg.com/profile_images/1443462091621478403/-MUHzb0X_400x400.jpg",
    },
    {
        name: "VAI",
        username: "@okxvai",
        body: "Monezon. Privacy is not a crime. Regulators of countries and CEX will hide Monero #XMR from the new cryptocurrency community. Talk about Monero.",
        img: "https://pbs.twimg.com/profile_images/1784860033013956608/jrAUt-4X_400x400.jpg",
    },
    {
        name: "Muchachada Libertaria🤝",
        username: "@daslibertarien",
        body: "How to buy in Amazon using $XMR ? Cómo comprar en Amazon con #Monero ? @atMonezon",
        img: "https://pbs.twimg.com/profile_images/1627332184590757888/oxijaiT1_400x400.jpg",
    },
    {
        name: "cryptoholix",
        username: "@crypptoholix",
        body: "Here we go #Monero to #Amazon bridge 🌉 https://monezon.com",
        img: "https://pbs.twimg.com/profile_images/1778384399010025472/GxLcj3yq_400x400.jpg",
    },
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
    img,
    name,
    username,
    body,
}) => {
    return (
        <figure
            className={cn(
                "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
                // light styles
                "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
                // dark styles
                "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
            )}
        >
            <div className="flex flex-row items-center gap-2">
                <img className="rounded-full" width="32" height="32" alt="" src={img} />
                <div className="flex flex-col">
                    <figcaption className="text-sm font-medium dark:text-black">
                        {name}
                    </figcaption>
                    <p className="text-xs font-medium dark:text-black/40">{username}</p>
                </div>
            </div>
            <blockquote className="mt-2 text-sm">{body}</blockquote>
        </figure>
    );
};

const MarqueeDemo = () => {
    return (
        <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background py-20 md:shadow-xl text-black">
            <Marquee pauseOnHover className="[--duration:20s]">
                {firstRow.map((review) => (
                    <ReviewCard key={review.username} {...review} />
                ))}
            </Marquee>
            <Marquee reverse pauseOnHover className="[--duration:20s]">
                {secondRow.map((review) => (
                    <ReviewCard key={review.username} {...review} />
                ))}
            </Marquee>
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
        </div>
    );
};

export default MarqueeDemo;