import IconCloud from "./magicui/icon-cloud";
import avax from '../styles/avax.svg';

const slugs = [
    "bitcoin",
    "tether",
    "ethereum",
    "monero",
    "litecoin",
    "dogecoin",
    "solana",
    "polygon",
    "binance",
];

const customIcons = {
    customIcon1: avax,
};

export default function IconCloudDemo() {
    return (
        <div className="mt-20 border relative flex h-[300px] smE:w-[70%] w-[90%] items-center justify-center overflow-hidden rounded-lg bg-[radial-gradient(circle_at_50%_200%,rgba(0,0,0,0.2),rgba(255,255,255,0))]">
            <span className="absolute smE:top-8 top-12 pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center smE:text-4xl text-xl font-semibold leading-none text-transparent">
                Working with 800+ Cryptos
            </span>
            <IconCloud iconSlugs={slugs} customIcons={customIcons} />
        </div>
    );
}