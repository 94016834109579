import React, { useState } from "react";
import Modal from "react-modal";
import Session from "../styles/session.png";
import X from "../styles/X.avif";
import SessionQR from "../styles/sessionqr.jpg";

function Verify() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="verify">
      <h1>
        We’ve <span>successfully</span> received your account creation request!
      </h1>
      <h2>
        <span>DM us</span> on X / Session to confirm your account
        <br />
        and start <span>executing</span> orders
      </h2>
      <p style={{ textDecoration: "underline", marginBottom: "10px" }}>click to contact</p>
      <div className="contImg">
        <a>
          <img src={Session} alt="" srcSet="" onClick={openModal} />
        </a>
        or
        <a target="_blank" href="https://x.com/atMonezon?s=20">
          <img src={X} alt="" srcSet=""/>
        </a>
      </div>
      <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={{ overlay: { background: "#000000ad" } }}
      className={"session-modal"}
      >
        <h3>Contact us on Session</h3>
        <p>Scan the QR code below</p>
        <img src={SessionQR} alt="" />
        <p>Or copy and paste the following link</p>
        <p style={{ wordBreak: "break-word" }}>Session key: 058f0af56f68bb0e4110ac27c0f791cb2293b3d92c9b757e46efed129892f3960e</p>
      </Modal>
    </div>
  );
}

export default Verify;
