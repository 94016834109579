import { BorderBeam } from "./magicui/border-beam";
import chat from "../styles/chat.png";
export default function BorderBeamDemo() {
  return (
    <div className="relative w-fit rounded-xl">
      <img
        src={chat}
        alt="Hero Image"
        className="block w-[700px] rounded-[inherit] border object-contain shadow-lg"
      />
      
      <BorderBeam size={250} duration={12} delay={9} />
    </div>
  );
}
