import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Hamburger from "hamburger-react";
import Button from "./magicui/button";
import Monezon from "../styles/monezon.svg"; // Fix the import path

function Nav() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    checkIsMobile();
    handleScroll();

    window.addEventListener("resize", checkIsMobile);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <nav className={`fixed flex justify-center items-center top-0 left-0 right-0 z-30 transition-all duration-300 h-16 w-5/6 mx-auto ${isMobile ? 'mt-8 mb-1' : 'mt-16 mb-10'} bg-white shadow-md rounded-lg ${isScrolled ? 'nav-scrolled' : ''}`}>
        <div className={`w-full mx-auto flex items-center rounded-lg`}>
          <div className={`flex items-center w-full px-4 sm:px-6 lg:px-8 ${isMobile ? 'justify-evenly' : 'justify-between'}`}>
            <div className="flex items-center gap-2">
              <Link to="/" className="flex-shrink-0">
                <img src={Monezon} className="h-8 w-auto" alt="Monezon logo" />
              </Link>

              {isMobile ? null : (
                <Link
                  to="/login"
                  className="ml-4 p-2 py-1 bg-orange-500 text-white text-md font-medium rounded-md"
                >
                  Executor Panel
                </Link>
              )}
            </div>
            {isMobile ? (
              <div className="flex md:hidden">
                <Hamburger toggled={isMobileMenuOpen} toggle={toggleMobileMenu} className="h-6 w-6" />
              </div>
            ) : (
              <div className="md:flex right-0 flex relative items-center space-x-4">
                <Link to="/faq" className="text-gray-600 hover:text-gray-900">
                  F.A.Q
                </Link>
                <Link to="/blog" className="text-gray-600 hover:text-gray-900">
                  Blog
                </Link>
                <Link to="/stats" className="text-gray-600 hover:text-gray-900">
                  Stats
                </Link>
                <Link to="/referral">
                  <Button
                    variant="outline"
                    className="bg-orange-100 text-orange-600 hover:bg-orange-200 p-2 rounded-lg"
                  >
                    Referral
                  </Button>
                </Link>
                <Link to="/chat/entry">
                  <Button className="bg-orange-500 text-white hover:bg-orange-600 p-2 rounded-lg">
                    Track Your Order
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div className="fixed top-20 right-8 h-50 w-2/5 max-w-xs bg-white shadow-lg z-50 px-4 py-6 sm:px-6 rounded-lg">
          <div className="flex justify-end">
          </div>
          <div className="space-y-1">
            <Link
              to="/faq"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              F.A.Q
            </Link>
            <Link
              to="/blog"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Blog
            </Link>
            <Link
              to="/stats"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Stats
            </Link>
            <Link
              to="/referral"
              className="block px-3 py-2 bg-orange-100 text-orange-600 hover:bg-orange-200 p-2 rounded-lg w-full"
            >
              Referral
            </Link>
            <Link
              to="/chat/entry"
              className="block px-3 py-2 rounded-md text-base font-medium text-white bg-orange-500 hover:bg-orange-600 text-sm text-center"
            >
              Track Your Order
            </Link>
            <Link to="/login" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-orange-500 hover:bg-orange-600 text-sm text-center">
              Executor Panel
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Nav;
