import cartIcon from "../styles/cartIcon.png";
import orderIcon from "../styles/order.png";

import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useData } from "../components/DataContext";
import logoImage from "../styles/cart.png";
import noOrder from "../styles/no-order.png";
import wishlistPDF from "../styles/wishlist.pdf";
import ReactModal from "react-modal";

import {
  Heading,
  Text,
  Button,
  Highlight,
  Image,
  Checkbox,
  Center,
  Flex,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepNumber,
  Box,
  StepTitle,
  StepDescription,
  StepSeparator,
  useSteps,
  StepIcon,
  Hide,
  InputLeftAddon,
  Input,
  InputGroup,
  Divider,
  AbsoluteCenter,
  Stack,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  useTheme,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Spacer,
  InputRightAddon,
  Select,
  ButtonGroup,
} from "@chakra-ui/react";

import {
  CopyIcon,
  QuestionOutlineIcon,
  WarningIcon,
  InfoIcon,
  EmailIcon
} from "@chakra-ui/icons";

import shippedByAmazon from "../styles/shippedByAmazon.png";

const steps = [
  { title: "First", description: "Wishlist" },
  { title: "Second", description: "Informations" },
  { title: "Third", description: "Passphrase" },
];

const countries = [
  "United States",
  "United Kingdom",
  "France",
  "Germany",
  "Canada",
  "Australia",
  "Italy",
  "Spain",
  "Japan",
  "China",
  "India",
  "Brazil",
  "Mexico",
  "Netherlands",
  "Singapore",
  "Sweden",
  "United Arab Emirates",
  "Saudi Arabia",
  "Turkey",
  "South Korea",
  "Switzerland",
  "Austria",
  "Belgium",
  "Norway",
  "Denmark",
  "Ireland",
  "New Zealand",
  "Finland",
  "Portugal",
  "Poland",
  "Greece",
  "Hungary",
  "Czech Republic",
  "Romania",
  "Russia",
  "South Africa",
  "Malaysia",
  "Thailand",
  "Indonesia",
  "Philippines",
  "Taiwan",
  "Vietnam",
  "Egypt",
  "Israel",
  "Argentina",
  "Chile",
  "Colombia",
  "Peru",
];

function CartBuild() {
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(0);
  const [initialPrice, setInitialPrice] = useState(0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(initialPrice);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [showDiscountExplanation, setShowDiscountExplanation] = useState(false);
  const [showEmailExplanation, setShowEmailExplanation] = useState(false);
  const [deliveryPreference, setDeliveryPreference] = useState("Address");
  const [lockerCountry, setLockerCountry] = useState("United States");
  const [lockerCity, setLockerCity] = useState("");
  const [lockerName, setLockerName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [shippingOption, setShippingOption] = useState("Earliest"); // Default to Earliest

  const theme = useTheme();

  function toggleDiscountExplanation() {
    setShowDiscountExplanation(!showDiscountExplanation);
  }

  function toggleEmailExplanation() {
    setShowEmailExplanation(!showEmailExplanation);
  }

  const handlePriceValue = (e) => {
    const newPrice = parseFloat(e.target.value);
    setInitialPrice(newPrice || 0);
  };

  useEffect(() => {
    const calculateEstimatedDeliveryTime = (discount) => {
      const baseDeliveryTime = 0;

      const additionalTimePerDiscount = Math.floor(discount / 5);

      return baseDeliveryTime + additionalTimePerDiscount;
    };

    // Update the estimated delivery time based on the current discount
    const newEstimatedDeliveryTime =
      calculateEstimatedDeliveryTime(sliderValue);
    setEstimatedDeliveryTime(newEstimatedDeliveryTime);
  }, [sliderValue]);

  useEffect(() => {
    const discount = sliderValue / 100;
    const discountedAmount = initialPrice * discount;
    const newPrice = initialPrice - discountedAmount;
    setPriceWithDiscount(newPrice);
  }, [sliderValue, initialPrice]);

  const getColor = (value) => {
    const green = theme.colors.green[400];
    const yellow = theme.colors.yellow[400];
    const red = theme.colors.red[400];

    if (value <= 7.5) {
      // Interpolation entre vert et jaune
      const mixRatio = value / 7.5;
      return `rgba(${mixRatio * 255 + (1 - mixRatio) * 0}, ${
        mixRatio * 255 + (1 - mixRatio) * 128
      }, 0)`;
    } else {
      // Interpolation entre jaune et rouge
      const mixRatio = (value - 7.5) / 7.5;
      return `rgba(255, ${255 - mixRatio * 255}, 0)`;
    }
  };

  const toast = useToast();

  const { activeStep } = useSteps({
    initialStep: 1,
    index: 0,
    count: steps.length,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleClose() {
    setShowConfirmation(false);
  }

  const navigate = useNavigate();
  const { data, dispatch } = useData();
  const [urlInput, setUrlInput] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(true);

  const [cart, setCart] = useState({});

  const orderIconRef = useRef(null); // Étape 2: Création d'une référence

  useEffect(() => {
    const isCartEmpty = Object.keys(cart).length === 0;
    setCartEmpty(isCartEmpty);

    if (!isCartEmpty && orderIconRef.current) {
      // Déclencher les événements touchstart et touchend
      const touchStartEvent = new Event("touchstart");
      orderIconRef.current.dispatchEvent(touchStartEvent);
      console.log("pressing");

      // Attendre 2 secondes avant de déclencher l'événement touchend
      setTimeout(() => {
        const touchEndEvent = new Event("touchend");
        orderIconRef.current.dispatchEvent(touchEndEvent);
      }, 2000); // Délai de 2 secondes pour simuler un appui long
    }
  }, [cart]);

  async function addItem() {
    const fields = ["url", "totalValue", "emailValue"].map(
      (name) => document.getElementsByName(name)[0]
    );

    if (fields[1].value < 0) {
      return false;
    }

    if (fields.slice(0, 2).some((field) => field.value === "")) {
      return false;
    }

    if (fields[2].value == "") {
      fields[2].value = null;
    }

    if (fields[2].value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(fields[2].value)) {
        toast({
          title: "Invalid Email",
          description: "Please enter a valid email address",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    }

    if (
      !fields[0].value.startsWith("https://") &&
      !fields[0].value.startsWith("http://")
    ) {
      toast({
        title: "Invalid URL",
        description: "Please enter a valid URL",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    if (
      lockerCountry.length > 2 &&
      lockerCity.length > 2 &&
      lockerName.length > 2
    ) {
      const newItem = {
        url: fields[0].value,
        totalValue: fields[1].value,
        email: fields[2].value,
        lockerCountry,
        lockerCity,
        lockerName,
        shippingOption,
      };
      console.log(newItem);
      setCart(newItem);
    } else {
      const newItem = {
        url: fields[0].value,
        totalValue: fields[1].value,
        email: fields[2].value,
        shippingOption,
      };
      setCart(newItem);
    }
  }

  useEffect(() => {
    // Check if the cart is empty based on a specific condition
    const isCartEmpty = Object.keys(cart).length === 0;
    // Update the state to trigger a re-render
    setCartEmpty(isCartEmpty);
  }, [cart]); // Run the effect whenever the cart changes

  // State to track whether the cart is empty
  const [isCartEmpty, setCartEmpty] = useState(true);
  return (
    <Center mb={10} mt={32}>
      <Box
        w={"90vw"}
        bgColor="rgba(255, 255, 255, 0.8)"
        style={{ backdropFilter: "blur(10px)" }}
        boxShadow="lg"
        borderRadius={10}
        padding={5}
      >
        <Flex direction="column" alignItems="center" gap={2}>
          <Hide below="md">
            <Stepper
              index={activeStep}
              w={"70%"}
              colorScheme="orange"
              size={"md"}
              mb={10}
            >
              {steps.map((step, index) => (
                <Fragment key={index}>
                  <Step>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </Step>
                  <StepSeparator />
                </Fragment>
              ))}
            </Stepper>
          </Hide>
          <Flex direction={"column"} gap={7} alignItems={"center"}>
            <Text
              onClick={() => {
                if (deliveryPreference === "Locker") {
                  setDeliveryPreference("Address");
                } else {
                  setDeliveryPreference("Locker");
                }
              }}
              _hover={{ cursor: "pointer" }}
              textDecoration={"underline"}
            >
              I want to ship to{" "}
              {deliveryPreference === "Locker" ? "an address" : "a locker"}
            </Text>
            <Flex align="center" direction={"column"} w="full">
              <InputGroup flex="1">
                <InputLeftAddon w={"50px"}>
                  <CopyIcon />
                </InputLeftAddon>
                <Input
                  onChange={(e) => setUrlInput(e.target.value)}
                  type="url"
                  placeholder="Wishlist URL"
                  name="url"
                />
              </InputGroup>
            </Flex>

            {deliveryPreference === "Locker" && (
              <Fragment>
                <InputGroup w="full">
                  <InputLeftAddon w={"50px"}>
                    <CopyIcon />
                  </InputLeftAddon>
                  <Select
                    placeholder="Country"
                    maxW={"90%"}
                    size="md"
                    name="country"
                    value={lockerCountry}
                    onChange={(event) => {
                      setLockerCountry(event.target.value);
                    }}
                    required
                  >
                    {countries.map((c) => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup w="full">
                  <InputLeftAddon w={"50px"}>
                    <CopyIcon />
                  </InputLeftAddon>
                  <Input
                    flex="1"
                    onChange={(e) => setLockerCity(e.target.value)}
                    type="url"
                    placeholder="Locker City"
                    name="url"
                  />
                </InputGroup>
                <InputGroup w="full">
                  <InputLeftAddon w={"50px"}>
                    <CopyIcon />
                  </InputLeftAddon>
                  <Input
                    flex="1"
                    onChange={(e) => setLockerName(e.target.value)}
                    type="url"
                    placeholder="Locker Name"
                    name="url"
                  />
                </InputGroup>
              </Fragment>
            )}

            <Tooltip label="With Shipping Fees" placement="bottom" hasArrow>
              <InputGroup w="full">
                <InputLeftAddon w={"50px"}>
                  <CopyIcon />
                </InputLeftAddon>
                <Input
                  type="number"
                  placeholder="Wishlist Price"
                  name="totalValue"
                  onChange={handlePriceValue}
                  onBlur={handlePriceValue}
                  borderRadius="0"
                  borderTopRightRadius="md"
                  borderBottomRightRadius="md"
                  min={1}
                  flex="1"
                />
              </InputGroup>
            </Tooltip>

            <InputGroup w="full">
  <InputLeftAddon w="50px">
    <EmailIcon />
  </InputLeftAddon>
  <Input
    type="text"
    placeholder="Email (recommended)"
    name="emailValue"
    value={emailAddress}
    onChange={(e) => setEmailAddress(e.target.value)}
    borderRadius="0"
    borderTopRightRadius="md"
    borderBottomRightRadius="md"
    min={1}
    flex="1"
  />
  <QuestionOutlineIcon
    className="-right-[35px] top-[50%] -translate-x-[50%] -translate-y-[50%]"
    position="absolute"
    onClick={toggleEmailExplanation}
    cursor="pointer"
  />
</InputGroup>

<ButtonGroup isAttached variant="outline" mt="2" justifyContent={"center"}>
  <Button
    colorScheme={shippingOption === "Earliest" ? "orange" : "gray"}
    onClick={() => setShippingOption("Earliest")}
  >
    Earliest Shipping
  </Button>
  <Button
  colorScheme={shippingOption === "Latest" ? "orange" : "gray"}
  onClick={() => setShippingOption("Latest")}
  style={{paddingRight: "20px"}}
>
  Latest Shipping
  <div >
    <Tooltip label="Environmentally friendly" fontSize="xs" hasArrow >
      <svg
        style={{top: 0, right: 0, width: "15px", marginLeft: "5px", paddingRight: "3px", position: "absolute"}}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#19c235"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-leaf"
      >
        <path d="M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z" />
        <path d="M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12" />
      </svg>
    </Tooltip>
  </div>
</Button>
</ButtonGroup>

            <Button
              colorScheme="orange"
              size="sm"
              bgGradient="linear(to-r, orange.500, #fb966d)"
              _hover={{ bg: "orange.500" }}
              mt={5}
              pt={3}
              pb={3}
              pr={10}
              pl={10}
              w={"fit-content"}
              onClick={addItem}
            >
              Add Wishlist
            </Button>
          </Flex>
          <Stack width="100%" spacing={4} position={"relative"} mt={5}>
            <Divider />
            <AbsoluteCenter px="4">
              <img style={{ height: "20px" }} src={cartIcon} alt="" srcset="" />
            </AbsoluteCenter>
          </Stack>
        </Flex>
        <Center mt={10}>
          <Flex
            direction="column"
            align="center"
            w={{ base: "95%", md: "80%" }}
            boxShadow="md"
            pt="6"
            pb={1}
            rounded="md"
            bg="white"
            position={"relative"}
          >
            <QuestionOutlineIcon
              position={"absolute"}
              top={5}
              right={5}
              onClick={toggleDiscountExplanation}
              cursor="pointer"
            />
            <Heading as="h4" size="xs">
              Select your discount
            </Heading>
            <Slider
              id="slider"
              defaultValue={0}
              min={0}
              max={15}
              onChange={(v) => setSliderValue(v)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              step={1}
              mt={5}
              w={"90%"}
            >
              <SliderMark
                value={0}
                mt="1"
                ml="-2.5"
                fontSize={{ base: "xs", md: "sm" }}
              >
                0%
              </SliderMark>
              <SliderMark
                value={5}
                mt="1"
                ml="-2.5"
                fontSize={{ base: "xs", md: "sm" }}
              >
                5%
              </SliderMark>
              <SliderMark
                value={10}
                mt="1"
                ml="-2.5"
                fontSize={{ base: "xs", md: "sm" }}
              >
                10%
              </SliderMark>
              <SliderMark
                value={15}
                mt="1"
                ml="-2.5"
                fontSize={{ base: "xs", md: "sm" }}
              >
                15%
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack bg={getColor(sliderValue)} />
              </SliderTrack>
              <Tooltip
                hasArrow
                bg="orange.500"
                color="white"
                placement="top"
                isOpen={showTooltip}
                label={`${sliderValue}%`}
              >
                <SliderThumb boxSize={4} bg="white" />
              </Tooltip>
            </Slider>
            <StatGroup mt={10}>
              <Stat>
                <StatLabel
                  fontSize={{ base: "sm", md: "md" }}
                  color={"gray.500"}
                >
                  Estimated delivery time
                </StatLabel>
                <StatNumber fontSize={{ base: "sm", md: "lg" }}>
                  +{estimatedDeliveryTime} day(s)
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel
                  fontSize={{ base: "sm", md: "md" }}
                  color={"gray.500"}
                >
                  Price with Discount
                </StatLabel>
                <StatNumber fontSize={{ base: "md", md: "lg" }}>
                  ${priceWithDiscount.toFixed(2)}
                </StatNumber>
                <StatHelpText fontSize={{ base: "xs", md: "sm" }}>
                  <StatArrow type="increase" />
                  Discount: {sliderValue}%
                </StatHelpText>
              </Stat>
            </StatGroup>
            <Text fontSize="xs" mt={5} color={"gray.500"} textAlign={"center"}>
              The smaller the discount, the quicker it will be claimed by an
              executor.
            </Text>
          </Flex>
        </Center>
        <Center>
          <Box
            bgColor={"#EFEFEF"}
            w={"100%"}
            h={"25vh"}
            mt={10}
            borderRadius={7}
            maxW={"700px"}
            p={3}
          >
            <Box
              w={"100%"}
              h={"100%"}
              borderRadius={7}
              border={"1px dashed #ABABAB"}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                w={"100%"}
                h={"100%"}
                flexDirection={"column"}
                gap={5}
              >
                {isCartEmpty ? (
                  <div></div>
                ) : (
                  <div
                    style={{
                      height: "45%",
                      width: "fit-content",
                      position: "relative",
                    }}
                  >
                    <Image
                      src={orderIcon}
                      alt=""
                      height={"100%"}
                      ref={orderIconRef}
                      className={!isCartEmpty ? "scale-bounce" : ""}
                    />
                    <span
                      style={{
                        // Étape 2 & 3
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        width: "25px", // Ajustez selon la taille souhaitée
                        height: "25px", // Ajustez selon la taille souhaitée
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "10px", // Ajustez la taille du texte si nécessaire
                      }}
                    >
                      -{sliderValue}%
                    </span>
                  </div>
                )}
                <Button
                  colorScheme="orange"
                  bgGradient="linear(to-r, orange.500, #fb966d)"
                  _hover={{ bg: "orange.500" }}
                  pt={1}
                  pb={1}
                  pr={5}
                  pl={5}
                  onClick={() => {
                    const updatedCart = { ...cart };
                    console.log(cart);
                    dispatch({ type: "SET_CART", payload: updatedCart });
                    dispatch({
                      type: "SET_CHOOSED_DISCOUNT",
                      payload: sliderValue,
                    });
                    navigate("/details");
                  }}
                  disabled={cart.totalValue < 0}
                  display={isCartEmpty ? "none" : "block"}
                >
                  Continue
                </Button>
              </Flex>
            </Box>
          </Box>
        </Center>
      </Box>

      <Modal
        isOpen={showConfirmation}
        onClose={handleClose}
        closeOnOverlayClick={true}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
        >
          <ModalHeader>
            <Heading style={{ marginBottom: "10px" }}>Notice 📝</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              display={"flex"}
              gap={2}
              textDecoration={"underline"}
              alignItems={"center"}
            ></Text>
            <Text fontSize={{ base: "sm", md: "lg", lg: "lg" }}>
              <Highlight
                query={[
                  "select items shipped by Amazon",
                  "provide your shipping address",
                  "Amazon",
                  "issues",
                  "third-party sellers",
                ]}
                styles={{
                  px: "2",
                  py: "1",
                  rounded: "full",
                  bg: "orange.100",
                  lineHeight: "40px",
                  fontWeight: "bold",
                }}
              >
                You need to either select items that are shipped by Amazon or
                provide your shipping address to your executor, as Amazon is
                encountering issues with third-party sellers.
              </Highlight>
            </Text>
            <img
              src={shippedByAmazon}
              alt="Item shipped by amazon"
              style={{ height: "150px", marginTop: "20px" }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              size="md"
              colorScheme="orange"
              mt="24px"
              onClick={handleClose}
            >
              I understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showDiscountExplanation}
        onClose={toggleDiscountExplanation}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
        >
          <ModalHeader>
            <Heading as="h3" size="lg">
              Discount System Explained
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">
              <Highlight
                query={[
                  "0%",
                  "15%",
                  "Higher discounts",
                  "longer wait times",
                  "executors",
                  "better returns",
                  "selected discount",
                  "directly impacts",
                ]}
                styles={{
                  px: "2",
                  py: "1",
                  rounded: "full",
                  bg: "orange.100",
                  lineHeight: "40px",
                  fontWeight: "bold",
                }}
              >
                Choose a discount from 0% to 15% on your order. Higher discounts
                may lead to longer wait times as executors prefer orders with
                better returns. Your selected discount directly impacts how
                quickly an executor will claim your order.
              </Highlight>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" onClick={toggleDiscountExplanation}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showEmailExplanation}
        onClose={toggleEmailExplanation}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
        >
          <ModalHeader>
            <Heading as="h3" size="lg">
              Why Email Notifications are Important
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">
              We use email notifications to keep you informed about your orders.
              <br />
              If you <strong>haven't read</strong> a message from your executor{" "}
              <strong>within 12 hours</strong>, you will{" "}
              <strong>receive an email</strong>.<br />
              <br />
              You will also be <strong>notified</strong> when your order is{" "}
              <strong>taken</strong> by an executor, when it is{" "}
              <strong>purchased</strong>, when it is <strong>shipped</strong>,
              when it is <strong>delivered</strong>, or if the executor{" "}
              <strong>cancels</strong> the order.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" onClick={toggleEmailExplanation}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}

export default CartBuild;
