import BoxReveal from "./magicui/box-reveal";

export default function BoxRevealDemo2() {
    return (
        <div className="h-full w-full max-w-[32rem] items-center justify-center overflow-hidden pt-8">
            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <p className="smE:text-[2.5rem] text-[2rem] font-semibold">
                    Choose your discount<span className="text-[#F38154]">.</span>
                </p>
            </BoxReveal>

            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <h5 className="mt-[.5rem] smE:text-[2rem] text-[1.5rem]">
                    Select from 0% to 15% off{" "}
                    <span className="text-[#F38154]">on your order</span>
                </h5>
            </BoxReveal>

            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <div className="mt-[1.5rem]">
                    <p>
                        -&gt; Enjoy discounts with <span className="font-semibold text-[#F38154]">no</span> minimum or limit on the order price.<br />
                        -&gt; Choose the best discount for you <span className="font-semibold text-[#F38154]">without</span> any restrictions.<br />
                    </p>
                </div>
            </BoxReveal>
        </div>
    );
}