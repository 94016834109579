import { useState, Fragment, useEffect } from "react";
import { useData } from "../components/DataContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wishlistGif from "../styles/wishlistConfiguration.gif";
import {
  Checkbox,
  Center,
  Flex,
  Text,
  Heading,
  Button,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepNumber,
  useSteps,
  StepSeparator,
  StepIcon,
  StepTitle,
  StepDescription,
  Box,
  Hide,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Divider,
  Select,
  Textarea,
  Input,
  Link,
  useDisclosure,
  useToast,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Switch,
  Badge,
  Spinner,
} from "@chakra-ui/react";

import { CheckIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import e from "cors";

const steps = [
  { title: "First", description: "Wishlist" },
  { title: "Second", description: "Informations" },
  { title: "Third", description: "Passphrase" },
];

const countries = [
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Canada",
  "Chile",
  "China",
  "Colombia",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Vietnam"
];


function DetailsBuild() {
  const navigate = useNavigate();
  const { data, dispatch } = useData();
  const [priorityTips, setPriorityTips] = useState(0);
  const [country, setCountry] = useState("United States");
  const [isUSTaxApplicable, setIsUSTaxApplicable] = useState(true);
  const [taxRate, setTaxRate] = useState(0.08);
  const [serviceFee, setTotalServiceFee] = useState(10);
  const [feePercentage, setFeePercentage] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [discount, setDiscount] = useState("");
  const [choosedDiscount, setDiscountChoosed] = useState(data.choosedDiscount);
  const [referral, setReferral] = useState("");
  const [tempReferral, setTempReferral] = useState("");
  const [loader, setLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenModal = () => {
    setTempReferral(referral);
    onOpen();
  };

  const handleCloseModal = () => {
    setTempReferral("");
    onClose();
  };

  const handleApplyReferral = () => {
    setReferral(tempReferral);
    onClose();
  };

  const toast = useToast();

  const handlediscountChange = (event) => {
    setDiscount(event.target.value);
  };

  useEffect(() => {
    if (!data.cart.lockerCountry) {
    } else {
      setCountry(data.cart.lockerCountry);
    }
  }, [data]);

  useEffect(() => {
    calculateTotal();
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    setIsUSTaxApplicable(country === "United States");
  }, [country]);

  const calculateTotal = () => {
    const discountAmount = choosedDiscount ? (data.cart.totalValue * choosedDiscount) / 100 : 0;

    console.log("The discount amount is", discountAmount)
    const discountedSubtotal = data.cart.totalValue - discountAmount;
    console.log("The discounted subtotal is", discountedSubtotal)
  
    // Calculate the fee based on the discounted subtotal
    let feesPercentage = 0;
    if (discountedSubtotal <= 100) {
      feesPercentage = 5; // 5% fee for orders up to $100
    } else if (discountedSubtotal > 100 && discountedSubtotal <= 500) {
      feesPercentage = 4; // 4% fee for orders $101 to $500
    } else if (discountedSubtotal > 500 && discountedSubtotal <= 1000) {
      feesPercentage = 3.5; // 3.5% fee for orders $501 to $1,000
    } else if (discountedSubtotal > 1000) {
      feesPercentage = 3; // 3% fee for orders above $1,000
    }
    const feeAmount = (serviceFee + (discountedSubtotal * feesPercentage / 100));
    console.log("The fee amount is", feeAmount)
    // Calculate the total before service fee and tips
    const totalBeforeServiceAndTips = discountedSubtotal + feeAmount;
    console.log("The total before service and tips is", totalBeforeServiceAndTips)
  
    // Final total calculation
    const totalOrder = totalBeforeServiceAndTips  + priorityTips;
    console.log("The total order is", totalOrder)
  
  
    setFeePercentage(feesPercentage);
    setTotalOrder(totalOrder);
  };

  async function sendOrder() {
    const notes = document.getElementsByName("notes")[0].value;
    const refundaddress = document.getElementsByName("refundaddress")[0].value;

    if (!(refundaddress === "" || country === "")) {
      if (!data.cart.lockerCity) {
        axios
          .post("/orders/new", {
            data: {
              delivery_info: {
                country: country,
                note: notes,
              },
              wishlist: data.cart.url,
              priority_tips: priorityTips,
              refund_address: refundaddress,
              total_order: totalOrder,
              original_total: data.cart.totalValue,
              statusPanel: "Set",
              notePanel: "",
              email: data.cart.email,
            },
            discount: discount.length > 0 ? discount : null,
            referral: referral.length > 0 ? referral : null,
            choosedDiscount: choosedDiscount > 0 ? choosedDiscount : null,
            feePercentage: feePercentage,
            shippingOption: data.cart.shippingOption
          })
          .then((response) => {
            dispatch({ type: "SET_PASSPHRASE", payload: response.data.words });
            dispatch({ type: "SET_CHECKOUTURL", payload: response.data.url });
            navigate("/passphrase");
          })
          .catch((error) => {
            if (error.response.data.code == "promo") {
              setTotalServiceFee(10);
              setDiscount("");
              toast({
                title:
                  "Promo has already been used by the maximum amount of user.",
                autoClose: 1000,
                duration: 1000,
                status: "error",
                hideProgressBar: true,
                closeOnClick: true,
              });
            } else {
              toast({
                title: "Something went wrong. Try again in 2 minutes",
                autoClose: 1000,
                duration: 1000,
                status: "error",
                hideProgressBar: true,
                closeOnClick: true,
              });
            }
          });
      } else {


        axios
          .post("/orders/new", {
            data: {
              delivery_info: {
                country: country,
                lockerCity: data.cart.lockerCity ? data.cart.lockerCity : null,
                lockerName: data.cart.lockerName ? data.cart.lockerName : null,
                note: notes,
              },
              wishlist: data.cart.url,
              priority_tips: priorityTips,
              refund_address: refundaddress,
              total_order: totalOrder,
              statusPanel: "Set",
              notePanel: "",
              email: data.cart.email
            },
            discount: discount.length > 0 ? discount : null,
            referral: referral.length > 0 ? referral : null,
            choosedDiscount: choosedDiscount > 0 ? choosedDiscount : null,
            feePercentage: feePercentage,
            shippingOption: data.cart.shippingOption
          })
          .then((response) => {
            dispatch({ type: "SET_PASSPHRASE", payload: response.data.words });
            dispatch({ type: "SET_CHECKOUTURL", payload: response.data.url });
            navigate("/passphrase");
          })
          .catch((error) => {
            if (error.response.data.code == "promo") {
              setTotalServiceFee(10);
              setDiscount("");
              toast({
                title:
                  "Promo has already been used by the maximum amount of user.",
                autoClose: 1000,
                duration: 1000,
                status: "error",
                hideProgressBar: true,
                closeOnClick: true,
              });
            } else {
              toast({
                title: "Something went wrong. Try again in 2 minutes",
                autoClose: 1000,
                duration: 1000,
                status: "error",
                hideProgressBar: true,
                closeOnClick: true,
              });
            }
          });
      }
    } else {
      toast({
        title: "Please fill all the required fields",
        autoClose: 1000,
        duration: 1000,
        status: "error",
        hideProgressBar: true,
      });
    }
  }

  const handlePriorityTipsChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 0) {
      value = 0;
    }
    setPriorityTips(value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setIsUSTaxApplicable(event.target.value === "United States");
  };

  const applyDiscount = () => {
    if (discount == "") {
      toast({
        title: "Please enter a valid promo code",
        autoClose: 1000,
        duration: 1000,
        status: "error",
        hideProgressBar: true,
      });
      return;
    }

    axios
      .post("/checkDiscounts", {
        data: {
          discount: discount.toUpperCase(),
        },
      })
      .then((response) => {
        if (response.data) {
          setTotalServiceFee(5);

          toast({
            title: discount.toLocaleLowerCase() + " applied (-$5)",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setTotalServiceFee(10);
        setDiscount("");
        toast({
          title: error.response.data.message,
          autoClose: 1000,
          duration: 1000,
          status: "error",
          hideProgressBar: true,
          closeOnClick: true,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("referralCode") !== null) {
      setReferral(localStorage.getItem("referralCode"));
      toast({
        title:
          "Referral code " + localStorage.getItem("referralCode") + " applied",
        description: "",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  const { activeStep } = useSteps({
    initialStep: 1,
    index: 1,
    count: steps.length,
  });

  useEffect(() => {
    calculateTotal();
  }, [priorityTips]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [switch3, setSwitch3] = useState(false);

  const handleOpenConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleContinue = () => {
    if (switch1 && switch2 && switch3) {
      setLoader(true);
      sendOrder();
      handleCloseConfirmModal();
    } else {
      toast({
        title: "Please make sure all switches are turned on.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Center mt={32}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          height: "100vh",
          zIndex: "1000",
        }}
      >
        <Spinner
          size={"xl"}
          colorScheme="orange.500"
          display={loader ? "initial" : "none"}
        ></Spinner>
      </div>{" "}
      <div
        style={{
          filter: loader ? "blur(5px)" : "none",
          pointerEvents: loader ? "none" : "auto",
        }}
      >
        <Box
          w={"90vw"}
          bgColor="rgba(255, 255, 255, 0.8)"
          style={{ backdropFilter: "blur(10px)" }}
          boxShadow="lg"
          borderRadius={10}
          padding={5}
        >
          <Center>
            <Hide below="md">
              <Stepper
                index={activeStep}
                w={"70%"}
                colorScheme="orange"
                size={"md"}
                mb={10}
              >
                {steps.map((step, index) => (
                  <Fragment key={index}>
                    <Step>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>

                      <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                      </Box>
                    </Step>
                    <StepSeparator />
                  </Fragment>
                ))}
              </Stepper>
            </Hide>
          </Center>
          <Center>
            <Flex
              direction={"row"}
              alignItems={"center"}
              gap={10}
              wrap={"wrap"}
              justifyContent={"center"}
            >
              <Box
                bgColor={"#FFF"}
                w={"85vw"}
                h={"60vh"}
                minH={"550px"}
                p={3}
                maxW={"350px"}
                boxShadow="sm"
              >
                <Box w={"100%"} h={"100%"} border={"1px dashed #F87B45"}>
                  <Flex
                    direction={"column"}
                    alignItems={"center"}
                    gap={"20px"}
                    w={"fit-content"}
                    width={"100%"}
                    height={"100%"}
                  >
                    <Heading as="h1" size="md" mt={"20px"}>
                      Informations
                      <br></br>
                      <p className="text-slate-400 text-sm text-center">{data.cart.email}</p>
                    </Heading>
                    <Divider />
                    <Select
                      placeholder="Country"
                      maxW={"90%"}
                      size="sm"
                      name="country"
                      value={country}
                      onChange={(event) => {
                        handleCountryChange(event);
                        calculateTotal();
                      }}
                      required
                    >
                      {countries.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </Select>
                    <Textarea
                      name="notes"
                      maxW={"90%"}
                      placeholder="Order note for the executor (optional)"
                    />
                    <Input
                      placeholder="Tips"
                      type="number"
                      size={"sm"}
                      maxW={"90%"}
                      name="prioritytips"
                      onChange={(event) => {
                        handlePriorityTipsChange(event);
                        calculateTotal();
                      }}
                      required
                    />
                    <Input
                      placeholder="Refund Monero Address"
                      size={"sm"}
                      maxW={"90%"}
                      name="refundaddress"
                      required
                    />
                    <Divider />
                    <Flex
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Flex
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"fit-content"}
                        gap={1}
                      >
                        <Input
                          placeholder="Discount Code"
                          name="discount"
                          value={discount}
                          onChange={(event) => {
                            handlediscountChange(event);
                            calculateTotal();
                          }}
                          size={"sm"}
                          w={"585%"}
                          required
                        />
                        <Button
                          colorScheme="green"
                          onClick={(event) => {
                            applyDiscount();
                            calculateTotal();
                          }}
                          size="xs"
                        >
                          <CheckIcon />
                        </Button>
                      </Flex>
                      <Flex
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        mt={3}
                      >
                        <button
                          style={{
                            color: "grey",
                            fontSize: "11px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={onOpen}
                        >
                          {referral !== null
                            ? "Have a referral code?"
                            : "Referral: " + (referral || "")}
                        </button>
                      </Flex>
                    </Flex>
                    <Divider />
                    <Text>Total: ${totalOrder.toFixed(2)}</Text>
                    <Button
                      size="lg"
                      colorScheme="orange"
                      bgGradient="linear(to-r, orange.500, #fb966d)"
                      mb={1}
                      onClick={handleOpenConfirmModal}
                    >
                      Continue
                    </Button>
                  </Flex>
                </Box>
              </Box>
              <TableContainer bg={"#fff"} borderRadius={7} boxShadow="md" p="6">
                <Table variant="simple">
                  <TableCaption>Prices list of the order</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Order details</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td color={"grey"}>Initial Price</Td>
                      <Td color={"grey"} isNumeric>
                        ${data.cart.totalValue}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Discount Choosed</Td>
                      <Td isNumeric>
                        <Badge colorScheme="orange" fontSize="1em">
                          {data.choosedDiscount
                            ? `-${data.choosedDiscount}%`
                            : "0%"}
                        </Badge>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total ex. Taxes</Td>
                      <Td isNumeric>
                        $
                        {data.cart &&
                          (data.choosedDiscount !== null &&
                            data.choosedDiscount !== undefined
                            ? (
                              data.cart.totalValue *
                              (1 - data.choosedDiscount / 100)
                            ).toFixed(2)
                            : data.cart.totalValue.toFixed(2))}
                      </Td>
                    </Tr>
                    
                    <Tr>
                      <Td>Priority Tips</Td>
                      <Td isNumeric>${priorityTips}</Td>
                    </Tr>
                    <Tr>
                      <Td>Service Fee</Td>
                      <Td isNumeric>
                        ${serviceFee} +{" "}
                        {data.cart.totalValue <= 100
                          ? "5%"
                          : data.cart.totalValue <= 500
                            ? "4%"
                            : data.cart.totalValue <= 1000
                              ? "3.5%"
                              : "3%"}{" "}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Discount Code</Td>
                      <Td isNumeric>{discount}</Td>
                    </Tr>
                    <Tr>
                      <Td>Referral Code</Td>
                      <Td isNumeric>
                        {referral ? referral : "No Referral Code"}
                      </Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Total price</Th>
                      <Th isNumeric>${totalOrder.toFixed(2)}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Flex>
          </Center>
        </Box>
        <Modal
          isOpen={confirmModalOpen}
          onClose={handleCloseConfirmModal}
          ml={20}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Please confirm the following before continuing:</Text>
              <Flex direction="column" mt={7} mb={7} gap={10}>
                <Switch
                  colorScheme="orange"
                  isChecked={switch1}
                  onChange={() => setSwitch1(!switch1)}
                >
                  I correctly configured my wishlist.
                </Switch>
                <Switch
                  colorScheme="orange"
                  isChecked={switch2}
                  onChange={() => setSwitch2(!switch2)}
                >
                  I will confirm the receipt of my order in the chat.
                </Switch>
                <Switch
                  colorScheme="orange"
                  isChecked={switch3}
                  onChange={() => setSwitch3(!switch3)}
                >
                  I will regularly log into my order's chat.
                  <Link href="https://monezon.com/faq" isExternal>
                    <QuestionOutlineIcon
                      as={QuestionOutlineIcon}
                      mx="2"
                      cursor="pointer"
                    />
                  </Link>
                </Switch>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="orange"
                mr={3}
                onClick={handleContinue}
                disabled={!(switch1 && switch2)}
              >
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Referral</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <input
                style={{ border: "1px solid black", borderRadius: "5px" }}
                onChange={(e) => setTempReferral(e.target.value)}
                value={tempReferral}
                maxLength={15}
              />
            </ModalBody>

            <ModalFooter>
              <Button mr={3} variant="ghost" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                colorScheme="orange"
                onClick={() => {
                  handleApplyReferral();
                  if (tempReferral) {
                    toast({
                      title: "Referral code " + tempReferral + " applied",
                      description: "",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                  onClose();
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </Center>
  );
}

export default DetailsBuild;