import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Monezon from "../styles/monezon.svg";

function Nav() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(`/logout`, {}, { withCredentials: true });

      if (response.data.message) {
        navigate("/");
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const buttonStyle =
    "block px-3 py-2 bg-orange-100 text-orange-600 hover:bg-orange-200 p-2 rounded-lg w-full text-md border-orange-300 border";

  return (
<nav className="flex justify-center items-center bg-white shadow-md py-4 fixed top-0 left-0 right-0 z-30 transition-all duration-300 h-16 w-5/6 mx-auto mt-8 rounded-lg">
  <div className="px-4 flex justify-between items-center h-16 gap-33 w-full">
    <div className="flex items-center space-x-8">
      <button className={buttonStyle} onClick={handleLogout}>
        Disconnect
      </button>
    </div>
    <div className="flex items-center justify-center flex-shrink-0">
      <Link to="/">
        <img src={Monezon} className="h-8 w-auto" alt="Monezon logo" />
      </Link>
    </div>
    <div className="flex items-center space-x-8">
      <button className={buttonStyle} onClick={() => navigate("/profile")}>
        Profile
      </button>
    </div>
  </div>
</nav>
  );
}

export default Nav;
