import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logoImage from "../styles/monezon.svg";
import axios from "axios";
function Register() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [registerStatus, setRegisterStatus] = useState("");

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (confirmPassword && e.target.value !== confirmPassword) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (password && e.target.value !== password) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const handleRegister = async () => {
    if (
      password === confirmPassword &&
      password.length >= 3 &&
      username.length >= 3
    ) {
      try {
        const response = await axios.post(
          "/register",
          {
            username: username,
            password: password,
          },
          { withCredentials: true }
        );

        if (response.data.success) {
          navigate("/verify-your-account");
        } else {
          setRegisterStatus(response.data.error);
        }
      } catch (error) {
        setRegisterStatus(error.response.data.error);
      }
    }
  };

  return (
    <div className="center-container" style={{ height: "98vh" }}>
      <Link to="/"><img src={logoImage} alt="Logo" zIndex="10" style={{ position: "relative" }} className="logo-image" /></Link>

      <div className="register-form">
        <label htmlFor="username">username</label>

        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">password</label>

        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <label htmlFor="conf-password">confirm password</label>

        <input
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <p>{passwordMatchError ? "Passwords do not match" : ""}</p>
        <button onClick={handleRegister}>Register</button>
        <Link className="sub-text" to="/login">
          Login instead
        </Link>
        {registerStatus && <p>{registerStatus}</p>}
      </div>
      <Link className="returnHome" to={"/"}>Return to home page</Link>
    </div>
  );
}

export default Register;
