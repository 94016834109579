
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

const NotificationBar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  useEffect(() => {
    setLoading(true); 
    axios
      .get("/totalOrders")
      .then((res) => {
        setMessage(res.data.message + " orders placed 🎉");
      })
      .catch((err) => {
        console.log(err);
        setMessage("Apologies for the inconvenience, our servers are currently undergoing network issues.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={`notification-bar ${isVisible ? "visible" : ""} fixed top-32 w-48 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-orange-100 text-orange-600 p-2 text-center z-40 rounded-md border border-orange-600`}
    >
      {loading ? ( 
        <Spinner color="white" />
      ) : (
        <p className="text-orange-600">{message}</p>
      )}
    </div>
  );
};

export default NotificationBar;
