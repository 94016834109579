import { Link } from "react-router-dom";
import Twitter from "../styles/Twitter.png";
import "../styles/background.css";
import Monero from "../styles/Monero-bg.png";

function Background() {
  return (
    <div className="Background">
        <img srcSet={Monero} alt="" />
      <div className="fond-cercle"></div>
    </div>
  );
}

export default Background;
