import { useState } from "react";
import { useNavigate } from "react-router-dom";
import wishlistGif from "../styles/wishlistConfiguration.gif";
import {
  Checkbox,
  Center,
  Flex,
  Text,
  Heading,
  Button,
} from "@chakra-ui/react";

function WishListWarning() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleButtonClick = () => {
    navigate("/cart");
  };

  return (
    <Center mt={40}>
      <Flex
        w="90vw"
        bgColor="white"
        direction="column"
        alignItems="center"
        gap={5}
        borderRadius={10}
        padding={5}
        boxShadow="lg"
        style={{ marginBottom: "50px" }}
      >
        <Heading
          bgGradient="linear(to-r, orange.300, orange.400, black)"
          bgClip="text"
          textAlign="center"
        >
          Happy to see you here!
        </Heading>
        <Text textAlign="center">Easy Wishlist Setup: Avoid Order Issues</Text>
        <div style={{ height: "fit-content", overflow: "hidden" }}>
          <img
            src={wishlistGif}
            alt="How to properly configure your wishlist"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <Checkbox
          size="md"
          colorScheme="orange"
          isChecked={isChecked}
          onChange={handleCheckboxChange}
        >
          I understand
        </Checkbox>
        <Button
          colorScheme="orange"
          size="md"
          bgGradient="linear(to-r, orange.500, #fb966d)"
          _hover={{ bg: "orange.500" }}
          p={4}
          isDisabled={!isChecked}
          onClick={handleButtonClick}
        >
          Start ordering
        </Button>
      </Flex>
    </Center>
  );
}

export default WishListWarning;
