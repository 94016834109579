import React, { useEffect, useState, useRef, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useData } from "../components/DataContext";
import { ec as EC } from "elliptic";
import hash from "hash.js";
import { enc } from "crypto-js";
import notificationIcon from "../styles/notification.svg";
import executorPDF from "../styles/executor_error.pdf";
import Twitter from "../styles/X.avif";
import send from "../styles/send.png";
import { isMobile } from "react-device-detect";
import readreceipt from "../styles/readReceiptOK.png";
import {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Divider,
  Indicator,
  WrapItem,
} from "@chakra-ui/react";
import {
  StarIcon,
  HamburgerIcon,
  WarningTwoIcon,
  ChevronDownIcon,
  CheckIcon,
  ViewIcon,
  CheckCircleIcon,
  ArrowForwardIcon,
  CopyIcon,
  InfoIcon,
} from "@chakra-ui/icons";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Modal,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Image,
  Stack,
  Link,
  useToast,
  Badge,
  Tooltip,
} from "@chakra-ui/react";

const ec = new EC("secp256k1");

var steps = [
  { title: "Ordered", description: "Nothing yet." },
  { title: "Shipping", description: "Nothing yet." },
  { title: "Delivered", description: "Nothing yet." },
];

function Chat() {
  const { data, dispatch } = useData();

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState(null);

  const [step, setStep] = useState(0);

  const [personalPublicKey, setPersonalPublicKey] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [acceptedTimestamp, setAcceptedTimestamp] = useState(null);

  const [isDeliveredManualModalOpen, setIsDeliveredManualModalOpen] =
    useState(false);
  const onDeliveredManualModalOpen = () => setIsDeliveredManualModalOpen(true);
  const onDeliveredManualModalClose = () =>
    setIsDeliveredManualModalOpen(false);

  const [isDeliveredModalOpen, setIsDeliveredModalOpen] = useState(false);
  const onDeliveredModalOpen = () => setIsDeliveredModalOpen(true);
  const onDeliveredModalClose = () => setIsDeliveredModalOpen(false);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const onContactModalOpen = () => setIsContactModalOpen(true);
  const onContactModalClose = () => setIsContactModalOpen(false);

  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [rating, setRating] = useState(0);

  const [executorRating, setExecutorRating] = useState(data.ratings || 0);
  const [executorXmrAddress, setExecutorXmrAddress] = useState(data.xmrAddress);

  const [orderCount, setOrderCount] = useState(data.orderCount || 0);

  const openRatingModal = () => setIsRatingModalOpen(true);
  const closeRatingModal = () => setIsRatingModalOpen(false);

  const [isBuyerDeniedModalOpen, setIsBuyerDeniedModalOpen] = useState(false);

  const onBuyerDeniedModalOpen = () => setIsBuyerDeniedModalOpen(true);
  const onBuyerDeniedModalClose = () => setIsBuyerDeniedModalOpen(false);

  const [isSendingFundModalOpen, setIsSendingFundModalOpen] = useState(false);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const btnRef = React.useRef();
  const hasFetchedRef = useRef(false);

  const toast = useToast();

  const { roomid } = useParams();
  const [wsUrl, setwsUrl] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.keys && data.keys.public && data.keys.private) {
      setPersonalPublicKey(data.keys.public);
      setPrivateKey(data.keys.private);
      setwsUrl(process.env.REACT_APP_WS_URL);
      setAcceptedTimestamp(formatCreatedAt(data.acceptedTimestamp));
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  function formatCreatedAt(dateString) {
    const dateObject = new Date(dateString);

    const formattedDate = dateObject
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "/");

    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  }

  useEffect(() => {
    
    const fetchMessages = async () => {
      if (hasFetchedRef.current) return;

      try {
        setIsLoading(true);
        const response = await axios.get(`/chat/getMessages/${roomid}`);
        const messages = response.data.message;

        const decryptPromises = messages.map(async (msg) => {
          if (msg.username === "Reason") {
            return msg;
          }

          const userType = data.userType;
          let dupe = false;

          if (userType === "Buyer" && msg.username === "Buyer") {
            dupe = true;
          } else if (userType !== "Buyer" && msg.username === "Executor") {
            dupe = true;
          }

          if (dupe || userType === "Buyer" || userType !== "Buyer") {
            const msge = JSON.parse(atob(dupe ? msg.dupe : msg.message));
            msg.message = await decrypt(
              privateKey,
              msge.iv,
              msge.ephemeralPublicKey,
              msge.ciphertext
            );
          }

          return msg;
        });

        const decryptedMessages = await Promise.all(decryptPromises);

        const formattedMessages = decryptedMessages.map((msg) => ({
          ...msg,
          createdAt: formatCreatedAt(msg.createdAt),
        }));
        console.log(formattedMessages);
        setMessages(formattedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setIsLoading(false);
        hasFetchedRef.current = true;
      }
    };

    fetchMessages();

    const newSocket = new WebSocket(wsUrl);
    let isBuyer = false;

    if (data.userType === "Buyer") {
      isBuyer = true;
    } else if (data.userType !== "Executor") {
      isBuyer = false;
    }

    newSocket.onopen = () => {
      const joinRoomMessage = {
        type: "joinRoom",
        roomId: roomid,
        userType: "client",
      };


      newSocket.send(JSON.stringify(joinRoomMessage));
    };

    newSocket.onmessage = async (event) => {
      
      const msg = JSON.parse(event.data);
      try {
        if (msg.type == "info") {
          return;
        }
        // if msg.message contain 'accepted your order.', then show a toast asking the user to reload
        if (msg.message.includes("accepted your order.")) {
          toast({
            title: "Refresh needed",
            description: 'An executor has accepted your order, you need to refresh the page to communicate with them.',
            status: "success",
            duration: 150000,
            isClosable: true,
          });
        }

        if (msg.message.includes("cancelled")) {
          data.publicKey = null;
        }
        if (data.userType == "Buyer") {
          // Coté buyer
          if (msg.username == "Buyer") {
            // mes messages
            const msge = JSON.parse(atob(msg.dupe));
            const msgd = await decrypt(
              privateKey,
              msge["iv"],
              msge["ephemeralPublicKey"],
              msge["ciphertext"]
            );

            msg.message = msgd;
          } else if (msg.username == "Executor") {
            const msge = JSON.parse(atob(msg.message));
            const msgd = await decrypt(
              privateKey,
              msge["iv"],
              msge["ephemeralPublicKey"],
              msge["ciphertext"]
            );

            msg.message = msgd;
          }
        } else {
          if (msg.username == "Executor") {
            const msge = JSON.parse(atob(msg.dupe));
            const msgd = await decrypt(
              privateKey,
              msge["iv"],
              msge["ephemeralPublicKey"],
              msge["ciphertext"]
            );

            msg.message = msgd;
          } else if (msg.username == "Buyer") {
            const msge = JSON.parse(atob(msg.message));
            const msgd = await decrypt(
              privateKey,
              msge["iv"],
              msge["ephemeralPublicKey"],
              msge["ciphertext"]
            );

            msg.message = msgd;
          }
        }
        setMessages((prevMessages) => [...prevMessages, msg]);
      } catch (error) {
        console.error("Error decrypting message:", error);
      }
    };



    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, [roomid, privateKey, data.userType]);

  useEffect(() => {
    const newSocket = new WebSocket(wsUrl);
    let isBuyer = false;

    if (data.userType === "Buyer") {
      isBuyer = true;
    } else if (data.userType !== "Executor") {
      isBuyer = false;
    }

    newSocket.addEventListener('open', () => {
      const markMessagesAsRead = async () => {
        if (messages.length === 0) return;

        const filteredMessages = messages.filter(message => message.username !== data.userType);
        if (filteredMessages.length === 0) return;

        const ids = filteredMessages.map((message) => message._id);
        const lastMsgId = ids[ids.length - 1];
        const userPart = isBuyer ? "Executor" : "Buyer";
        console.log(lastMsgId)
        try {
          newSocket.send(JSON.stringify({ type: "readReceipt", messageId: lastMsgId }));
        } catch (error) {
          console.error(error);
        }
        /*try {
          for (const id of ids) {
            newSocket.send(JSON.stringify({ type: "readReceipt", messageId: id }));
          }
          console.log(ids);
        } catch (error) {
          console.error(error);
        }*/
      };

      markMessagesAsRead();
    });

    return () => {
      newSocket.close();
    };
  }, [messages, data.userType, wsUrl]);


  async function decrypt(privateKey, iv, ephemeralPublicKey, ciphertext) {
    try {
      const userPrivateKey = ec.keyFromPrivate(privateKey, "hex");
      const senderEphemeralPublicKey = ec.keyFromPublic(
        ephemeralPublicKey,
        "hex"
      );

      // Derive the shared secret
      const sharedSecret = userPrivateKey.derive(
        senderEphemeralPublicKey.getPublic()
      );

      // Convert shared secret to ArrayBuffer
      const sharedSecretBytes = new Uint8Array(sharedSecret.toArray());

      // Convert the iv and ciphertext back to Uint8Array
      const ivArray = new Uint8Array(iv);
      const ciphertextArray = new Uint8Array(ciphertext);

      // Use the shared secret to decrypt the message with AES-GCM
      const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        sharedSecretBytes,
        { name: "AES-GCM" },
        false,
        ["decrypt"]
      );

      const decryptedContent = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: ivArray,
        },
        keyMaterial,
        ciphertextArray
      );
      return new TextDecoder().decode(decryptedContent);
    } catch (error) {
      console.error("Error decrypting message:", error);
      return "Error decrypting message";
    }
  }

  /**
   * Encrypts a message using a recipient's public key.
   *
   * @param {string} plaintext - The message to be encrypted.
   * @param {string} publicKey - The public key of the recipient.
   * @return {object} An object containing the encrypted message.
   *   - {array} iv - The initialization vector used for encryption.
   *   - {string} ephemeralPublicKey - The ephemeral public key used for encryption.
   *   - {array} ciphertext - The encrypted message.
   */
  async function encryptMessage(plaintext, publicKey) {
    // Import the recipient's public key
    const recipientPublicKey = ec.keyFromPublic(publicKey, "hex");

    // Generate an ephemeral key pair
    const ephemeralKeyPair = ec.genKeyPair();
    const ephemeralPublicKey = ephemeralKeyPair.getPublic("hex");

    // Derive a shared secret using ECDH
    const sharedSecret = ephemeralKeyPair.derive(
      recipientPublicKey.getPublic()
    );

    // Convert shared secret to an ArrayBuffer
    const sharedSecretBytes = new Uint8Array(sharedSecret.toArray());

    // Use the shared secret to encrypt the message with AES-GCM
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      sharedSecretBytes,
      { name: "AES-GCM" },
      false,
      ["encrypt"]
    );

    const encryptedContent = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      keyMaterial,
      new TextEncoder().encode(plaintext)
    );

    return {
      iv: Array.from(iv),
      ephemeralPublicKey: ephemeralPublicKey,
      ciphertext: Array.from(new Uint8Array(encryptedContent)),
    };
  }

  async function sendMessage() {
    if (!data.publicKey) {
      toast({
        title: "Error",
        description: "Cannot send a message because no executor accepted your order.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }
    if (message.length > 250) {
      return;
    }
    if (message === "") {
      return;
    }

    if (socket) {
      const encryptedMessage = await encryptMessage(message, data.publicKey); // message for the other
      const encryptedMessageForExecutor = await encryptMessage(
        message,
        personalPublicKey
      ); // message for me

      const base64EncryptedMessage = btoa(JSON.stringify(encryptedMessage));
      const base64EncryptedMessageDupe = btoa(
        JSON.stringify(encryptedMessageForExecutor)
      );

      if (data.userType == "Buyer") {
        const chatMessage = {
          type: "chatMessage",
          roomId: roomid,
          username: "Buyer",
          message: base64EncryptedMessage,
          dupe: base64EncryptedMessageDupe,
        };
        socket.send(JSON.stringify(chatMessage));
        setMessage("");
      } else {
        const chatMessage = {
          type: "chatMessage",
          roomId: roomid,
          username: "Executor",
          message: base64EncryptedMessage,
          dupe: base64EncryptedMessageDupe,
        };

        socket.send(JSON.stringify(chatMessage));
        setMessage("");
      }
    }
  }

  const chatRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMessage, setShowMessage] = useState("");

  async function rateExecutor() {
    await axios
      .post("/orders/rateExecutor", {
        orderId: roomid,
        rating: rating,
      })
      .then((response) => {
        toast({
          title: "Success.",
          description: "You have successfuly rated your executor.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.error,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    switch (data.deliveryValidated) {
      case "accepted":
        if (data.userType == "Buyer") {
          setShowMessage("You have acknowledged the receipt of the order.");
        }
        if (data.userType == "Executor") {
          setShowMessage(
            "The buyer has acknowledged the receipt of the order."
          );
        }
        break;
      case "denied":
        if (data.userType == "Buyer") {
          setShowMessage("You have denied the receipt, please contact us.");
        }
        if (data.userType == "Executor") {
          setIsBuyerDeniedModalOpen(true);
          setShowMessage(
            "The buyer has denied the receipt, please contact us."
          );
        }
        break;
      case "pending":
        if (data.userType == "Buyer") {
          setIsDeliveredModalOpen(true);
        }
        if (data.userType == "Executor") {
          setShowMessage("Waiting on buyer confirmation.");
        }
        break;
    }
  }, []);

  async function handleValidate(state) {
    await axios
      .post(
        "/orders/received",
        {
          id: roomid,
          state: state,
        },
        { withCredentials: true }
      )
      .then((response) => {})
      .catch((error) => {});
  }

  const handleContactUs = () => {
    onContactModalClose();
  };

  const handleAccept = () => {
    handleValidate("accepted");
    setIsDeliveredManualModalOpen(false);
    setIsDeliveredModalOpen(false);
    setShowMessage("You have acknowledged the receipt.");
    setIsRatingModalOpen(true);
  };

  const handleDeny = () => {
    handleValidate("denied");
    setIsDeliveredManualModalOpen(false);
    setIsContactModalOpen(true);
    setShowMessage("You have denied the receipt, please contact us.");
  };

  const handleRating = (rate) => {
    setRating(rate);
  };

  const submitRating = () => {
    closeRatingModal();
    rateExecutor();
    setShowFeedbackModal(true);
  };

  var { setActiveStep, goToNext, activeStep } = useSteps({
    index: step,
    initialStep: 1,
    count: steps.length,
  });

  useEffect(() => {
    axios
      .post(
        "/orders/getTrackingStatus",
        { id: roomid },
        { withCredentials: true }
      )
      .then((response) => {
        switch (response.data.message.trackingStatus) {
          case "null":
            break;
          case "ordered":
            steps = [
              {
                title: "Ordered",
                description: "Set as ordered by the executor.",
              },
              { title: "Shipping", description: "Nothing yet." },
              { title: "Delivered", description: "Nothing yet." },
            ];
            goToNext();
            break;
          case "shipped":
            steps = [
              {
                title: "Ordered",
                description: "Set as ordered by the executor.",
              },
              {
                title: "Shipping",
                description: (
                  <div>
                    Set as shipped by the executor.
                    <br />
                    {response.data.message.trackingUrl !== undefined ? (
                      <a
                        href={response.data.message.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "blue" }}
                      >
                        {response.data.message.trackingUrl}
                      </a>
                    ) : (
                      "No tracking provided"
                    )}
                  </div>
                ),
              },
              { title: "Delivered", description: "Nothing yet." },
            ];
            setActiveStep(2);
            break;
          case "delivered":
            steps = [
              {
                title: "Ordered",
                description: "Set as ordered by the executor.",
              },
              {
                title: "Shipping",
                description: (
                  <div>
                    Set as shipped by the executor.
                    <br />
                    {response.data.message.trackingUrl !== undefined ? (
                      <a
                        href={response.data.message.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "blue" }}
                      >
                        {response.data.message.trackingUrl}
                      </a>
                    ) : (
                      "No tracking provided"
                    )}
                  </div>
                ),
              },
              {
                title: "Delivered",
                description: "Set as delivered by the executor.",
              },
            ];
            setActiveStep(3);
            break;
        }
      });
  }, []);

  return (
    <div className="chat-container mt-40">
      <Modal
        closeOnOverlayClick={false}
        isOpen={isDeliveredManualModalOpen}
        onClose={onDeliveredManualModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delivery Status</ModalHeader>
          <ModalCloseButton disabled />{" "}
          <ModalBody
            justifyContent="center"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <img
              src={notificationIcon}
              alt="Logo"
              style={{ width: "50px", height: "50px", marginBottom: "20px" }}
            />
            <p>You're about to confirm that you have received your order.</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={handleAccept}>
              I received it
            </Button>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={() => {
                setIsDeliveredManualModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isDeliveredModalOpen}
        onClose={onDeliveredModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delivery Status</ModalHeader>
          <ModalCloseButton disabled />{" "}
          <ModalBody
            justifyContent="center"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <img
              src={notificationIcon}
              alt="Logo"
              style={{ width: "50px", height: "50px", marginBottom: "20px" }}
            />
            <p>You're about to confirm that you have received your order.</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={handleAccept}>
              I received it
            </Button>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={() => {
                setIsDeliveredModalOpen(false);
                handleDeny();
              }}
            >
              I didn't receive it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isBuyerDeniedModalOpen}
        onClose={() => setIsBuyerDeniedModalOpen(false)}
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Receipt Denied</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              The buyer has denied the receipt of the order. Please check the
              order details and communicate with the buyer to resolve any
              issues.
              <br></br>
              If no agreement is reached, please contact us on Twitter/Session.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => setIsBuyerDeniedModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSendingFundModalOpen}
        onClose={() => setIsSendingFundModalOpen(false)}
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sending missing funds</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please send the funds to the following address.
              <br />
              <br></br>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <input
                  value={executorXmrAddress}
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #EAEAEA",
                    paddingLeft: "10px",
                  }}
                ></input>

                <Button
                  colorScheme="orange"
                  size={"sm"}
                  onClick={() => {
                    navigator.clipboard.writeText(executorXmrAddress);
                    //toast to validate that the user has coied the xmd address
                    toast({
                      title: "Copied to clipboard!",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                >
                  <CopyIcon />
                </Button>
              </div>
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={() => setIsSendingFundModalOpen(false)}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isRatingModalOpen}
        onClose={closeRatingModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit Your Rating</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              How would you rate your experience with your executor?
            </Text>
            <Stack direction="row" spacing={4} justify="center">
              {[1, 2, 3, 4, 5].map((star) => (
                <Box
                  as="a"
                  key={star}
                  fontSize="2xl"
                  color={star <= rating ? "orange.400" : "gray.300"}
                  onClick={() => handleRating(star)}
                >
                  ★
                </Box>
              ))}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={submitRating}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isContactModalOpen}
        onClose={onContactModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalCloseButton disabled />
          <ModalBody
            justifyContent="center"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              src={notificationIcon}
              alt="Contact Icon"
              boxSize="50px"
              marginBottom="20px"
            />
            <Text>Contact us on Session / Twitter to resolve the issue.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={handleContactUs}>
              Okay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        isCentered
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent
          padding="20px"
          borderRadius="30px"
          boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
          m="auto"
          maxWidth="500px"
        >
          <ModalHeader textAlign="center">Feedback</ModalHeader>
          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="4"
          >
            <Image
              src={notificationIcon}
              alt="Logo"
              boxSize="50px"
              marginBottom="10px"
            />

            <Text textAlign="center">
              We're happy to hear that you received your item(s)!
            </Text>
            <Text textAlign="center">Share your experience on X!</Text>

            <Link href="https://twitter.com/atMonezon" isExternal>
              <Image
                src={Twitter} // Replace with your Twitter logo path
                alt="Twitter"
                boxSize="50px"
                borderRadius="10px"
              />
            </Link>

            <Text>Don't forget to @ us!</Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <div
        className="tracking"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          textAlign: "center",
        }}
      >
        {data.userType == "Buyer" ? (
          <div className="executor-container">
            <div className="executor-profile">
              <WrapItem>
                <Avatar
                  name="Dan Abrahmov"
                  src="https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/i6q39lojbhyf4ppld1df"
                />
              </WrapItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <p style={{ textAlign: "center", marginLeft: "60%" }}>
                  {data.executorUsername}
                </p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {data.executorUsername === "Monezon" && (
                    <Fragment>
                      <Badge colorScheme="orange">Admin</Badge>
                    </Fragment>
                  )}
                  {data.trustLevel === "confirmed" && (
                    <Fragment>
                      <Badge colorScheme="purple">Confirmed</Badge>
                      <Tooltip
                        label="This badge indicates that the executor has successfully processed at least 6 orders."
                        fontSize="xs"
                        hasArrow
                      >
                        <InfoIcon color="#6c7a89" />
                      </Tooltip>{" "}
                    </Fragment>
                  )}
                  {data.trustLevel === "intermediate" && (
                    <Fragment>
                      <Badge colorScheme="yellow">Intermediate</Badge>
                      <Tooltip
                        label="This badge indicates that the executor has successfully processed at least 3 orders."
                        fontSize="xs"
                        hasArrow
                      >
                        <InfoIcon color="#6c7a89" />
                      </Tooltip>{" "}
                    </Fragment>
                  )}
                  {data.trustLevel === "new" && (
                    <Fragment>
                      <Badge colorScheme="green">New</Badge>
                      <Tooltip
                        label="This badge indicates that the executor is new."
                        fontSize="xs"
                        hasArrow
                      >
                        <InfoIcon color="#6c7a89" />
                      </Tooltip>{" "}
                    </Fragment>
                  )}
                </div>
              </div>

              {data.executorUsername === "Monezon" ? null : (
                <>
                  <div className="executor-rating">
                    {[...Array(5)].map((_, index) => {
                      return index < Math.floor(executorRating) ? (
                        <StarIcon key={index} className="checked" />
                      ) : (
                        <StarIcon key={index} className="unchecked" />
                      );
                    })}
                  </div>
                </>
              )}

              {data.executorUsername === "Monezon" ? (
                <>
                  <p></p>
                </>
              ) : (
                <>
                  <p style={{ color: "#6B6B6B", fontSize: "13px" }}>
                    {orderCount} orders
                  </p>
                </>
              )}
            </div>

            {data.executorUsername === "Monezon" ? (
              <></>
            ) : (
              <>
                <div
                  className="executor-buttons"
                  style={{
                    marginLeft: isMobile ? "0" : "130px",
                    marginTop: isMobile ? "60px" : "0",
                  }}
                >
                  {isMobile ? (
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        size="sm"
                        color="white"
                        backgroundColor="#F87B45"
                        _active={{ backgroundColor: "#d65a2b" }}
                        _focus={{ backgroundColor: "#F87B45" }}
                        _hover={{ backgroundColor: "#F87B45" }}
                      >
                        Actions
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          icon={<WarningTwoIcon />}
                          onClick={() => {
                            setIsContactModalOpen(true);
                          }}
                          sx={{
                            "&:hover": {
                              bg: "transparent !important",
                            },
                          }}
                        >
                          Report Executor
                        </MenuItem>
                        <MenuItem
                          icon={<ArrowForwardIcon />}
                          onClick={() => {
                            setIsSendingFundModalOpen(true);
                          }}
                          sx={{
                            "&:hover": {
                              bg: "transparent !important",
                            },
                          }}
                        >
                          Send additional funds
                        </MenuItem>
                        <MenuItem
                          icon={<CheckIcon />}
                          onClick={onDeliveredManualModalOpen}
                        >
                          I Received My Order
                        </MenuItem>
                        <MenuItem icon={<ViewIcon />} onClick={onDrawerOpen}>
                          Order status
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Fragment>
                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<ChevronDownIcon />}
                          size={"sm"}
                          color="white"
                          backgroundColor="#F87B45"
                          _active={{ backgroundColor: "#d65a2b" }}
                          _focus={{ backgroundColor: "#F87B45" }}
                          _hover={{ backgroundColor: "#F87B45" }}
                        >
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={<WarningTwoIcon />}
                            command=""
                            sx={{
                              "&:hover": {
                                bg: "transparent !important",
                              },
                            }}
                            onClick={() => {
                              setIsContactModalOpen(true);
                            }}
                          >
                            Report Executor
                          </MenuItem>
                          <MenuItem
                            icon={<ArrowForwardIcon />}
                            onClick={() => {
                              setIsSendingFundModalOpen(true);
                            }}
                            sx={{
                              "&:hover": {
                                bg: "transparent !important",
                              },
                            }}
                          >
                            Send additional funds
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      <Button
                        size={"sm"}
                        onClick={onDeliveredManualModalOpen}
                        style={{
                          backgroundColor: "#F87B45",
                          color: "white",
                          fontSize: "15px",
                          borderRadius: "5px",
                          padding: "0px 5px",
                        }}
                      >
                        I Received My Order
                      </Button>
                      <Button
                        size={"sm"}
                        ref={btnRef}
                        onClick={onDrawerOpen}
                        style={{
                          backgroundColor: "#F87B45",
                          color: "white",
                          fontSize: "15px",
                          borderRadius: "5px",
                          padding: "0px 5px",
                        }}
                      >
                        Order status
                      </Button>
                    </Fragment>
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={onDrawerClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Order Status</DrawerHeader>

            <DrawerBody>
              <Stepper
                index={activeStep}
                orientation="vertical"
                height="400px"
                gap="0"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>

              <p
                style={{
                  textAlign: "center",
                  color: "#6B6B6B",
                  marginTop: "10px",
                }}
              >
                {showMessage}
              </p>
            </DrawerBody>

            <DrawerFooter>
              <Button
                variant="outline"
                colorScheme="red"
                mr={3}
                onClick={onDrawerClose}
              >
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        {data.userType == "Executor" ? (
          <a
            href={executorPDF}
            target="_blank"
            className="text-orange-500 hover:text-orange-600 bg-orange-100 p-1 rounded-lg border border-orange-300"
          >
            Cannot deliver an item to the selected location? Click here to learn
            about the work around
          </a>
        ) : null}
      </div>
      {isLoading && (
        <div className="loader" style={{ width: "50px", height: "50px" }}></div>
      )}

      <div className="chat" ref={chatRef}>
        <div
          style={{ textAlign: "center", color: "#6B6B6B", fontSize: "16px" }}
        >
          <h3>{acceptedTimestamp}</h3>
          <h2>Here starts your conversation</h2>
        </div>
        {messages.map((msg, index) => (
          <div
            className={`message ${
              data.userType === msg.username ? "rightMessage" : "left"
            }`}
            key={index}
          >
            {console.log(msg.username)}
            {msg.username === "Reason" ? (
              <>
                <p style={{ color: "black", fontSize: "15px" }}>
                  {msg.message.split("\\n").map((line, index) => (
                    <Fragment>
                      <span key={index}>{line}</span>
                      <br></br>
                    </Fragment>
                  ))}
                </p>
                <p style={{ color: "#B9B9B9", fontSize: "15px" }}>
                  {formatCreatedAt(msg.createdAt)}
                </p>
                <Divider></Divider>
              </>
            ) : (
              <>
                <p style={{ color: "#B9B9B9", fontSize: "15px" }}>
                  {msg.username}
                </p>
                <div className="speech-bubble">
                  <p>
                    {typeof msg.message === "object"
                      ? "Empty message"
                      : msg.message}
                  </p>
                </div>
                <p className="flex row gap-2" style={{ color: "#B9B9B9", fontSize: "15px" }}>
                  {msg.username === data.userType && msg.read === true && index === messages.length - 1 && (
                    <img className="h-6" src={readreceipt} draggable="false" />
                  )}
                  {formatCreatedAt(msg.createdAt)}
                </p>
              </>
            )}
          </div>
        ))}

        <p>{showMessage}</p>
      </div>
      <div className="chat-input" onKeyDown={handleKeyPress}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          max={250}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage} disabled={message.length === 0}>
          <img style={{ width: "28px", height: "28px" }} src={send}></img>
        </button>
      </div>
    </div>
  );
}

export default Chat;
