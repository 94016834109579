import NumberTicker from "./magicui/number-ticker";

const NumberTickerDemo = () => {
  return (
    <span>
      <NumberTicker value={300} />
    </span>
  );
};

export default NumberTickerDemo;
