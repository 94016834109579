import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logoImage from "../styles/monezon.svg";
import { Bounce, ToastContainer, toast } from "react-toastify";

import { Spinner } from '@chakra-ui/react';

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/login/",
        {
          username: username,
          password: password,
        },
        { withCredentials: true }
      );
      setLoading(false);
      if (response.data.success) {
        toast.success('Logged in!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
        navigate("/panel");
        
      } 
    } catch (error) {
      toast.error('Could not login right now. \nCheck your credentials or try again later.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      console.error("An error occurred while requesting data: ", error);

    }
  };

  useEffect(() => {
    try {
      axios.get("/auth", { withCredentials: true }).then((response) => {
        if (response.data.message) {
          navigate("/panel");
        }
        
      });
    } catch (error) {}
  }, []);

  const userNotAccepted = () => toast("Your account is not approved yet.");

  return (
    <div className="center-container"  style={{ height: "98vh" }}>
      <Link to="/"><img src={logoImage} alt="Logo" zIndex="10" style={{ position: "relative" }} className="logo-image" /></Link>
      <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
      />
      <form
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <label htmlFor="username">username</label>

        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">password</label>
        
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          
        />
        <button>{loading ? <Spinner /> : "Login"}</button>
        <Link className="sub-text" to="/register">
          Signup instead
        </Link>
      </form>
      <Link className="returnHome" to={"/"}>Return to home page</Link>
    </div>
  );
}

export default Login;
