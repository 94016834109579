import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import profileImg from "../styles/profile.svg";
import { Badge, Tooltip, Spinner, useToast } from "@chakra-ui/react";
import { StarIcon, InfoIcon } from "@chakra-ui/icons";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Profile() {
  const [username, setUsername] = useState("");
  const [xmrAddress, setXmrAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [orderCount, setOrderCount] = useState(0);
  const [trustLevel, setTrustLevel] = useState("");
  const [ratings, setRatings] = useState(null);
  const [totalPayout, setTotalPayout] = useState(0);

  const toast = useToast();

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  useEffect(() => {
    axios
      .get("/profile", { withCredentials: true })
      .then((response) => {
        setXmrAddress(response.data.message[0]["address"]);
        setEmailAddress(response.data.message[0]["email"]);
        setUsername(response.data.message[0]["username"]);
        setOrderCount(response.data.message[0]["orderCount"]);
        setTrustLevel(response.data.message[0]["trustLevel"]);
        setRatings(response.data.message[0]["ratings"]);
        if (response.data.message[0]["totalPayout"] == null) {
          setTotalPayout(0);
        } else {
          setTotalPayout(response.data.message[0]["totalPayout"].toFixed(4));
        }
        console.log(response.data.message[0]["ratings"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const saveToDB = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailAddress)) {
        toast({
          title: "Error",
          description: "Please enter a valid email address",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    const payload = {
      xmrAddress: xmrAddress,
      emailAddress: emailAddress,
    };

    axios
      .post("/profile/update/", payload, {
        withCredentials: true,
      })
      .then((response) => {
        toast({
          title: "Success",
          description: "Profile updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error saving to the db:", error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen p-8 mt-28">
      <ToastContainer />

      <div className="flex flex-col smE:flex-row w-full max-w-4xl gap-8">
        <div className="w-full md:w-1/2 bg-white bg-opacity-50 shadow-xl backdrop-blur-md rounded-2xl p-4">
          <div className="flex flex-row items-center mb-4 gap-4">
            <img src={profileImg} alt="Profile" className="w-12 h-12 ml-2" />
            <h1 className="mt-2 text-xl font-semibold">{username}</h1>
          </div>
          <div className="mb-4">
            <p className="mb-2 text-sm font-semibold mt-8">XMR Address</p>
            <input
              type="text"
              value={xmrAddress}
              onChange={(e) => setXmrAddress(e.target.value)}
              placeholder="46eLvF7bxaBbU9..."
              className="w-full p-1 border rounded-lg text-sm pl-2"
            />
            <p className="mb-2 text-sm font-semibold mt-8">Email</p>
            <input
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeholder="executor@proton.me"
              className="w-full p-1 border rounded-lg text-sm pl-2"
            />
            <button
              onClick={saveToDB}
              className="mt-8 px-4 w-1/4 py-2 bg-orange-500 text-white rounded-lg text-sm [background-image:linear-gradient(93dev,_#F87B45,_#F87B4500)]"
            >
              Save
            </button>
          </div>
        </div>

        <div className="w-full md:w-1/2 text-md">
          <div className="mb-2 bg-white bg-opacity-50 backdrop-blur-md shadow-xl rounded-2xl p-2 pl-3">
            You have processed{" "}
            <Badge colorScheme="green" fontSize={"sm"}>
              {orderCount}
            </Badge>{" "}
            orders
          </div>
          <div className="mb-2 bg-white bg-opacity-50 backdrop-blur-md shadow-xl rounded-2xl p-2 pl-3">
            Your current trust level is{" "}
            {trustLevel === "new" && (
              <Fragment>
                <Badge colorScheme="green" fontSize={"sm"}>
                  New
                </Badge>
              </Fragment>
            )}
            {trustLevel === "intermediate" && (
              <Fragment>
                <Badge colorScheme="yellow" fontSize={"sm"}>
                  Intermediate
                </Badge>
              </Fragment>
            )}
            {trustLevel === "confirmed" && (
              <Fragment>
                <Badge colorScheme="purple" fontSize={"sm"}>
                  Confirmed
                </Badge>
              </Fragment>
            )}
          </div>
          <div className="mb-2 bg-white bg-opacity-50 backdrop-blur-md shadow-xl rounded-2xl p-2 pl-3 flex flex-row items-center">
            You are rated
            <div className="flex flex-row items-center ml-2 mr-2 gap-1">
              {ratings ? (
                [...Array(5)].map((_, index) => {
                  return index <
                    Math.floor(
                      ratings.reduce((a, b) => a + b, 0) / ratings.length
                    ) ? (
                    <StarIcon key={index} className="checked" />
                  ) : (
                    <StarIcon key={index} className="unchecked" />
                  );
                })
              ) : (
                <Spinner size={"sm"} />
              )}
            </div>
            by the buyers
          </div>
          <div className="mb-2 bg-white bg-opacity-50 backdrop-blur-md shadow-xl rounded-2xl p-2 pl-3">
            You have been paid a total of{" "}
            <Badge colorScheme="green" fontSize={"sm"}>
              {totalPayout.toString()} XMR
            </Badge>
            <Tooltip
              label="May not show the amount since you started processing orders."
              fontSize="xs"
              hasArrow
              placement="top"
            >
              <InfoIcon color="#6c7a89" className="ml-2" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;