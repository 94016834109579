import React, { useState } from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  Text,
  Button,
  Collapse,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import { toast } from "react-toastify";

function AdminSearchBar() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const toast = useToast();


  const handleSearch = async (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value.length > 1) {
      // You can adjust the minimum length as needed
      try {
        const response = await axios.get(
          `admin/search?q=${encodeURIComponent(value)}`,
          { withCredentials: true }
        );
        setResults(response.data);
        setSelectedUser(null); // Reset selected user on new search
      } catch (error) {
        console.error("Error fetching search results:", error);
        setResults([]);
      }
    } else {
      setResults([]);
      setSelectedUser(null);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  async function acceptUser(user) {
    try {
      const response = await axios.post(
        `/admin/executor/accept`,
        { username: user },
        { withCredentials: true }
      );
      toast({
        title: "User approved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.error("Error accepting user:", error);
    }
  }

  return (
    <Box>
      <Input
        placeholder="Search username..."
        value={query}
        onChange={handleSearch}
        mb={4}
      />
      <List spacing={2}>
        {results.map((user) => (
          <ListItem
            key={user._id}
            paddingX="4"
            paddingY="2"
            bg="gray.100"
            borderRadius="md"
            cursor="pointer"
            onClick={() => handleSelectUser(user)}
          >
            {user.username}
          </ListItem>
        ))}
      </List>
      {selectedUser && (
        <Collapse in={!!selectedUser} animateOpacity>
          <Box p={4} mt={4} bg="blue.100" borderRadius="md">
            <Text>Order Count: {selectedUser.orderCount}</Text>
            <Text>XMR Address: {selectedUser.xmrAddress}</Text>
            <Text>
              Average Rating:{" "}
              {selectedUser.ratings && selectedUser.ratings.length > 0
                ? (
                    selectedUser.ratings.reduce((a, b) => a + b, 0) /
                    selectedUser.ratings.length
                  ).toFixed(1)
                : "No ratings"}
            </Text>
            <Text>On Orders: {selectedUser.onOrders.join(", ")}</Text>
            {!selectedUser.accepted && (
              <Button
                colorScheme="green"
                mt={2}
                onClick={() => acceptUser(selectedUser.username)}
              >
                Approve
              </Button>
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}

export default AdminSearchBar;
