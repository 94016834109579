import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Nav() {
    const [inputs, setInputs] = useState(Array(8).fill(''));
    const [step, setStep] = useState(0);

    const [pending, setPending] = useState('');
    const [ordered, setOrdered] = useState(false)
    const [shipped, setShipped] = useState(false)
    const [delivered, setDelivered] = useState(false)

    const [orderingDate, setOrderingDate] = useState("ㅤ")
    const [shippingDate, setShippingDate] = useState("ㅤ")
    const [deliveredDate, setDeliveredDate] = useState("ㅤ")

    useEffect(() => {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      }, []);

    const handleChange = (index, value) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const handlePaste = (e, index) => {
        e.preventDefault();

        const data = e.clipboardData.getData('text');

        const words = data.split(' ');

        let newInputs = [...inputs];

        for (let i = index; i < newInputs.length && i - index < words.length; i++) {
            newInputs[i] = words[i - index];
        }

        // Update the state with the new inputs
        setInputs(newInputs);
    }

    const handleShowContainer = () => {
        const filledInputs = inputs.filter((input) => input.trim() !== '');
        if (filledInputs.length == 8) {
            getOrderFromPass(filledInputs)
            setStep(1)
        }

    };

async function getOrderFromPass(passphrase) {
  const postData = { "passphrase": passphrase };
  try {
    const response = await axios.post(
      '/orders/tracking', postData);
    const message = response.data.message;
    if (message[0].timestamp == null) {
      setPending("Order pending, should be processed soon.");
      setOrdered(false);
    } else {
      setOrdered(true);
      setOrderingDate(new Date(message[1].timestamp).toLocaleString());
    }
    if (message[1].timestamp !== null) {
      setShipped(true);
      setShippingDate(new Date(message[1].timestamp).toLocaleString());
    }
    if (message[2].timestamp !== null) {
      setDelivered(true);
      setDeliveredDate(new Date(message[2].timestamp).toLocaleString());
    }
  } catch (error) {
    console.error('Error could not get order:', error);
  }
}

    return (
        <div className="container" style={{ marginTop: '150px', textAlign: 'center', display: 'flex', flexDirection: "column", alignItems: "center" }}>
            <div className={step === 0 ? 'tracking-words' : 'tracking-words hidden'} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <p>Enter tracking words</p>
                <div className='input-box' style={{ display: 'flex', flexWrap: 'wrap', width: "50%" }}>
                    {inputs.map((input, index) => (
                        <div style={{ flex: "0 0 25%", marginBottom: '10px' }}>
                            <span>{index + 1}. </span>
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onPaste={(e) => handlePaste(e, index)}
                                style={{ width: '50%', padding: '5px' }}
                            />
                        </div>
                    ))}
                </div>
                <button onClick={() => { handleShowContainer() }}>Confirm</button>
            </div>
            <div className={step === 1 ? 'tracking' : 'tracking hidden'} >
                <h3>{pending}</h3>
                <div className="hh-grayBox pt45" id='tracker'>
                    <div className="row justify-content-between">
                        <div className={ordered === true ? 'order-tracking completed' : 'order-tracking'}>
                            <span className="is-complete"></span>
                            <p>
                                Ordered
                                <br />
                                <span>{orderingDate}</span>
                            </p>
                        </div>
                        <div className={shipped === true ? 'order-tracking completed' : 'order-tracking'}>
                            <span className="is-complete"></span>
                            <p>
                                Shipped
                                <br />
                                <span>{shippingDate}</span>
                            </p>
                        </div>
                        <div className={delivered === true ? 'order-tracking completed' : 'order-tracking'}>
                            <span className="is-complete"></span>
                            <p>
                                Delivered
                                <br />
                                <span>{deliveredDate}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Nav;
