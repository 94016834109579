import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import axios from "axios";
import OrderIdExemple from "../../styles/orderIdExemple.png";
import chatIcon from "../../styles/chat.svg";
import noOrder from "../../styles/no-order.png";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useBreakpointValue,
  Box,
  Flex,
  VStack,
  HStack,
  Divider,
  Spacer,
  Tr,
  Td,
  Tbody,
  Th,
  Thead,
  Table,
  TableCaption,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Input,
  Text,
  useToast,
  Badge,
} from "@chakra-ui/react";

import {
  HamburgerIcon,
  CheckIcon,
  CloseIcon,
  InfoIcon,
  TimeIcon,
  ChevronDownIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

const AdminManageOrders = ({ orders }) => {
  const toast = useToast();

  const [localOrders, setLocalOrders] = useState([]);

  useEffect(() => {
    setLocalOrders(orders);
  }, [orders]);

  const [modalStates, setModalStates] = useState({});
  const [inputValues, setInputValues] = useState({}); // Ajout de cet état
  const [loadingStates, setLoadingStates] = useState({});
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [showOrderIdExample, setShowOrderIdExample] = useState(false);
  const [trackingURL, setTrackingURL] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedOrderIdForEdit, setSelectedOrderIdForEdit] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [shouldFocusInput, setShouldFocusInput] = useState(false);
  const [isModalBlocked, setIsModalBlocked] = useState(false);
  const [itemDetailsModalOpen, setItemDetailsModalOpen] = useState({});
  const [hasTrackingLink, setHasTrackingLink] = useState(false);

  const [showPayModal, setShowPayModal] = useState(false);

  const {
    isOpen: isDetailsDrawerOpen,
    onOpen: onDetailsDrawerOpen,
    onClose: onDetailsDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOrderModalOpen,
    onOpen: onDeleteOrderModalOpen,
    onClose: onDeleteOrderModalClose,
  } = useDisclosure();

  const inputRefs = useRef({});

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const closeOrderIdExample = () => {
    setShowOrderIdExample(false);
  };

  async function setCurrentOrder(id) {
    const currentOrder = localOrders.find((order) => order.id === id);
    setSelectedOrder(currentOrder);
  }

  useEffect(() => {
    if (shouldFocusInput && selectedOrderIdForEdit !== null) {
      const focusedInput = inputRefs.current[selectedOrderIdForEdit];
      if (focusedInput) {
        focusedInput.focus();
      }
      setShouldFocusInput(false);
    }
  }, [shouldFocusInput, selectedOrderIdForEdit]);

  async function deleteOrder(id) {
    await axios
      .post(`/admin/orders/delete/`, { id }, { withCredentials: true })
      .then((res) => {
        toast({
          title: "Order deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLocalOrders(localOrders.filter((order) => order.id !== id));
      })
      .catch((error) => {
        toast({
          title: error.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }

  return (
    <div className="manageOrders" style={{ background: "white" }}>
      <Table>
        <TableCaption>Processing Orders</TableCaption>
        <Thead>
          <Tr>
            <Th>ID</Th>

            <Th>Amount $</Th>
            <Th>Amount XMR</Th>
            <Th>View</Th>
            <Th>Pay</Th>
            <Th>Delete</Th>
          </Tr>
        </Thead>
        <Tbody>
          {localOrders.map((pendingOrder) => (
            <Tr
              key={pendingOrder.id}
              bg={
                pendingOrder.executor === "Monezon"
                  ? "orange.200"
                  : pendingOrder.deliveryValidated === "accepted"
                  ? "green"
                  : ""
              }
            >
              <Td>{pendingOrder.id}</Td>
              <Td>${pendingOrder.amount}
</Td>
              <Td>
                {pendingOrder.totalXMR || <Badge colorScheme="red">None</Badge>}
              </Td>
              <Td>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    View
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        onDetailsDrawerOpen();
                        setCurrentOrder(pendingOrder.id);
                        onDetailsDrawerOpen();
                      }}
                    >
                      Order Details
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        window.open(pendingOrder.wishlist, "_blank")
                      }
                    >
                      Go to Wishlist
                    </MenuItem>
                    <MenuItem onClick={() => {}}>Copy Passphrase</MenuItem>
                  </MenuList>
                </Menu>
              </Td>
              <Td>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    setCurrentOrder(pendingOrder.id);
                    setShowPayModal(true);
                  }}
                >
                  Pay
                </Button>
              </Td>
              <Td>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setSelectedOrder(pendingOrder.id);
                    onDeleteOrderModalOpen();
                  }}
                >
                  Delete
                </Button>
              </Td>

            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isDeleteOrderModalOpen} onClose={onDeleteOrderModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this order?</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                deleteOrder(selectedOrder);
                onDeleteOrderModalClose();
              }}
            >
              Delete
            </Button>
            <Button onClick={onDeleteOrderModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={showPayModal} onClose={() => setShowPayModal(false)} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              XMR Amount:  <p>{selectedOrder.totalXMR}</p>
            </Text>
            <Text>
              Address: <p>{selectedOrder.xmrAddress}</p>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isDetailsDrawerOpen}
        placement="right"
        onClose={onDetailsDrawerClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Order Details #{String(selectedOrder.id)}
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Flex justifyContent="space-evenly" alignItems="center">
                <Box textAlign="center">
                  <Text fontSize="lg" fontWeight="bold">
                    You pay
                  </Text>
                  <Text fontSize="md">$ {selectedOrder.amount}</Text>
                </Box>

                <Box textAlign="center">
                  <Text fontSize="lg" fontWeight="bold">
                    You get
                  </Text>
                  <Text fontSize="md">
                    ${" "}
                    {selectedOrder.choosedDiscount
                              ? (
                                (selectedOrder.totalXMR * selectedOrder.cryptoRate) *
                                  1 - selectedOrder.choosedDiscount / 100)
                                
                              : selectedOrder.totalXMR * selectedOrder.cryptoRate
                            }
                  </Text>
                </Box>

                <Box textAlign="center">
                  <Text fontSize="lg" fontWeight="bold">
                    Which equals to
                  </Text>
                  <Text fontSize="md">
                    {selectedOrder.totalXMR
                      ? selectedOrder.totalXMR.toFixed(5) + " XMR"
                      : ""}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-evenly" alignItems="center">
                <Box textAlign="center">
                  {selectedOrder.choosedDiscount ? (
                    <Text fontSize="lg" fontWeight="bold">
                      Buyer paid
                      <Badge colorScheme="orange" ml={2} mr={2}>
                        {selectedOrder.choosedDiscount}%
                      </Badge>
                      less
                    </Text>
                  ) : (
                    ""
                  )}
                </Box>
              </Flex>

              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Wishlist
                </Text>
                <Button
                  onClick={() => window.open(selectedOrder.wishlist, "_blank")}
                  color="white.500"
                  fontWeight="medium"
                >
                  Open Wishlist
                </Button>
              </Box>

              <Divider />

              <HStack justifyContent="space-between">
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    Date
                  </Text>
                  <Text fontSize="md">{selectedOrder.orderDate}</Text>
                </Box>
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    Country
                  </Text>
                  <Text fontSize="md">{selectedOrder.country}</Text>
                </Box>
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    Executor
                  </Text>
                  <Text fontSize="md">{selectedOrder.executor}</Text>
                </Box>
              </HStack>

              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Note
                </Text>
                <Text
                  fontSize="md"
                  bg="gray.100"
                  p={2}
                  borderRadius="md"
                  overflowY={"scroll"}
                  height={"250px"}
                >
                  {selectedOrder.note}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onDetailsDrawerClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AdminManageOrders;
