import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [wsUrl, setwsUrl] = useState(process.env.REACT_APP_WS_URL);
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("/admin/login", {
        username,
        password,
      }, { withCredentials: true });

      if (response.data.success) {
        setIsLoggedIn(true);
        toast.success("Login successful");
        navigate("/admin/dashboard");
      } else {
        toast.error(response.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed due to server error");
    }
  };

  // Login form
  return (
    <div className="login-container" style={{ height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <form onSubmit={handleSubmit} className="login-form" style={{ width: "573px", height: "330px", maxWidth: "573px", gap: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Input
          placeholder="Username"
          value={username}
          onChange={handleUsernameChange}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button type="submit" colorScheme="orange">
          Login
        </Button>
      </form>
    </div>
  );
};

export default AdminLogin;
