import React, { useEffect, useState } from "react";
import Package from "../styles/Package.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ManageOrders from "../components/manageOrders.js";
import ReactModal from "react-modal";
import Joyride from "react-joyride";
import LivraisonIcon from "../styles/Livraison.svg";
import noNewOrder from "../styles/no-new-order.png";
import { CheckIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Button,
  Text,
  VStack,
  useToast,
  Box,
} from "@chakra-ui/react";
import { Badge } from "@chakra-ui/react";

import moneroLogo from "../styles/cryptos/monero.png";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react'
import { Stack, HStack } from '@chakra-ui/react'
var countriesFlags = {
  Afghanistan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/af.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/af.png",
  },
  Albania: {
    mini: "http://flags.fmcdn.net/data/flags/mini/al.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/al.png",
  },
  Algeria: {
    mini: "http://flags.fmcdn.net/data/flags/mini/dz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/dz.png",
  },
  Andorra: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ad.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ad.png",
  },
  Angola: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ao.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ao.png",
  },
  Antigua: {
    alias: "Antigua and Barbuda",
    mini: "http://flags.fmcdn.net/data/flags/mini/ag.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ag.png",
  },
  Argentina: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ar.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ar.png",
  },
  Armenia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/am.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/am.png",
  },
  Australia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/au.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/au.png",
  },
  Austria: {
    mini: "http://flags.fmcdn.net/data/flags/mini/at.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/at.png",
  },
  Azerbaijan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/az.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/az.png",
  },
  Bahamas: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bs.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bs.png",
  },
  Bahrain: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bh.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bh.png",
  },
  Bangladesh: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bd.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bd.png",
  },
  Barbados: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bb.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bb.png",
  },
  Belarus: {
    mini: "http://flags.fmcdn.net/data/flags/mini/by.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/by.png",
  },
  Belgium: {
    mini: "http://flags.fmcdn.net/data/flags/mini/be.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/be.png",
  },
  Belize: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bz.png",
  },
  Benin: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bj.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bj.png",
  },
  Bhutan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bt.png",
  },
  Bolivia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bo.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bo.png",
  },
  Bosnia: {
    alias: "Bosnia and Herzegovina",
    mini: "http://flags.fmcdn.net/data/flags/mini/ba.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ba.png",
  },
  Botswana: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bw.png",
  },
  Brazil: {
    mini: "http://flags.fmcdn.net/data/flags/mini/br.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/br.png",
  },
  Brunei: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bn.png",
  },
  Bulgaria: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bg.png",
  },
  Burkina: {
    alias: "Burkina Faso",
    mini: "http://flags.fmcdn.net/data/flags/mini/bf.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bf.png",
  },
  Burundi: {
    mini: "http://flags.fmcdn.net/data/flags/mini/bi.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/bi.png",
  },
  Cambodia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/kh.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kh.png",
  },
  Cameroon: {
    mini: "http://flags.fmcdn.net/data/flags/mini/cm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cm.png",
  },
  Canada: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ca.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ca.png",
  },
  Verde: {
    alias: "Cape Verde",
    mini: "http://flags.fmcdn.net/data/flags/mini/cv.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cv.png",
  },
  CAR: {
    alias: "Central African Republic",
    mini: "http://flags.fmcdn.net/data/flags/mini/cf.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cf.png",
  },
  Chad: {
    mini: "http://flags.fmcdn.net/data/flags/mini/td.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/td.png",
  },
  Chile: {
    mini: "http://flags.fmcdn.net/data/flags/mini/cl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cl.png",
  },
  Colombia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/co.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/co.png",
  },
  Comoros: {
    mini: "http://flags.fmcdn.net/data/flags/mini/km.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/km.png",
  },
  Cook: {
    alias: "Cook Islands",
    mini: "http://flags.fmcdn.net/data/flags/mini/ck.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ck.png",
  },
  CostaRica: {
    alias: "Costa Rica",
    mini: "http://flags.fmcdn.net/data/flags/mini/cr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cr.png",
  },
  Ivoire: {
    alias: "Cote d'Ivoire",
    mini: "http://flags.fmcdn.net/data/flags/mini/ci.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ci.png",
  },
  Croatia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/hr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/hr.png",
  },
  Cuba: {
    mini: "http://flags.fmcdn.net/data/flags/mini/cu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cu.png",
  },
  Cyprus: {
    mini: "http://flags.fmcdn.net/data/flags/mini/cy.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cy.png",
  },
  Czech: {
    alias: "Czech Republic",
    mini: "http://flags.fmcdn.net/data/flags/mini/cz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cz.png",
  },
  DRC: {
    alias: "Democratic Republic of the Congo",
    mini: "http://flags.fmcdn.net/data/flags/mini/cd.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cd.png",
  },
  Denmark: {
    mini: "http://flags.fmcdn.net/data/flags/mini/dk.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/dk.png",
  },
  Djibouti: {
    mini: "http://flags.fmcdn.net/data/flags/mini/dj.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/dj.png",
  },
  Dominica: {
    mini: "http://flags.fmcdn.net/data/flags/mini/dm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/dm.png",
  },
  DR: {
    alias: "Dominican Republic",
    mini: "http://flags.fmcdn.net/data/flags/mini/do.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/do.png",
  },
  Timor: {
    alias: "East Timor",
    mini: "http://flags.fmcdn.net/data/flags/mini/tl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tl.png",
  },
  Ecuador: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ec.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ec.png",
  },
  Egypt: {
    mini: "http://flags.fmcdn.net/data/flags/mini/eg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/eg.png",
  },
  Salvador: {
    alias: "El Salvador",
    mini: "http://flags.fmcdn.net/data/flags/mini/sv.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sv.png",
  },
  EGuinea: {
    alias: "Equatorial Guinea",
    mini: "http://flags.fmcdn.net/data/flags/mini/gq.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gq.png",
  },
  Eritrea: {
    mini: "http://flags.fmcdn.net/data/flags/mini/er.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/er.png",
  },
  Estonia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ee.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ee.png",
  },
  Ethiopia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/et.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/et.png",
  },
  Fiji: {
    mini: "http://flags.fmcdn.net/data/flags/mini/fj.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/fj.png",
  },
  Finland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/fi.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/fi.png",
  },
  France: {
    mini: "http://flags.fmcdn.net/data/flags/mini/fr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/fr.png",
  },
  Gabon: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ga.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ga.png",
  },
  Gambia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gm.png",
  },
  Georgia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ge.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ge.png",
  },
  Germany: {
    mini: "http://flags.fmcdn.net/data/flags/mini/de.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/de.png",
  },
  Ghana: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gh.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gh.png",
  },
  Greece: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gr.png",
  },
  Grenada: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gd.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gd.png",
  },
  Guatemala: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gt.png",
  },
  Guinea: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gn.png",
  },
  GuineaB: {
    alias: "Guinea-Bissau",
    mini: "http://flags.fmcdn.net/data/flags/mini/gw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gw.png",
  },
  Guyana: {
    mini: "http://flags.fmcdn.net/data/flags/mini/gy.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gy.png",
  },
  Haiti: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ht.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ht.png",
  },
  Honduras: {
    mini: "http://flags.fmcdn.net/data/flags/mini/hn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/hn.png",
  },
  Hungary: {
    mini: "http://flags.fmcdn.net/data/flags/mini/hu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/hu.png",
  },
  Iceland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/is.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/is.png",
  },
  India: {
    mini: "http://flags.fmcdn.net/data/flags/mini/in.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/in.png",
  },
  Indonesia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/id.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/id.png",
  },
  Iran: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ir.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ir.png",
  },
  Iraq: {
    mini: "http://flags.fmcdn.net/data/flags/mini/iq.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/iq.png",
  },
  Ireland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ie.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ie.png",
  },
  Israel: {
    mini: "http://flags.fmcdn.net/data/flags/mini/il.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/il.png",
  },
  Italy: {
    mini: "http://flags.fmcdn.net/data/flags/mini/it.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/it.png",
  },
  Jamaica: {
    mini: "http://flags.fmcdn.net/data/flags/mini/jm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/jm.png",
  },
  Japan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/jp.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/jp.png",
  },
  Jordan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/jo.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/jo.png",
  },
  Kazakhstan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/kz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kz.png",
  },
  Kenya: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ke.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ke.png",
  },
  Kiribati: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ki.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ki.png",
  },
  Kosovo: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ks.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ks.png",
  },
  Kuwait: {
    mini: "http://flags.fmcdn.net/data/flags/mini/kw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kw.png",
  },
  Kyrgyzstan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/kg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kg.png",
  },
  Laos: {
    mini: "http://flags.fmcdn.net/data/flags/mini/la.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/la.png",
  },
  Latvia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/lv.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lv.png",
  },
  Lebanon: {
    mini: "http://flags.fmcdn.net/data/flags/mini/lb.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lb.png",
  },
  Lesotho: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ls.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ls.png",
  },
  Liberia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/lr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lr.png",
  },
  Libya: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ly.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ly.png",
  },
  Liechtenstein: {
    mini: "http://flags.fmcdn.net/data/flags/mini/li.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/li.png",
  },
  Lithuania: {
    mini: "http://flags.fmcdn.net/data/flags/mini/lt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lt.png",
  },
  Luxembourg: {
    mini: "http://flags.fmcdn.net/data/flags/mini/lu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lu.png",
  },
  Macedonia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mk.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mk.png",
  },
  Madagascar: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mg.png",
  },
  Malawi: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mw.png",
  },
  Malaysia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/my.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/my.png",
  },
  Maldives: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mv.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mv.png",
  },
  Mali: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ml.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ml.png",
  },
  Malta: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mt.png",
  },
  Marshall: {
    alias: "Marshall Islands",
    mini: "http://flags.fmcdn.net/data/flags/mini/mh.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mh.png",
  },
  Mauritania: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mr.png",
  },
  Mauritius: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mu.png",
  },
  Mexico: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mx.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mx.png",
  },
  Micronesia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/fm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/fm.png",
  },
  Moldova: {
    mini: "http://flags.fmcdn.net/data/flags/mini/md.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/md.png",
  },
  Monaco: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mc.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mc.png",
  },
  Mongolia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mn.png",
  },
  Montenegro: {
    mini: "http://flags.fmcdn.net/data/flags/mini/me.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/me.png",
  },
  Morocco: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ma.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ma.png",
  },
  Mozambique: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mz.png",
  },
  Myanmar: {
    mini: "http://flags.fmcdn.net/data/flags/mini/mm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/mm.png",
  },
  Namibia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/na.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/na.png",
  },
  Nauru: {
    mini: "http://flags.fmcdn.net/data/flags/mini/nr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/nr.png",
  },
  Nepal: {
    mini: "http://flags.fmcdn.net/data/flags/mini/np.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/np.png",
  },
  Netherlands: {
    mini: "http://flags.fmcdn.net/data/flags/mini/nl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/nl.png",
  },
  NZ: {
    alias: "New Zealand",
    mini: "http://flags.fmcdn.net/data/flags/mini/nz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/nz.png",
  },
  Nicaragua: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ni.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ni.png",
  },
  Niger: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ne.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ne.png",
  },
  Nigeria: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ng.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ng.png",
  },
  Niue: {
    mini: "http://flags.fmcdn.net/data/flags/mini/nu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/nu.png",
  },
  NKorea: {
    alias: "North Korea",
    mini: "http://flags.fmcdn.net/data/flags/mini/kp.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kp.png",
  },
  Norway: {
    mini: "http://flags.fmcdn.net/data/flags/mini/no.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/no.png",
  },
  Oman: {
    mini: "http://flags.fmcdn.net/data/flags/mini/om.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/om.png",
  },
  Pakistan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pk.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pk.png",
  },
  Palau: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pw.png",
  },
  Panama: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pa.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pa.png",
  },
  PGuinea: {
    alias: "Papua new Guinea",
    mini: "http://flags.fmcdn.net/data/flags/mini/pg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pg.png",
  },
  Paraguay: {
    mini: "http://flags.fmcdn.net/data/flags/mini/py.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/py.png",
  },
  China: {
    alias: "People's Republic China",
    mini: "http://flags.fmcdn.net/data/flags/mini/cn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cn.png",
  },
  Peru: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pe.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pe.png",
  },
  Philippines: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ph.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ph.png",
  },
  Poland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pl.png",
  },
  Portugal: {
    mini: "http://flags.fmcdn.net/data/flags/mini/pt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/pt.png",
  },
  Qatar: {
    mini: "http://flags.fmcdn.net/data/flags/mini/qa.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/qa.png",
  },
  Taiwan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tw.png",
  },
  RC: {
    alias: "Republic of Congo",
    mini: "http://flags.fmcdn.net/data/flags/mini/cg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/cg.png",
  },
  Romania: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ro.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ro.png",
  },
  Russia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ru.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ru.png",
  },
  Rwanda: {
    mini: "http://flags.fmcdn.net/data/flags/mini/rw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/rw.png",
  },
  SKN: {
    alias: "Saint Kitts and Nevis",
    mini: "http://flags.fmcdn.net/data/flags/mini/kn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kn.png",
  },
  SL: {
    alias: "Saint Lucia",
    mini: "http://flags.fmcdn.net/data/flags/mini/lc.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lc.png",
  },
  SVG: {
    alias: "Saint Vincent and the Grenadines",
    mini: "http://flags.fmcdn.net/data/flags/mini/vc.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/vc.png",
  },
  Samoa: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ws.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ws.png",
  },
  SM: {
    alias: "San Marino",
    mini: "http://flags.fmcdn.net/data/flags/mini/sm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sm.png",
  },
  STP: {
    alias: "Sao Tome and Principe",
    mini: "http://flags.fmcdn.net/data/flags/mini/st.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/st.png",
  },
  Arab: {
    alias: "Saudi Arabia",
    mini: "http://flags.fmcdn.net/data/flags/mini/sa.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sa.png",
  },
  Senegal: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sn.png",
  },
  Serbia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/rs.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/rs.png",
  },
  Seychelles: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sc.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sc.png",
  },
  Sierra: {
    alias: "Sierra Leone",
    mini: "http://flags.fmcdn.net/data/flags/mini/sl.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sl.png",
  },
  Singapore: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sg.png",
  },
  Slovakia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sk.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sk.png",
  },
  Slovenia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/si.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/si.png",
  },
  Solomon: {
    alias: "Solomon Islands",
    mini: "http://flags.fmcdn.net/data/flags/mini/sb.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sb.png",
  },
  Somalia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/so.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/so.png",
  },
  RSA: {
    alias: "Republic of South Africa",
    mini: "http://flags.fmcdn.net/data/flags/mini/za.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/za.png",
  },
  SKorea: {
    alias: "South Korea",
    mini: "http://flags.fmcdn.net/data/flags/mini/kr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/kr.png",
  },
  SSudan: {
    alias: "South Sudan",
    mini: "http://flags.fmcdn.net/data/flags/mini/ss.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ss.png",
  },
  Spain: {
    mini: "http://flags.fmcdn.net/data/flags/mini/es.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/es.png",
  },
  Lanka: {
    alias: "Shri Lanka",
    mini: "http://flags.fmcdn.net/data/flags/mini/lk.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/lk.png",
  },
  Sudan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sd.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sd.png",
  },
  Suriname: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sr.png",
  },
  Swaziland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sz.png",
  },
  Sweden: {
    mini: "http://flags.fmcdn.net/data/flags/mini/se.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/se.png",
  },
  Switzerland: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ch.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ch.png",
  },
  Syria: {
    mini: "http://flags.fmcdn.net/data/flags/mini/sy.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/sy.png",
  },
  Tajikistan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tj.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tj.png",
  },
  Tanzania: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tz.png",
  },
  Thailand: {
    mini: "http://flags.fmcdn.net/data/flags/mini/th.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/th.png",
  },
  Togo: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tg.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tg.png",
  },
  Tonga: {
    mini: "http://flags.fmcdn.net/data/flags/mini/to.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/to.png",
  },
  "Trinidad and Tobago": {
    alias: "Trinidad and Tobago",
    mini: "http://flags.fmcdn.net/data/flags/mini/tt.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tt.png",
  },
  Tunisia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tn.png",
  },
  Turkey: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tr.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tr.png",
  },
  Turkmenistan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tm.png",
  },
  Tuvalu: {
    mini: "http://flags.fmcdn.net/data/flags/mini/tv.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/tv.png",
  },
  Uganda: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ug.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ug.png",
  },
  Ukraine: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ua.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ua.png",
  },
  "United Arab Emirates": {
    mini: "http://flags.fmcdn.net/data/flags/mini/ae.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ae.png",
  },
  "United Kingdom": {
    mini: "http://flags.fmcdn.net/data/flags/mini/gb.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/gb.png",
  },
  "United States": {
    mini: "http://flags.fmcdn.net/data/flags/mini/us.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/us.png",
  },
  Uruguay: {
    mini: "http://flags.fmcdn.net/data/flags/mini/uy.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/uy.png",
  },
  Uzbekistan: {
    mini: "http://flags.fmcdn.net/data/flags/mini/uz.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/uz.png",
  },
  Vanuatu: {
    mini: "http://flags.fmcdn.net/data/flags/mini/vu.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/vu.png",
  },
  Vatican: {
    alias: "Vatican City",
    mini: "http://flags.fmcdn.net/data/flags/mini/va.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/va.png",
  },
  Venezuela: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ve.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ve.png",
  },
  Vietnam: {
    mini: "http://flags.fmcdn.net/data/flags/mini/vn.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/vn.png",
  },
  Sahara: {
    alias: "Western Sahara",
    mini: "http://flags.fmcdn.net/data/flags/mini/eh.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/eh.png",
  },
  Yemen: {
    mini: "http://flags.fmcdn.net/data/flags/mini/ye.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/ye.png",
  },
  Zambia: {
    mini: "http://flags.fmcdn.net/data/flags/mini/zm.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/zm.png",
  },
  Zimbabwe: {
    mini: "http://flags.fmcdn.net/data/flags/mini/zw.png",
    normal: "http://flags.fmcdn.net/data/flags/normal/zw.png",
  },
};

const Panel = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("availableOrders");
  const showAvailableOrders = () => setActiveTab("availableOrders");
  const showExecutingOrders = () => setActiveTab("executingOrders");
  const [showConfirmation, setShowConfirmation] = useState(false); // Popup confirmation order set to false by default
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [runTutorial, setRunTutorial] = useState(false);
  const [showMockOrder, setShowMockOrder] = useState(false);
  const [showMockOrderWaiting, setShowMockOrderWaiting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const toast = useToast();

  const [wsUrl, setwsUrl] = useState(process.env.REACT_APP_WS_URL);
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  useEffect(() => {
    const hasSeenJoyride = localStorage.getItem("hasSeenJoyride");
    const hasSeenEmail = localStorage.getItem("hasSeenEmail");

    if (hasSeenJoyride === "true") {
      setRunTutorial(false);
      setShowMockOrder(false);
      setShowMockOrderWaiting(false);
    } else {
      setRunTutorial(true);
      setShowMockOrder(true);
      setShowMockOrderWaiting(true);

      localStorage.setItem("hasSeenJoyride", true);
    }

    const isFirstVisit = localStorage.getItem("firstVisit") === null;

    if (isFirstVisit) {
      setFirstLoad(true);
      localStorage.setItem("firstVisit", "visited");
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, step } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      setShowMockOrder(false);
      setShowMockOrderWaiting(false);
    }

    // Vérifier si l'étape actuelle est celle qui cible .manage-orders
    if (step.target === ".manage-orders") {
      // Vérifier si l'onglet actuel n'est pas celui des commandes en cours d'exécution
      if (activeTab !== "executingOrders") {
        // Changer pour l'onglet des commandes en cours d'exécution
        setActiveTab("executingOrders");
      }
    }
  };

  // Define a mock order
  const mockOrder = {
    id: "Demo",
    amount: "10.00",
    prize: "2.50",
    // Add other necessary fields that your order object requires
  };

  // Define a mock orderWaiting object
  const mockOrderWaiting = {
    id: "Demo",
    amount: "15.00",
    prize: "20.00",
    // Add other necessary fields that your orderWaiting object requires
  };

  // Modify this function to hide the mock order after the tutorial
  const onTutorialEnd = () => {
    setShowMockOrder(false);
  };

  const tutorialSteps = [
    {
      target: ".profileConnect",
      content: "Enter your Monero wallet here.",
      disableBeacon: true,
    },
    {
      target: ".new-orders",
      content: "View new available orders here.",
      disableBeacon: true,
    },
    {
      target: ".orderWaiting",
      content: "Click an order to start processing it.",
    },
    {
      target: ".orderWaitingPrice",
      content:
        "This displays the pending order's price in USD you'll have to pay on Amazon.",
    },
    {
      target: ".orderWaitingPrize",
      content:
        "Here's the USD equivalent you'll receive for the order in Monero, plus your share of the fixed order fees (Order price plus 5$).",
    },
    {
      target: ".manage-orders",
      content: "Orders you're currently executing are shown here.",
    },
    {
      target: "#trackingCodeInput",
      content:
        "Enter the order's tracking code here once available. (tracking code that amazon sends you once the order is shipped)",
    },
    {
      target: ".OrderDetailsButton",
      content: "View order details here.",
    },
    {
      target: ".chatButton",
      content: "Use this button to chat with the customer.",
    },
  ];

  const openConfirmation = (orderId) => {
    setSelectedOrderId(orderId);
    setShowConfirmation(true);
  };

  const confirmAcceptOrder = async () => {
    console.log("Accepting order");
    if (selectedOrderId) {
      setPendingOrders((prevOrders) =>
        prevOrders.filter((id) => id !== selectedOrderId)
      );

      await acceptOrder(selectedOrderId);
      setSelectedOrderId(null);
    }
  };

  async function loadManagedOrders() {
    try {
      const { data } = await axios.get("/users/fetchOrders", {
        withCredentials: true,
      });
      setOrders(data["message"]);
    } catch (error) {
      if (error.response && error.response.data.code === 200) {
        navigate("/");
      } else {
        console.error("Error fetching orders:", error);
      }
    }
  }

  const acceptOrder = async (orderId) => {
    try {
      const response = await axios.post(
        "/orders/update",
        {
          status: "accepted",
          id: orderId,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        // remove order from array
        await setPendingOrders((prevOrders) =>
          prevOrders.filter((id) => id !== orderId)
        );
        await loadManagedOrders();

        toast({
          title: "Order accepted!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "You cannot accept this order.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const cancelOrder = async (orderId, reason) => {
    try {
      const response = await axios.post(
        "/orders/update",
        {
          status: "pending",
          id: orderId,
          reason: reason,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        await setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );

        toast({
          title: "Order cancelled",
          description: "The order has been successfully cancelled.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "An error occurred while cancelling the order.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    loadManagedOrders();

    // Créer un EventSource pour écouter les événements SSE
    const eventSource = new EventSource("https://api.monezon.com:3001/events", {
      withCredentials: true,
    });

    eventSource.onmessage = (event) => {
      try {
        if (event.data.startsWith("{") || event.data.startsWith("[")) {
          event = JSON.parse(event.data);
          console.log(event.action);
          switch (event.action) {
            case "addOrder":
              setPendingOrders((prevPendingOrders) => [
                ...prevPendingOrders,
                event.data,
              ]);
              break;
            case "delOrder":
              console.log(event.action);
              console.log(event.data.id);
              setPendingOrders((prevPendingOrders) =>
                prevPendingOrders.filter((order) => order.id !== event.data.id)
              );
              break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    eventSource.onerror = (error) => {
      setPendingOrders([]);
    };

    return () => {
      eventSource.close();
      setPendingOrders([]);
    };
  }, []);

  useEffect(() => {
    try {
      axios.get("/auth", { withCredentials: true }).then((response) => {
        if (response.data.code == 100) {
          navigate("/login");
        } else {
        }
      });
    } catch (error) {}
  }, []);



  const [hideDiscountedOrders, setHideDiscountedOrders] = useState(JSON.parse(localStorage.getItem('hideDiscountedOrders')));
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [isFilterLoaded, setIsFilterLoaded] = useState(false);

  useEffect(() => {
    console.log("Hide: ", hideDiscountedOrders);
  }, [hideDiscountedOrders]);

  // Handle price range change
  const handlePriceRangeChange = (values) => {
    setPriceRange(values);
  };

  // Toggle hide discounted orders
  const toggleHideDiscountedOrders = (value) => {
    setHideDiscountedOrders(value);
  };

  // Save filter preferences to localStorage
  useEffect(() => {
    if (isFilterLoaded) {
      localStorage.setItem('hideDiscountedOrders', hideDiscountedOrders);
      localStorage.setItem('priceRange', JSON.stringify(priceRange));
    }
  }, [hideDiscountedOrders, priceRange, isFilterLoaded]);


  // Load filter preferences from localStorage
  const loadFilterPreferences = () => {
    const hideDiscountedOrders = JSON.parse(localStorage.getItem('hideDiscountedOrders'));
    const priceRange = JSON.parse(localStorage.getItem('priceRange'));
  

  
    if (priceRange !== null) {
      setPriceRange(priceRange);
    }
  
    // Set filter loaded state to true once preferences are loaded
    setIsFilterLoaded(true);
  };

  // Load preferences on component mount
  useEffect(() => {
    loadFilterPreferences();
  }, []);

  const filteredOrders = pendingOrders.filter((order) => {
    // Apply filters based on user selection
    const isWithinPriceRange =
      order.amount >= priceRange[0] && order.amount <= priceRange[1];
    const isDiscounted = hideDiscountedOrders ? !order.choosedDiscount : true;
    return isWithinPriceRange && isDiscounted;
  });
  console.log(filteredOrders)
  return (
    <div className="mt-32">
      <div className="panel h-[75vh]">
        {firstLoad && (
          <h1 className="absolute z-50 bg-red-500 text-white px-4 py-2 -bottom-20 rounded-xl">
            Click on Profile to set your email for chat notifications.
          </h1>
        )}

        <div className="tabs">
          <button
            onClick={showAvailableOrders}
            className={activeTab === "availableOrders" ? "active" : "inactive"}
          >
            Available Orders
          </button>
          <button
            onClick={showExecutingOrders}
            className={activeTab === "executingOrders" ? "active" : "inactive"}
          >
            Processing
          </button>
        </div>

        <div
          style={{ border: "1px solid #DDDDDD" }}
          className={`w-[86vw] mdE1:w-[30vw] panelSm:w-[38vw] pendingOrders rounded-xl new-orders p-5 bg-white h-[70vh] ${
            activeTab === "availableOrders" ? "active-tabFlex" : ""
          }`}
        >
          <div
            className={`bg-white new-orders h-full rounded-sm w-full`}
            style={{
              border: "1px dashed #DDDDDD",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/*contenu de la tab "availableOrders"*/}
            <Modal
              isOpen={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              isCentered
            >
              <ModalOverlay />
              <ModalContent borderRadius="lg" boxShadow="xl">
                <ModalHeader
                  bg="orange.100"
                  color="orange.800"
                  borderTopRadius="lg"
                  p={4}
                >
                  Order Acceptance Confirmation
                </ModalHeader>
                <ModalCloseButton color="orange.800" />
                <ModalBody p={6}>
                  <VStack spacing={6} align="stretch">
                    <Text fontSize="lg" fontWeight="bold">
                      Are you sure you want to accept order #{selectedOrderId}?
                    </Text>
                    <Checkbox
                      isChecked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      colorScheme="orange"
                    >
                      <Text fontWeight="medium">
                        I acknowledge and agree to the terms.
                      </Text>
                    </Checkbox>
                    <Box bg="red.50" p={4} borderRadius="md">
                      <Text color="red.600" fontSize="sm">
                        <strong>Important:</strong> You must place the order and
                        send your initial message within two days. Failure to do
                        so will result in the order being relisted under
                        available orders.
                      </Text>
                    </Box>
                  </VStack>
                </ModalBody>
                <ModalFooter bg="gray.50" borderBottomRadius="lg">
                  <Button
                    variant="outline"
                    mr={3}
                    onClick={() => setShowConfirmation(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="orange"
                    isDisabled={!termsAccepted}
                    onClick={() => {
                      confirmAcceptOrder();
                      setShowConfirmation(false);
                      setTermsAccepted(false);
                    }}
                  >
                    Accept Order
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Joyride
              steps={tutorialSteps}
              continuous={true}
              disableScrollParentFix={true}
              showSkipButton={true}
              callback={handleJoyrideCallback}
              run={runTutorial}
              styles={{
                options: {
                  arrowColor: "#fff",
                  backgroundColor: "#fff",
                  primaryColor: "#f87b45",
                  textColor: "#004a14",
                  zIndex: 1000,
                },
              }}
            />
            <div className="bg-gray-100 h-14 flex justify-evenly items-center m-2 rounded-sm w-[95%]">
              <p id="title">Available Orders</p>
              
              <Popover>
  <PopoverTrigger>
    <Button variant="outline" colorScheme="orange" size="sm" leftIcon={<SettingsIcon />}>
      Filter
    </Button>
  </PopoverTrigger>
  <PopoverContent>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverHeader>Filter Orders</PopoverHeader>
    <PopoverBody>
      <VStack align="start" spacing={4}>
      <Checkbox
  defaultChecked={hideDiscountedOrders}
  onChange={(e) => toggleHideDiscountedOrders(e.target.checked)}
>
  Hide Discounted Orders
</Checkbox>

        <Box w={"100%"}>
      <Text fontWeight="medium">Price Range</Text>
      <RangeSlider
        aria-label={["min", "max"]}
        value={[priceRange[0], priceRange[1]]} // Use `value` instead of `defaultValue`
        min={0}
        max={5000}
        step={5}
        onChange={handlePriceRangeChange}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
      <HStack justify="space-between">
        <Text>${priceRange[0]}</Text>
        <Text>${priceRange[1]}</Text>
      </HStack>
    </Box>
      </VStack>
    </PopoverBody>
  </PopoverContent>
</Popover>
            </div>
            {showMockOrderWaiting && (
              <div className="orderWaiting" key={mockOrderWaiting.id}>
                <p>
                  #{mockOrderWaiting.id} -
                  <span className="orderWaitingPrice">
                    {" "}
                    Price: ${mockOrderWaiting.amount}
                  </span>
                  <br />
                  <span className="orderWaitingPrize">
                    Prize: ${mockOrderWaiting.prize}
                  </span>
                </p>
                <div className="bgBox">
                  <img src={LivraisonIcon} alt="" srcset="" />
                </div>
              </div>
            )}

            {!showMockOrderWaiting && (
              <>
                {filteredOrders.length === 0 ? (
                  <div className="emptyOrders flex flex-col justify-center items-center word-break">
                    <img src={noNewOrder} alt="Empty Icon" />
                    <p>No order at this moment...</p>
                    {hideDiscountedOrders != null ? (
                    <p>You may have enabled filters that hide some orders.</p>
                  ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  filteredOrders.map((order) => (
                    <div
                      key={order.id}
                      className="mt-2 relative p-4 bg-white w-[95%] h-20 rounded-md"
                      style={{ boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.1)" }}
                    >
                      <div className="relative flex justify-start items-center w-full h-full">
                      <div className="rounded-2xl bg-orange-400 w-[40px] h-[40px] flex justify-center items-center mr-2 mdE1:w-[47px] mdE1:h-[47px]">
                          <img src={Package} className="w-[50%] mdE1:w-[55%]" />
                        </div>
                        <div className="flex flex-col">
                          <p className="text-xs font-semibold">
                            #{String(order.id).substring(0, 5)}
                          </p>
                          <div className="flex items-center space-x-2">
                            <div className="bg-gray-100 rounded-md px-2 py-1">
                              <span className="text-sm font-medium text-gray-600">
                                Pay:
                              </span>
                              <span className="text-sm font-bold text-gray-800 ml-1">
                                ${order.amount}
                              </span>
                            </div>
                            <div className="bg-orange-100 rounded-md px-2 py-1">
                              <span className="text-sm font-medium text-orange-600">
                                Earn:
                              </span>
                              <span className="text-sm font-bold text-orange-800 ml-1">
                                $
                                {order?.feePercentage
                                  ? (parseFloat(order.price) + 5).toFixed(2)
                                  : parseFloat(order.price).toFixed(2)}{" "}
                              </span>
                            </div>
                            <p className="text-sm text-gray-500 mt-1 flex items-center">
                              <img
                                src={moneroLogo}
                                alt="XMR"
                                className="inline-block w-4 h-4 mr-1"
                              />
                              {order.totalXMR.toFixed(4)}
                            </p>
                          </div>
                        </div>
                        <img
                          src={
                            countriesFlags[order.country] &&
                            countriesFlags[order.country]["mini"]
                              ? countriesFlags[order.country]["mini"]
                              : ""
                          }
                          title={order.country}
                          className="absolute top-0 right-0 w-6"
                        />
                      </div>
                      <Badge
                        colorScheme="orange"
                        top={"5px"}
                        position={"absolute"}
                        left={"50%"}
                        transform={"translateX(-50%)"}
                      >
                        {order.choosedDiscount
                          ? `User paid -${order.choosedDiscount}% less`
                          : ""}
                      </Badge>
                      <button
                        onClick={() => openConfirmation(order.id)}
                        className="w-full h-full absolute top-0 left-0"
                      ></button>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={`w-[86vw] mdE1:w-[35vw] panelSm:w-[46vw] bg-white manage-orders h-[70vh]  rounded-xl p-5 ${
            activeTab === "executingOrders" ? "active-tab" : ""
          }`}
          style={{ border: "1px solid #DDDDDD" }}
        >
          <div
            className={`bg-white h-full rounded-sm`}
            style={{
              border: "1px dashed #DDDDDD",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Modal
              isOpen={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              isCentered
            ></Modal>
            <div className="bg-gray-100 h-14 flex justify-center items-center m-2 rounded-sm w-[98%]">
              <p id="title">Processing</p>
            </div>
            <ManageOrders
              orders={showMockOrder ? [mockOrder, ...orders] : orders}
              cancelOrder={cancelOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
