import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Text,
  Badge,
} from "@chakra-ui/react";

import sessionQR from "../styles/sessionqr.jpg";
import simplexQR from "../styles/simplex.png";
import howMany from "../styles/howmany.png";
import levelUp from "../styles/levelup.png";
import trustLevel from "../styles/trustlevel.png";

import { QuestionOutlineIcon } from "@chakra-ui/icons";
import PGP from "../monezon_0x2F7D36DC_public.asc";

function FAQ() {
  const faqs = {
    "Contact": [
      {
        question: "How can I contact support?",
        answer: (
          <>
            <p>Session: 058f0af56f68bb0e4110ac27c0f791cb2293b3d92c9b757e46efed129892f3960e</p>
            <p>SimpleX: Click <a href="https://simplex.chat/contact#/?v=2-5&smp=smp%3A%2F%2FPQUV2eL0t7OStZOoAsPEV2QYWt4-xilbakvGUGOItUo%3D%40smp6.simplex.im%2FnviUMkesw8UyyZbr0UMCK5Zjw7sl-6Dh%23%2F%3Fv%3D1-2%26dh%3DMCowBQYDK2VuAyEAs-Jjj6Oo-O_7GrFhrV45btnh6VcAAmpkO4v0ynNHCjg%3D%26srv%3Dbylepyau3ty4czmn77q4fglvperknl4bi2eb2fdy2bh4jxtf32kf73yd.onion&data=%7B%22type%22%3A%22group%22%2C%22groupLinkId%22%3A%22Dmte3IEKWh6o5vSJy8vRlg%3D%3D%22%7D" target="_blank">HERE</a></p>
          </>
        ),
      },
    ],
    "Buying on Monezon": [
      {
        question: "What are the steps to correctly fill an order?",
        answer: (
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/STQoiM4ljC0?si=4X7vg9JhdXvMWTkS"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        ),
      },
      {
        question: "Am I required to follow those steps?",
        answer:
          "To ensure the process goes smoothly, please attentively follow the steps seen in the tutorial.",
      },
      {
        question: "What are the fees",
        answer:
          "Monezon keeps $5 (+5% under $100 / 4% under $500 / 3% under $1000) from each transaction to cover operational costs. Executors receive the amount of the order in XMR plus a $5 commission for each order they process.",
      },
      {
        question: "Can I communicate with my executor?",
        answer:
          "Yes! We've developped an end to end encryption chat between you and your Executor! To get started, click on 'Track Your Order', fill in your passphrase and access the chat!",
      },
      {
        question: "What happens if there's an issue with my order",
        answer:
          "Always try to communicate with your executor first, if the issue is not resolved, please contact us on X / Session / SimpleX.",
      },

      {
        question:
          "What if the value of the XMR I paid decreased below the price of my wishlist?",
        answer: "Please contact us to get a full refund.",
      },
      {
        question: "What if I sent an incorrect amount of XMR?",
        answer: (
          <p>
            You can send additional XMR to your executor via the chat, but it's
            only recommended to do so if your executor has earned enough trust
            level. If your executor doesn't have this indicator, please contact
            us to send more funds. <img src={trustLevel}></img>
          </p>
        ),
      },
      {
        question: "What if I my items are not available?",
        answer:
          "If you can find similar items with matching price, add them to your wishlist, otherwise contact us.",
      },
      {
        question: "How can I track my order",
        answer:
          "Click on 'Track Your Order' and fill in your passphrase. Once on the chat, click on 'Order Status'.",
      },
      {
        question: "In which countries does Monezon works?",
        answer: (
          <div>
            United States, United Kingdom, France, Germany, Canada,
            Australia, Italy, Spain, Japan, China, India, Brazil,
            Mexico, Netherlands, Singapore, Sweden, United Arab
            Emirates, Saudi Arabia, Turkey, South Korea, Switzerland,
            Austria, Belgium, Norway, Denmark, Ireland, New Zealand,
            Finland, Portugal, Poland, Greece, Hungary, Czech
            Republic, Romania, Russia, South Africa, Malaysia,
            Thailand, Indonesia, Philippines, Taiwan, Vietnam,
            Egypt, Israel, Argentina, Chile, Colombia, Peru
          </div>
        ),
      },
    ],
    "Earning XMR on Monezon": [
      {
        question: "How can I start earning XMR on Monezon?",
        answer:
          "There are several ways to earn XMR on Monezon, you can either become an executor and process orders, or you can earn through our referral program.",
      },
      {
        question: "What's an executor?",
        answer:
          "An executor is a person who purchases Amazons wishlists from buyers. They receive the amount of the order in XMR plus a $5 commission for each order they process.",
      },
      {
        question: "How can I become an executor?",
        answer:
          "First, register on Monezon, then contact us to get your account approved.",
      },
      {
        question:
          "What do I need to know in order to perform my tasks effectively?",
        answer: (
          <div>
            <p>
              - You have to purchase the buyer's wishlist withing 2 days after
              accepting an order.
            </p>
            <p>- Regularly update the buyer on the order status.</p>
            <p>
              - If you encounter the indisponibility of an item in the wishlist,
              you can access a pdf to solve this issue on the order chat.
            </p>
            <p>
              - Try to communicate with your buyer first before contacting us.
            </p>
          </div>
        ),
      },
      {
        question: "How much can I earn by becoming an Executor?",
        answer:
          "By becoming an executor, you'll receive the amount of the order in XMR plus a $5 commission for each order you process.",
      },
      {
        question: "How much can I earn by becoming an affiliate?",
        answer:
          "By becoming an affiliate, you'll earn $3 for every orders using your referral code. You will be able to cashout a minimum amount of $20.",
      },
      {
        question: "Do I risk anything with Amazon?",
        answer:
          "Since we're using an Amazon feature called 'Wishlists', you're not doing anything wrong, people regularly purchase others wishlists on Amazon.",
      },
      {
        question: "The wishlist url isn't working, what should I do?",
        answer: "Communicate with the buyer in order to get the correct url.",
      },
      {
        question:
          "The wishlist price is higher than what I'm earning, what should I do?",
        answer: "Please contact us to get a full refund.",
      },
      {
        question:
          "The buyer is claiming to not have received their order, how can I protect myself?",
        answer:
          "Please, immediately contact us. As long as you have proofs of purchase, you'll be safe from disputes.",
      },
    ],
  };

  return (
    <Box p={5} mt={40}>
      {" "}
      <Heading mb={4}>Frequently Asked Questions</Heading>
      {Object.keys(faqs).map((category, index) => (
        <div key={index}>
          <h1 style={{ fontSize: "25px" }}>{category}</h1>
          <Accordion allowToggle>
            {faqs[category].map((faq, questionIndex) => (
              <AccordionItem
                key={questionIndex}
                style={{ marginBottom: "5px", marginTop: "5px" }}
              >
                <h2>
                  <AccordionButton>
                    <Box
                      flex="1"
                      textAlign="left"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <QuestionOutlineIcon />
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="#6c7a89">
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ))}
    </Box>
  );
}

export default FAQ;
