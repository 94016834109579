import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import axios from "axios";
import OrderIdExemple from "../styles/orderIdExemple.png";
import chatIcon from "../styles/chat.svg";
import noOrder from "../styles/no-order.png";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useBreakpointValue,
  Box,
  Flex,
  VStack,
  HStack,
  Divider,
  Select,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  Text,
  useToast,
  Badge,
  SimpleGrid
} from "@chakra-ui/react";
import Button from "./magicui/button";

import {
  HamburgerIcon,
  CheckIcon,
  CloseIcon,
  InfoIcon,
  TimeIcon,
  WarningTwoIcon,
  ChevronRightIcon,
  ChatIcon,
  WarningIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";

const ManageOrders = ({ orders, cancelOrder }) => {
  const toast = useToast();

  const [localOrders, setLocalOrders] = useState([]);

  useEffect(() => {
    setLocalOrders(orders);
  }, [orders]);

  const [modalStates, setModalStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [showOrderIdExample, setShowOrderIdExample] = useState(false);
  const [trackingURL, setTrackingURL] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedOrderIdForEdit, setSelectedOrderIdForEdit] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [shouldFocusInput, setShouldFocusInput] = useState(false);
  const [isModalBlocked, setIsModalBlocked] = useState(false);
  const [itemDetailsModalOpen, setItemDetailsModalOpen] = useState({});
  const [hasTrackingLink, setHasTrackingLink] = useState(false);

  const [confirmShippingModalOpen, setConfirmShippingModalOpen] =
    useState(false);

  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);

  const [orderedOrderModalOpen, setOrderedOrderModalOpen] = useState(false);

  const [confirmDeliveryModalOpen, setConfirmDeliveryModalOpen] =
    useState(false);

  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  const predefinedReasons = [
    "Wishlist price is higher than what the user paid",
    "I do not have the funds to complete the order",
    "Other",
  ];

  const {
    isOpen: isDetailsDrawerOpen,
    onOpen: onDetailsDrawerOpen,
    onClose: onDetailsDrawerClose,
  } = useDisclosure();

  const inputRefs = useRef({});

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  const closeOrderIdExample = () => {
    setShowOrderIdExample(false);
  };

  async function setCurrentOrder(id) {
    const currentOrder = localOrders.find((order) => order.id === id);
    setSelectedOrder(currentOrder);
  }

  const handleConfirmCancellation = () => {
    const reason = selectedReason === "Other" ? customReason : selectedReason;
    if (reason) {
      if (selectedReason === "Other" && customReason.length < 10) {
        toast({
          title: "Error.",
          description: "Please enter a valid reason.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      setCancelOrderModalOpen(false);
      cancelOrder(selectedOrderIdForEdit, reason); // Assume cancelOrder takes a reason as a second argument
    } else {
      // Optionally, show an error message if no reason is provided
    }
  };
  // Function to log the input value and show check icon

  async function updateOrderTrackingStatus(state) {
    await axios
      .post(
        "/orders/updateTrackingStatus",
        {
          id: selectedOrderIdForEdit,
          state: state,
        },
        { withCredentials: true }
      )
      .then((response) => {
        toast({
          title: "Success.",
          description: "You have successfuly updated the order.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setLocalOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === selectedOrderIdForEdit
              ? { ...order, trackingStatus: state }
              : order
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function updateTracking(trackingUrl = null) {
    if (trackingUrl != null) {
      await axios
        .post(
          "/orders/updateTracking",
          {
            id: selectedOrderIdForEdit,
            trackingUrl: trackingUrl,
          },
          { withCredentials: true }
        )
        .then((response) => {
          toast({
            title: "Success.",
            description: "You have successfuly updated the order.",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setLocalOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.id === selectedOrderIdForEdit
                ? {
                    ...order,
                    trackingStatus: "shipped",
                    trackingCode: trackingUrl,
                  }
                : order
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios
        .post(
          "/orders/updateTrackingStatus",
          {
            id: selectedOrderIdForEdit,
            state: "shipped",
          },
          { withCredentials: true }
        )
        .then((response) => {
          toast({
            title: "Success.",
            description: "You have successfuly updated the order.",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setLocalOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.id === selectedOrderIdForEdit
                ? { ...order, trackingStatus: "shipped" }
                : order
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    if (shouldFocusInput && selectedOrderIdForEdit !== null) {
      const focusedInput = inputRefs.current[selectedOrderIdForEdit];
      if (focusedInput) {
        focusedInput.focus();
      }
      setShouldFocusInput(false); // Réinitialiser l'état
    }
  }, [shouldFocusInput, selectedOrderIdForEdit]);

  return (
    <div className="manageOrders">
      <Modal
        isOpen={orderedOrderModalOpen}
        onClose={() => setOrderedOrderModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent className="bg-white rounded-lg shadow-xl">
          <ModalHeader className="text-2xl font-bold text-gray-800 border-b pb-3">Order Confirmation</ModalHeader>
          <ModalCloseButton className="text-gray-500 hover:text-gray-700" />
          <ModalBody className="py-6">
            <Text className="text-lg text-black-600">Are you sure you have ordered the item(s)?</Text>
            <Text className="text-sm text-gray-500 mt-2">This action will update the order status to "Ordered".</Text>
          </ModalBody>

          <ModalFooter className="border-t pt-4">
            <Button
              className="bg-orange-500 hover:bg-orange-600  text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mr-3"
              onClick={() => {
                setOrderedOrderModalOpen(false);
                updateOrderTrackingStatus("ordered");
              }}
            >
              I ordered the item(s)
            </Button>
            <Button
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
              onClick={() => setOrderedOrderModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={confirmDeliveryModalOpen}
        onClose={() => setConfirmDeliveryModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent className="bg-white rounded-lg shadow-xl">
          <ModalHeader className="text-2xl font-bold text-gray-800 border-b pb-3">Delivery Confirmation</ModalHeader>
          <ModalCloseButton className="text-gray-500 hover:text-gray-700" />
          <ModalBody className="py-6">
            <Text className="text-lg text-gray-700 mb-4">Can you confirm delivery of the item(s)?</Text>
            <Text className="text-sm text-gray-600">
              The buyer will have to confirm the receipt on their end.
            </Text>
          </ModalBody>

          <ModalFooter className="border-t pt-4">
            <Button
              className="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mr-3"
              onClick={() => {
                setConfirmDeliveryModalOpen(false);
                updateOrderTrackingStatus("delivered");
              }}
            >
              Confirm Delivery
            </Button>
            <Button
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
              onClick={() => setConfirmDeliveryModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {localOrders.length === 0 ? (
        <div className="emptyOrders">
          <img src={noOrder} alt="Empty Icon" />
          <p>Not processing any orders...</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {localOrders.map((order) => (
            <div
              key={order.id}
              className="order bg-white rounded-lg shadow-md p-4 overflow-scroll"
            >
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<HamburgerIcon />}
                      variant="outline"
                    />
                    <MenuList>
                      <MenuItem
                        icon={<InfoIcon />}
                        onClick={() => {
                          onDetailsDrawerOpen();
                          setCurrentOrder(order.id);
                        }}
                      >
                        View order details
                      </MenuItem>
                      {order.trackingStatus === "null" && (
                        <MenuItem
                          icon={<CheckIcon />}
                          onClick={() => {
                            setSelectedOrderIdForEdit(order.id);
                            setOrderedOrderModalOpen(true);
                          }}
                        >
                          I ordered the item(s)
                        </MenuItem>
                      )}
                      {order.trackingStatus === "ordered" && (
                        <MenuItem
                          icon={<CheckIcon />}
                          onClick={() => {
                            setSelectedOrderIdForEdit(order.id);
                            setConfirmShippingModalOpen(true);
                          }}
                        >
                          Set as shipped
                        </MenuItem>
                      )}
                      {order.trackingStatus === "shipped" && (
                        <MenuItem
                          icon={<CheckIcon />}
                          onClick={() => {
                            setSelectedOrderIdForEdit(order.id);
                            setConfirmDeliveryModalOpen(true);
                          }}
                        >
                          Set as delivered
                        </MenuItem>
                      )}
                      {order.deliveryValidated === "accepted" && (
                        <MenuItem icon={<CheckIcon />} isDisabled>
                          Receipt acknowledged by the buyer
                        </MenuItem>
                      )}
                      {order.deliveryValidated === "denied" && (
                        <MenuItem icon={<WarningIcon />} isDisabled>
                          Receipt denied by the buyer
                        </MenuItem>
                      )}
                      {order.trackingStatus === "delivered" &&
                        order.deliveryValidated !== "accepted" &&
                        order.deliveryValidated !== "denied" && (
                          <MenuItem icon={<TimeIcon />} isDisabled>
                            Waiting for confirmation
                          </MenuItem>
                        )}
                      {order.trackingStatus !== "delivered" && (
                        <MenuItem
                          icon={<CloseIcon />}
                          onClick={() => {
                            setSelectedOrderIdForEdit(order.id);
                            setCancelOrderModalOpen(true);
                          }}
                        >
                          Cancel order
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                  <div className="flex items-center space-x-2">
                    <Badge variant="outline" colorScheme="orange">
                      #{String(order.id).substring(0, 5)}
                    </Badge>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-lg font-semibold">${order.amount}</p>
                    <p className="text-sm text-gray-500">{order.orderDate}</p>
                  </div>
                  <Link to={"chat/" + order.id + "/"}>
                    <Button
                      variant="outline"
                      className="block px-3 py-2 bg-orange-100 text-orange-600 hover:bg-orange-200 p-2 rounded-lg w-full text-md border-orange-300 border"
                    >
                      Chat
                    </Button>
                  </Link>
                </div>
                <ReactModal
                isOpen={showOrderIdExample}
                onRequestClose={closeOrderIdExample}
                style={{ overlay: { background: "#00002e" } }}
                ariaHideApp={false}
                className="ExempleIdModal"
              >
                <img src={OrderIdExemple} alt="Example of Order ID" />
              </ReactModal>
              <Drawer
                isOpen={isDetailsDrawerOpen}
                placement="right"
                onClose={onDetailsDrawerClose}
                size="md"
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px" className="bg-orange-100 text-orange-800">
                    Order Details #{String(selectedOrder.id)}
                  </DrawerHeader>

                  <DrawerBody className="bg-gray-50">
                    <VStack spacing={6} align="stretch">
                      <Box className="bg-white p-4 rounded-lg shadow-sm">
                        <Flex justifyContent="space-between" alignItems="center">
                          <Box textAlign="center">
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              You pay
                            </Text>
                            <Text fontSize="xl" fontWeight="bold" color="orange.500">
                              $ {selectedOrder.amount - 5}
                            </Text>
                          </Box>

                          <Box textAlign="center">
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              You get
                            </Text>
                            <Text fontSize="xl" fontWeight="bold" color="orange.500">
                              ${" "}
                              {selectedOrder.choosedDiscount
                                ? (
                                  selectedOrder.amount *
                                  (1 - selectedOrder.choosedDiscount / 100)
                                ).toFixed(2)
                                : selectedOrder.amount}
                            </Text>
                          </Box>

                          <Box textAlign="center">
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              Which equals to
                            </Text>
                            <Text fontSize="xl" fontWeight="bold" color="orange.500">
                              {selectedOrder.totalXMR !== undefined
                                ? `${selectedOrder.totalXMR.toFixed(5)} XMR`
                                : "XMR value not available"}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                      
                      {selectedOrder.choosedDiscount && (
                        <Box className="bg-white p-4 rounded-lg shadow-sm">
                          <Text fontSize="md" fontWeight="medium" color="gray.700">
                            Buyer paid
                            <Badge colorScheme="orange" ml={2} mr={2}>
                              {selectedOrder.choosedDiscount}%
                            </Badge>
                            less
                          </Text>
                        </Box>
                      )}
                      
                      <Box className="bg-white p-4 rounded-lg shadow-sm">
                        <Text fontSize="md" fontWeight="bold" color="gray.700" mb={2}>
                          Wishlist
                        </Text>
                        <Button
                          onClick={() =>
                            window.open(selectedOrder.wishlist, "_blank")
                          }
                          colorScheme="orange"
                          variant="outline"
                          leftIcon={<ExternalLinkIcon />}
                        >
                          Open Wishlist
                        </Button>
                      </Box>
                      
                      <Divider />
                      
                      <Box className="bg-white p-4 rounded-lg shadow-sm">
                        <SimpleGrid columns={2} spacing={4}>
                          <Box>
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              Date
                            </Text>
                            <Text fontSize="md" color="gray.700">{selectedOrder.orderDate}</Text>
                          </Box>
                          <Box>
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              Country
                            </Text>
                            <Text fontSize="md" color="gray.700">{selectedOrder.country}</Text>
                          </Box>
                          <Box>
                            <Text fontSize="sm" fontWeight="medium" color="gray.500">
                              Shipping Option
                            </Text>
                            <Text fontSize="md" color="gray.700">
                              {selectedOrder.shippingOption || "Not specified"}
                            </Text>
                          </Box>
                          {selectedOrder.lockerName && (
                            <>
                              <Box>
                                <Text fontSize="sm" fontWeight="medium" color="gray.500">
                                  Locker Name
                                </Text>
                                <Text fontSize="md" color="gray.700">
                                  {selectedOrder.lockerName}
                                </Text>
                              </Box>
                              <Box>
                                <Text fontSize="sm" fontWeight="medium" color="gray.500">
                                  Locker City
                                </Text>
                                <Text fontSize="md" color="gray.700">
                                  {selectedOrder.lockerCity}
                                </Text>
                              </Box>
                            </>
                          )}
                        </SimpleGrid>
                      </Box>
                      
                      <Box className="bg-white p-4 rounded-lg shadow-sm">
                        <Text fontSize="md" fontWeight="bold" color="gray.700" mb={2}>
                          Note
                        </Text>
                        <Box
                          bg="gray.100"
                          p={3}
                          borderRadius="md"
                          maxHeight="250px"
                          overflowY="auto"
                          className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                        >
                          <Text fontSize="md" color="gray.700">
                            {selectedOrder.note}
                          </Text>
                        </Box>
                      </Box>
                    </VStack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" className="bg-gray-100">
                    <Button
                      variant="outline"
                      mr={3}
                      onClick={onDetailsDrawerClose}
                      colorScheme="orange"
                    >
                      Close
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
              <Modal
                isOpen={confirmShippingModalOpen}
                onClose={() => setConfirmShippingModalOpen(false)}
                isCentered
              >
                <ModalOverlay />
                <ModalContent className="bg-white rounded-lg shadow-xl">
                  <ModalHeader className="text-2xl font-bold text-gray-800 border-b pb-3">Shipping Confirmation</ModalHeader>
                  <ModalCloseButton className="text-gray-500 hover:text-gray-700" />
                  <ModalBody className="py-6">
                    <Text className="text-lg text-gray-700 mb-4">Please confirm that the order has been shipped.</Text>

                    <div className="flex items-center mb-4">
                      <Checkbox
                        id="trackingCheckbox"
                        isChecked={hasTrackingLink}
                        onChange={(e) => setHasTrackingLink(e.target.checked)}
                        className="mr-3"
                      />
                      <label htmlFor="trackingCheckbox" className="text-gray-700 cursor-pointer">
                        I have a tracking link
                      </label>
                    </div>
                    
                    {hasTrackingLink && (
                      <Input
                        placeholder="Enter tracking link"
                        onChange={(e) => setTrackingURL(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                  </ModalBody>

                  <ModalFooter className="border-t pt-4">
                    <Button
                      onClick={() => {
                        setConfirmShippingModalOpen(false);
                        updateTracking(trackingURL);
                      }}
                      className="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mr-3"
                    >
                      Confirm Shipping
                    </Button>
                    <Button
                      onClick={() => setConfirmShippingModalOpen(false)}
                      className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
                <Modal
                  isOpen={cancelOrderModalOpen}
                  onClose={() => setCancelOrderModalOpen(false)}
                  isCentered
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader className="text-2xl font-bold text-gray-800">Cancel Order Confirmation</ModalHeader>
                    <ModalCloseButton className="text-gray-500 hover:text-gray-700" />
                    <ModalBody>
                      <Text className="text-lg text-gray-600 mb-4">
                        Are you sure you want to cancel this order? This action cannot be undone.
                      </Text>
                      <VStack spacing={4} mt={4}>
                        <Select
                          value={selectedReason}
                          onChange={(e) => setSelectedReason(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">Select a reason</option>
                          {predefinedReasons.map((reason, index) => (
                            <option key={index} value={reason}>
                              {reason}
                            </option>
                          ))}
                        </Select>
                        {selectedReason === "Other" && (
                          <Input
                            placeholder="Enter your reason (minimum 10 characters)"
                            value={customReason}
                            onChange={(e) => setCustomReason(e.target.value)}
                            minLength={10}
                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                          />
                        )}
                      </VStack>
                    </ModalBody>

                    <ModalFooter className="space-x-4">
                      <Button
                        variant="outline"
                        onClick={() => setCancelOrderModalOpen(false)}
                        className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100"
                      >
                        Go Back
                      </Button>
                      <Button
                        variant="solid"
                        colorScheme="red"
                        onClick={handleConfirmCancellation}
                        disabled={
                          !selectedReason ||
                          (selectedReason === "Other" &&
                            customReason.length < 10)
                        }
                        className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Confirm Cancellation
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                {order.deliveryValidated === "accepted" && (
                  <div className="bg-green-100 text-green-800 p-2 rounded-md flex items-center">
                    <CheckIcon className="mr-2" />
                    <p>Payment in process</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageOrders;
