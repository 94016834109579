import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

import { useData } from '../components/DataContext';
import logoImage from '../styles/cart.png';

function Confirmation() {
    const navigate = useNavigate();

    return (
        <div className="center-container" style={{ height: "70vh" }}>

            <div className='form ' style={{ display: "flex", flexDirection: "column", width: "573px", height: "330px", maxWidth: "573px", gap: "20px" }}>
                <h1>Thank you for your order!</h1>
                <h2>Your order will be processed shortly.</h2>
                <h3>Before you can track it, an executor needs to accept your order.</h3>
            </div>


        </div>
    );
}

export default Confirmation;
