import ShimmerButton from "./magicui/shimmer-button";

export default function ShimmerButtonDemo() {
    return (
        <div className="z-10 flex items-center justify-center">
            <ShimmerButton className="shadow-2xl">
                <span className="whitespace-pre-wrap text-center text-md font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10">
                    📦 Order now
                </span>
            </ShimmerButton>
        </div>
    );
}