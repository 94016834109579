import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logoImage from "../styles/monezon.png";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InfoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

function ThankYou() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  useEffect(() => {
    // Show toast message when component mounts
    toast.success("Your order has been successfully placed!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    onOpen();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="center-container" style={{ height: "90vh" }}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader className="flex items-center">
            <InfoIcon color='blue.500' mr={2} />
            Did you set your email?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            If you did, <strong>Check your spams!</strong><br /><br />
            We've <strong>sent you</strong> a <strong>confirmation email</strong> for your order.<br /><br />
            Please check your inbox or <strong>spam/junk</strong> folder.<br /><br />
            Remember to mark us as <strong>"not spam"</strong> to receive <strong>future order updates</strong>. Thank you!
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} colorScheme="green" onClick={onClose}>
              Okay
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <img src={logoImage} alt="Logo" className="logo-image" />
      <ToastContainer />
      <form
        className="login-form"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "573px",
          height: "330px",
          maxWidth: "573px",
          gap: "20px",
        }}
      >
        <h2>Thanks for your order!</h2>
        <h3>Your order will be processed shortly by our executors.</h3>
        <h4>
          Before you can track it, an executor needs to accept your order.
        </h4>
        <h4>
          You can track your order and chat with your executor using your
          passphrase.
        </h4>
        <h5>If you have any questions, feel free to contact us.</h5>
        <button>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Go back
          </Link>
        </button>
      </form>
    </div>
  );
}

export default ThankYou;