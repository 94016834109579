import { Link } from "react-router-dom";
import monero from "../styles/monero.png";
import simplex from "../styles/simplex.jpg";
import Monezon from "../styles/monezon.svg";
import Arrow from "../styles/fleche-amazon.webp";
import Next from "../styles/next.png";
import X from "../styles/X.avif";
import Star from "../styles/Star.png";
import NextSection from "../components/nextSection";
import DownArrow from "../styles/Down arrow.png";
import NotificationBar from "../components/notificationBar";
import { useEffect } from "react";
import styles from "../styles/styles.css";
import GlobeDemo from "../components/GlobeDemo";
import OrbitingCirclesDemo from "../components/OrbitingCirclesDemo";
import AvatarCirclesDemo from "../components/AvatarCirclesDemo";
import ShimmerButtonDemo from "../components/ShimmerButtonDemo";
import IconCloudDemo from "../components/IconCloudDemo";
import AnimatedListDemo from "../components/AnimatedListDemo";
import AnimatedBeamDemo from "../components/AnimatedBeamDemo";
import NumberTickerDemo from "../components/NumberTickerDemo";
import TextRevealDemo from "../components/TextRevealDemo";
import WordRotateDemo from "../components/WordRotateDemo";
import BoxRevealDemo from "../components/BoxRevealDemo";
import BoxRevealDemo2 from "../components/BoxRevealDemo2";
import BoxRevealDemo3 from "../components/BoxRevealDemo3";
import ScrollBasedVelocityDemo from "../components/ScrollBasedVelocityDemo";
import MarqueeDemo from "../components/MarqueeDemo";
import { Tweet } from "react-tweet";
import { Divider } from "@chakra-ui/react";
import BorderBeamDemo from "../components/BorderBeamDemo";
import GradualSpacingDemo from "../components/GradualSpacingDemo";
import MCEP from "../styles/MCEP.svg";
import PublicChat from "../components/PublicChat";

function Index() {
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get("r");

  if (referralCode) {
    localStorage.setItem("referralCode", referralCode);
  }

  return (
    <div className="landing mt-36">
      <section className="min-h-[80vh] relative">
        <NotificationBar />
        <div className="content">
          <div className="topContent">
            <span>
              <img src={Monezon} alt="Monezon logo" />
            </span>
          </div>
          <h2 className="text-4xl font-bold">
            Shop on Amazon<br></br>Anonymously
          </h2>
          <h3>
            Enjoy secure, private shopping on Amazon with your monero and more.
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Link to="/start-ordering">
              <ShimmerButtonDemo />
            </Link>
            <a
              style={{ background: "none" }}
              href="https://twitter.com/atMonezon"
              target="_blank"
              className="X"
            >
              <img
                src={X}
                alt="Twitter logo"
                srcset=""
                style={{ height: "30px" }}
              />
            </a>
          </div>
          <div>
            <p className="mt-2 smE:mt-0">
              <span className="text-orange-500">Hundreds</span> of people
              adopted Monezon!
            </p>
            <div className="flex flex-col mt-2 smE:flex-row items-center smE:items-start">
              <AvatarCirclesDemo />
              <div className="flex flex-col ml-2">
                <div className="flex flex-row mt-1 justify-center smE:justify-start">
                  <img className="h-4 w-4" src={Star}></img>
                  <img className="h-4 w-4" src={Star}></img>
                  <img className="h-4 w-4" src={Star}></img>
                  <img className="h-4 w-4" src={Star}></img>
                  <img className="h-4 w-4" src={Star}></img>
                </div>
                <p className="text-slate-500">
                  More than $35.000{" "}
                  <span className="text-orange-500">transacted</span> on Monezon
                </p>
              </div>
            </div>
          </div>
          <div className="smE:mt-4 mt-2">
            <a
              href="https://moneromaster.substack.com/p/mcep"
              target="_blank"
              style={{ display: "block", width: "fit-content" }}
            >
              <img src={MCEP} alt="MCEP logo" style={{ height: "70px" }}></img>
            </a>
          </div>
        </div>
        <div className="absolute smE:bottom-12 left-[50%] -translate-x-1/2	-translate-y-1/2	">
          <BoxRevealDemo3 />
        </div>
      </section>
      <PublicChat></PublicChat>
      <section className="bg-white w-[100vw] min-h-[100vh] py-32 px-10">
        <h1 className="text-center text-base smE:text-3xl font-bold">
          What people are saying
        </h1>
        <h1 className="text-center text-base smE:text-xl mt-4">
          Don't just take our word for it.<br></br> Here's what{" "}
          <span className="text-orange-500">real people</span> are saying about
          Monezon on Twitter.
        </h1>
        <div className="flex justify-center items-center flex-col mt-12">
          <p className="">🏆 Posted by fluffypony 🏆</p>
          <Tweet id="1753356210085023946" />
        </div>
        <MarqueeDemo />
        <Divider />
        <h1 className="mt-16 text-center text-base smE:text-3xl font-bold">
          👷 Our <NumberTickerDemo />+ executors will process your orders
          quickly
        </h1>
        <div className="flex mb-16 justify-center items-center gap-10 mt-14 wrap flex-col mdE:flex-row">
          <AnimatedListDemo />
          <AnimatedBeamDemo />
        </div>
        <Divider />
        <h1 className="mt-16 text-center text-base smE:text-3xl font-bold">
          🔒 Access a P2P encrypted chat to stay updated on your order
        </h1>
        <div className="flex justify-center items-center mt-12">
          <BorderBeamDemo />
        </div>
        <TextRevealDemo text="And... Guess what?" />
        <TextRevealDemo text="It's WorldWide!" />
        <div className="mdE:px-36 px-0 mb-16">
          <BoxRevealDemo />
        </div>
        <Divider />
        <div className="flex items-center justify-center flex-col">
          <IconCloudDemo />
        </div>
        <div className="mt-16 flex items-center justify-center mb-16">
          <p className="smE:text-4xl text-2xl font-bold text-black dark:text-white w-[100%] text-center">
            Order Anonymously using <WordRotateDemo />
          </p>
        </div>
        <Divider />
        <div className="mt-16">
          <div className="mt-16 flex smE:flex-row flex-col justify-center items-center gap-20">
            <OrbitingCirclesDemo />
            <BoxRevealDemo2 />
          </div>
        </div>
      </section>
      <footer className="bg-gray-800 text-white py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <img
                src={Monezon}
                alt="Monezon logo"
                className="text-2xl font-bold mb-4"
              ></img>
              <p className="text-sm">
                Shop on Amazon anonymously with your monero and more.
              </p>
            </div>
            <div className="flex w-full">
              <div className="w-full md:w-1/4 mb-6 md:mb-0">
                <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/start-ordering"
                      className="hover:text-orange-500 transition duration-300"
                    >
                      Start Ordering
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/faq"
                      className="hover:text-orange-500 transition duration-300"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="hover:text-orange-500 transition duration-300"
                    >
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full md:w-1/4 mb-6 md:mb-0">
                <h4 className="text-lg font-semibold mb-4">Connect</h4>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="https://twitter.com/atMonezon"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-orange-500 transition duration-300"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/MonezonDev"
                      className="hover:text-orange-500 transition duration-300"
                    >
                      Telegram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center">
            <p>&copy; 2024 Monezon. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Index;
