import BoxReveal from "./magicui/box-reveal";

export default function BoxRevealDemo() {
    return (
        <div className="h-full w-full max-w-[32rem] items-center justify-center overflow-hidden pt-8">
            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <p className="smE:text-[2.5rem] text-[2rem] font-semibold">
                    From where you are<span className="text-[#F38154]">.</span>
                </p>
            </BoxReveal>

            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <h5 className="mt-[.5rem] smE:text-[2rem] text-[1.5rem]">
                    Available from any country{" "}
                    <span className="text-[#F38154]">Amazon can ship</span>
                </h5>
            </BoxReveal>

            <BoxReveal boxColor={"#F38154"} duration={0.5}>
                <div className="mt-[1.5rem]">
                    <p>
                        -&gt; Monezon is available <span className="font-semibold text-[#F38154]"> everywhere</span> Amazon offer their services.<br />
                        -&gt; Our executors can fullfil your orders <span className="font-semibold text-[#F38154]"> independently</span> of their location.<br />
                    </p>
                </div>
            </BoxReveal>
        </div>
    );
}