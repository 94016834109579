import React, { useState, useEffect } from "react";
import axios from "axios";
import { Center, Flex, Button, Heading, Input } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export default function Article() {
  const { postId } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }, []);

  useEffect(() => {
    async function fetchArticle() {
      try {
        const response = await axios.get(`/posts/${postId}`);
        setArticle(response.data.message);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    }

    fetchArticle();
  }, [postId]);

  return (
    <div className="article mt-48 mb-32">
      {article ? (
        <div>
          <Center>
            <Heading
              textAlign="center"
              m="20px 50px 10px 50px"
              as="h1"
              size="lg"
            >
              {article.title}
            </Heading>
          </Center>
          <Center>
            <div
              className="article-content"
              style={{
                backgroundColor: "#fefefe",
                margin: "0 10px",
                padding: "50px",
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                minWidth: "500px",
                width: "80%",
                maxWidth: "80%",
              }}
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </Center>
        </div>
      ) : (
        <Center>
          <Heading>No article found</Heading>
        </Center>
      )}
      <style>{`
        .article-content p img {
          max-widht: 200px; /* Empêchez le redimensionnement de l'image de déformer son aspect */
          width: 30vw;
        }
        
        .article-content p em u {
          /* Ajoutez vos styles pour les éléments em avec u à l'intérieur de paragraphes */
          color: rgb(230, 0, 0);
          text-decoration: underline;
        }
        
        .article-content ol li span,

        
        .article-content p.ql-align-center {
          /* Ajoutez vos styles pour les paragraphes avec la classe ql-align-center */
          text-align: center;
        }
        
        .article-content s {
          /* Ajoutez vos styles pour les éléments s */
          text-decoration: line-through;
        }
        
        .article-content blockquote {
          /* Ajoutez vos styles pour les éléments blockquote */
          border-left: 5px solid #ccc;
          margin-left: 20px;
          padding-left: 15px;
        }
        
        .article-content ol {
          /* Ajoutez vos styles pour les listes ordonnées */
          list-style-type: decimal;
          margin-left: 20px;
        }
        
        .article-content ul {
          /* Ajoutez vos styles pour les listes non ordonnées */
          list-style-type: circle;
          margin-left: 20px;
        }
        
        .article-content h1 {
          /* Ajoutez vos styles pour les titres de niveau 1 */
          font-size: 5vw;
          font-weight: bold;
          margin-top: 2vh; /* Marge responsive */
          color: #333;
        }
        
        .article-content h2 {
          /* Ajoutez vos styles pour les titres de niveau 2 */
          font-size: 2.0vw;
          font-weight: bold;
          margin-top: 2vh; /* Marge responsive */
          color: #333;
        }        
      `}</style>
    </div>
  );
}
